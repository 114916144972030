import { Injectable } from '@angular/core';
import { ToastModel } from '../../components/dialogs/toast/toast.model';
import { ToastService } from '../../components/dialogs/toast/toast.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private toastService: ToastService) {}

    public onSuccess(message: string, title: string) {
        return this.onMessage({
            type: 'success',
            title,
            message,
        });
    }

    public onError(message: string, title: string) {
        return this.onMessage({
            type: 'error',
            title,
            message,
        });
    }

    public onWarn(message: string, title: string) {
        return this.onMessage({
            type: 'warn',
            title,
            message,
        });
    }

    public onInfo(message: string, title: string) {
        return this.onMessage({
            type: 'info',
            title,
            message,
        });
    }

    public onCustom(message: string, title: string) {
        return this.onMessage({
            type: 'custom',
            title,
            message,
        });
    }

    private onMessage(message: ToastModel) {
        this.toastService.onMessage(message);
    }
}
