import { DataObject } from '../../lib/model/template/template.model';

export function deepCloneObject<T>(objectToClone: T): T | null {
    let objectCloned: DataObject = {};
    for (let object in objectToClone) {
        if (Array.isArray(objectToClone[object])) {
            // const copyArrayCloned = Array<T>;
            // Object.assign(copyArrayCloned, objectToClone[object]);
            const array = objectToClone[object] as Array<T>;
            objectCloned[object] = [...array];
            continue;
        }
        if (typeof objectToClone[object] === 'object') {
            objectCloned[object] = deepCloneObject(objectToClone[object]);
            continue;
        }
        objectCloned[object] = objectToClone[object];
    }
    return objectToClone != null ? (objectCloned as T) : null;
}
export const deepCloneArray = <T>(items: T[]) => items.map(deepCloneObject);
