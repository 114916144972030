<ng-template>
    <section class="header">
        <p-button [icon]=" params.icon ||'pi pi-tablet'"
            styleClass="p-button-sm p-button-rounded p-button-info p-button-outlined"
            [style]="{margin:' auto 0'}"></p-button>
        <span class="title">{{params.title || 'Step (Sin nombre)'}}</span>
    </section>
    <section class="container">
        <ng-content></ng-content>
    </section>
</ng-template>