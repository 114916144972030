import {
    CardMessageParams,
    ComplexType,
    Country,
    DropDownCascade,
    InputComplexOptions,
    InputMode,
    InputSelectOptions,
    ListPanelConfiguration,
    MetaDataStyleOptions,
    ModeMetaData,
    State,
    createComplexOptions,
} from 'dashboard-lib';
import { PrimeIcons, SelectItemGroup } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { getMockMetaData } from '../../mock/_mocks_/metadata-alerts.mock';
import { AlertClassification, AlertSort, AlertStatus, AlertType } from '../constants/dashboard.constants';
import { Alert, AlertConfiguration, AlertDialogStatus, BlackListParams, GeneralUser, LadaParams, LocationParams } from '../model/alert.model';

export const AlertTypeImage: Record<AlertType, Partial<CardMessageParams<any>>> = {
    CRITICAL: {
        icon: PrimeIcons.TIMES_CIRCLE,
        borderColor: '#9c0e0e',
    },
    INFO: {
        icon: PrimeIcons.INFO_CIRCLE,
        borderColor: '#005a9e',
    },
    WARNING: {
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        borderColor: '#ffe433',
    },
    RESTRICTED: {
        icon: PrimeIcons.LOCK_OPEN,
        borderColor: '#531169',
    },
    NOT_ALLOWED: {
        icon: PrimeIcons.LOCK,
        borderColor: '#15847c',
    },
};

export const filterAlertOptions: SelectItemGroup[] = [
    {
        label: 'Tipo',
        value: 'type',
        items: [
            { label: 'Info', value: AlertType.INFO },
            { label: 'Advertencia', value: AlertType.WARNING },
            { label: 'Crítico', value: AlertType.CRITICAL },
            { label: 'Lista Negra', value: AlertType.NOT_ALLOWED },
            { label: 'Lista Blanca', value: AlertType.RESTRICTED },
        ],
    },
    {
        label: 'Estatus',
        value: 'status',
        items: [
            { label: 'Activos', value: AlertStatus.ACTIVO },
            { label: 'Atendidos', value: AlertStatus.ATENDIDO },
            { label: 'En espera', value: AlertStatus.ESPERA },
        ],
    },
];

export const SortAlertOptions = [
    { text: 'Fecha Asc', value: AlertSort.DateAsc },
    { text: 'Fecha Desc', value: AlertSort.DateDesc },
    { text: 'Tipo', value: AlertSort.Type },
    { text: 'Estatus', value: AlertSort.Status },
];

export const CheckStyleOptions: MetaDataStyleOptions = {
    hiddenLabel: true,
};

export const AlertTypeParams: InputSelectOptions<Alert> = {
    defaultValue: {
        value: 'INFO',
        key: 'value',
    },
    multiLabels: ['text'],
    optionValue: 'value',
};

export const AlertBlackListParams: InputComplexOptions<any> = {
    ...createComplexOptions(ComplexType.Table, {
        // dataKey: 'userId',
        searchOptions: {
            placeholder: 'Buscador',
        },
    }),
    metaData: getMockMetaData('blacklist', ModeMetaData.New),
};

export const AlertConfigurationTypeParams: InputSelectOptions<Alert> = {
    multiLabels: ['text'],
    optionValue: 'value',
};

export const AlertDialogStatusTypeParams: InputSelectOptions<AlertDialogStatus> = {
    multiLabels: ['text'],
    optionValue: 'value',
};

type StateFn = (countryISO: string) => Observable<State[]>;
type CountryCascadeFn = (statesFn: StateFn) => InputSelectOptions<Country>;
export const AlertDialogConfigurationCountryParams: CountryCascadeFn = (statesFn: StateFn) => ({
    multiLabels: ['name'],
    optionValue: 'iso2',
    cascade: [{ field: 'country', children: [{ field: 'region', cascadeFn: statesFn }], hiddenEmpty: true, options$: new BehaviorSubject<State[]>([]) }],
    defaultValue: {
        value: 'MX',
        key: 'iso2',
    },
});

export const AlertConfigurationStateParams = (cascade: DropDownCascade[]) => ({
    multiLabels: ['name'],
    optionValue: 'state_code',
    cascade,
    defaultValue: {
        value: 'HID',
        key: 'state_code',
    },
});

export const AlertConfigurationClassificationParams: InputSelectOptions<any> = {
    multiLabels: ['text'],
    optionValue: 'value',
};

export const AlertListPanelParams: ListPanelConfiguration<AlertConfiguration> = {
    dataList$: new BehaviorSubject<any>([]),
    actionDefault: 'newAction',
    avoidEditMode: true,
    editAction: {
        meta: { metaData: getMockMetaData('lada', ModeMetaData.Edit) },
        title: 'Editar configuración de alerta',
        hideButton: true,
    },
    id: 'list-pnl-alert-config',
    inputMode: InputMode.Read,
    customOptionLabel: (option: AlertConfiguration) => {
        const { classification, params } = option;
        if (classification === AlertClassification.LADA) {
            return `${classification} [${(params as LadaParams).lada}]`;
        }
        if (classification === AlertClassification.LOCATION) {
            const { country, city, region } = params as LocationParams;
            return `${classification} [${country} - ${region} - ${city}]`;
        }
        const { blackListName } = params as BlackListParams;
        return `${classification} [${blackListName}]`;
    },
    styleOptions: {
        columns: 2,
        hiddenWriteMode: false,
    },
    title: 'Lista de configuraciones',
    titlePanel: (data: any) => {
        if (data == null) return 'Configuración de alerta';
        return `Configuración de alerta [${data['classification']}]`;
    },
    delete: {
        confirmation: {
            header: 'Eliminación de configuraciones',
            icon: PrimeIcons.EXCLAMATION_TRIANGLE,
            message: '¿Está seguro de eliminar las configuraciones?',
            acceptLabel: 'Sí',
            acceptIcon: PrimeIcons.CHECK,
            rejectIcon: PrimeIcons.TIMES,
        },
    },
    newAction: {
        title: 'Crear nueva configuración de alerta',
        meta: { metaData: getMockMetaData('lada', ModeMetaData.New) },
    },
    paths: {
        lada: 'params.lada',
        city: 'params.city',
        region: 'params.region',
        blackListName: 'params.blackListName',
        users: 'params.users',
    },

    hiddenConditions: {
        classification: {
            conditions: [
                {
                    value: AlertClassification.LADA,
                    fieldsToHidden: ['city', 'region', 'country', 'blackListName', 'users'],
                    fieldsToShow: ['lada'],
                    compareType: 'equals',
                },
                {
                    value: AlertClassification.LOCATION,
                    fieldsToShow: ['city', 'region', 'country'],
                    fieldsToHidden: ['lada', 'blackListName', 'users'],
                    compareType: 'equals',
                },
                {
                    value: AlertClassification.BLACKLIST,
                    fieldsToShow: ['blackListName', 'users'],
                    fieldsToHidden: ['lada', 'region', 'country', 'city'],
                    compareType: 'equals',
                },
                {
                    value: null,
                    fieldsToHidden: ['city', 'region', 'country', 'lada', 'blackListName', 'users'],
                    fieldsToShow: [],
                    compareType: 'equals',
                },
            ],
        },
        region: {
            conditions: [{ value: null, fieldsToHidden: ['city'], fieldsToShow: [], compareType: 'equals' }],
        },
    },
};
export const UserWhitelistListPanelParams: ListPanelConfiguration<any> = {
    dataList$: new BehaviorSubject([]),
    actionDefault: 'newAction',
    avoidEditMode: true,
    editAction: {
        meta: { metaData: getMockMetaData('user', ModeMetaData.New) },
        title: 'Editar usuario de lista blanca',
    },
    id: 'list-pnl-users-whitelist',
    inputMode: InputMode.Read,
    customOptionLabel: (option: GeneralUser) => {
        const { userId, userName } = option;
        return `${userId} - ${userName}`;
    },
    styleOptions: {
        columns: 2,
        hiddenWriteMode: false,
    },
    title: 'Lista de usuarios de lista blanca',
    titlePanel: (data: GeneralUser) => {
        if (data == null) return 'Usuario de lista blanca';
        return `Editar ${data.userName ? '- ' + data.userName : ''}`;
    },
    delete: {
        confirmation: {
            header: 'Eliminación de usuario de lista blanca',
            icon: PrimeIcons.EXCLAMATION_TRIANGLE,
            message: '¿Está seguro de quitar usuario de la lista blanca?',
            acceptLabel: 'Sí',
            acceptIcon: PrimeIcons.CHECK,
            rejectIcon: PrimeIcons.TIMES,
        },
    },
    newAction: {
        title: 'Agregar usuario a lista blanca',
        meta: { metaData: getMockMetaData('user', ModeMetaData.New) },
    },
    paths: {
        id: 'userId',
        name: 'userName',
    },
};
