import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const DEFAULT_HEADERS: Record<string, string> = {
    'Content-Type': 'application/json',
};
@Injectable()
export class EasyPayInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const { url } = request;
        if (url.includes(environment.apiUrl)) {
            let headers = request.headers;
            Object.keys(DEFAULT_HEADERS).forEach((key: string) => (headers = headers.set(key, DEFAULT_HEADERS[key])));
            return next.handle(request.clone({ headers, url }));
        }
        return next.handle(request);
    }
}

export const PROVIDE_EASYPAY_HEADERS: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: EasyPayInterceptor,
    multi: true,
};
