import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { untilDestroyed } from '@ngneat/until-destroy';
import { MonoTypeOperatorFunction, filter, pipe, tap } from 'rxjs';
import { StepperContainerComponent } from '../../components/stepper/stepper-container/stepper-container.component';
import { EStepStatus, EStepStatusLabel, LibExtendStepParams } from '../../model/components/stepper.model';

/**
 *
 * @param stepContainer
 * @returns MonoTypeOperatorFunction<StepperSelectionEvent>
 * @description This operator configure the step params to in progress status.
 * This operator includes `untilDestroyed`
 */
export function stepInProgress(
    stepContainer: StepperContainerComponent
): MonoTypeOperatorFunction<StepperSelectionEvent> {
    return pipe(
        untilDestroyed(stepContainer),
        filter(Boolean),
        tap((stepSelectionEvent: StepperSelectionEvent) => {
            const { params, completed } = stepContainer._selected;
            if (!completed) {
                params.status = EStepStatus.IN_PROGRESS;
                params.statusLabel = EStepStatusLabel.IN_PROGRESS;
            }
            stepContainer.selected = stepSelectionEvent.previouslySelectedStep;
        })
    );
}

/**
 *
 * @param stepContainer
 * @param params
 * @returns MonoTypeOperatorFunction<boolean>
 * @description This operator configure the step params to complete status in case complete = true and assign the value completed of the CDKStep.
 * If the value was completed and then the value change to `completed = false` set the status to INACTIVE
 */

export function stepComplete(
    stepContainer: StepperContainerComponent,
    params: LibExtendStepParams
): MonoTypeOperatorFunction<boolean> {
    return pipe(
        tap((isCompleted: boolean) => {
            if (stepContainer._selected) {
                if (stepContainer._selected.completed && !isCompleted) {
                    params.status = EStepStatus.INACTIVE;
                    params.statusLabel = EStepStatusLabel.INACTIVE;
                }
                stepContainer._selected.completed = isCompleted;
                if (isCompleted) {
                    params.status = EStepStatus.COMPLETE;
                    params.statusLabel = EStepStatusLabel.COMPLETE;
                }
            }
        })
    );
}
