import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ErrorHandlerModel } from '../../model/utils/utils.model';
import { LoadingService } from '../shared/loading.service';
import { NotificationService } from '../shared/notification.service';
const AngularListError: ErrorHandlerModel = {
    [-100]: 'NG0100: Expression has changed after it was checked',
};
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
    constructor(private notificationService: NotificationService, private zone: NgZone, private loadingService: LoadingService) {}
    public handleError(error: any): void {
        const { code } = error;
        if (!(error instanceof HttpErrorResponse)) {
            const errorList = AngularListError[code];
            if (errorList) return this.registerError(errorList);
            return this.openError(error);
        }
    }

    private openError(error: any): void {
        this.zone.run(() => {
            console.error(`Error - ${error?.message || 'Error from global error handler'}`, error?.status, error);
        });
        this.loadingService.unblock();
    }

    private registerError(message: string): void {
        console.error('Error from global error handler', message);
        this.loadingService.unblock();
    }
}
