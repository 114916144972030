import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputMode, MetaDataOptions, ModeMetaData, NotificationService, PanelConfig, PanelService } from 'dashboard-lib';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { tap } from 'rxjs';
import { getMockMetaData } from 'src/mock/_mocks_/metadata-alerts.mock';
import { AlertTypeParams, CheckStyleOptions } from '../../../configuration/dashboard.config';
import { Alert, AlertComment } from '../../../model/alert.model';
import { AlertService } from '../../../services/alert.service';

@UntilDestroy()
@Component({
    selector: 'app-dialog-alert',
    templateUrl: './dialog-alert.component.html',
    styleUrls: ['./dialog-alert.component.scss'],
})
export class DialogAlertComponent implements OnInit {
    @Input({ required: true }) alert: Alert | null = null;
    @Input({ required: true }) open: boolean = false;
    @Input({ required: true }) header!: string;
    @Input({ required: true }) isNew!: boolean;
    @Input({ required: true }) pnlAlertMode!: InputMode;

    @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onWriteMode: EventEmitter<boolean> = new EventEmitter<boolean>();

    get alertControl(): FormGroup | undefined {
        return this.pnlService.getPanelParams('pnl-alert')?.control;
    }

    get comments(): AlertComment[] {
        return this.alertControl?.value?.comments ?? [];
    }

    get dateFormat(): string {
        return 'dd MMM yyyy HH:mm:ss';
    }

    get isWriteMode(): boolean {
        return this.panelConfig.inputMode === InputMode.Write;
    }

    public panelConfig: PanelConfig = {
        id: 'pnl-alert',
        inputMode: InputMode.Write,
        avoidEditMode: true,
        metaData: getMockMetaData('alert', ModeMetaData.New),
        styleOptions: {
            columns: 2,
            isAppendToBody: true,
        },
    };

    public options: MetaDataOptions<Alert> = {
        type: {
            inputMetaOptions: AlertTypeParams,
        },
        isHtml: {
            metaDataStyleOptions: CheckStyleOptions,
        },
        sendEmail: {
            metaDataStyleOptions: CheckStyleOptions,
        },
        message: {
            inputMetaOptions: { row: 3 },
        },
    };

    constructor(
        private alertService: AlertService,
        private pnlService: PanelService,
        private confirmationService: ConfirmationService,
        private notificationService: NotificationService
    ) {}

    public ngOnInit(): void {
        this.onWriteMode.emit(false);
    }

    public onPanelModeChange(mode: InputMode): void {
        this.onWriteMode.emit(mode === InputMode.Write);
        this.pnlAlertMode = mode;
    }

    public onOpenChange(visible: boolean): void {
        this.openChange.emit(visible);
    }

    public onSaveComment(event: any, op: OverlayPanel, txtComment: HTMLTextAreaElement): void {
        const comment = txtComment.value;
        if (!comment) return this.notificationService.onWarn('El comentario no puede estar vacío', 'Comentarios');
        op.hide();
        this.confirmationService.confirm({
            acceptLabel: 'Sí',
            rejectLabel: 'No',
            header: 'Comentario de alerta',
            message: `¿Está seguro de guardar el comentario a la alerta? <br> <b>${comment}</b>`,
            accept: () => {
                const { id } = this.alertControl?.value;
                this.alertService
                    .saveAlertComment(id, comment)
                    .pipe(
                        untilDestroyed(this),
                        tap((response: any) => {
                            const { message } = response;
                            if (!message) return this.notificationService.onSuccess('Se creó el comentario', 'Comentario');
                        })
                    )
                    .subscribe(() => {
                        txtComment.value = '';
                        this.onOpenChange(false);
                    });
            },
        });
    }
}
