<ng-container *ngIf="metaData$ | async as metaData">

    <p-blockUI [target]="pnl" [blocked]="blockedPanel">
        <p-progressSpinner strokeWidth="8" fill="var(--surface-ground)"></p-progressSpinner>
    </p-blockUI>
    <p-panel #pnl [transitionOptions]="'0ms'" collapseIcon="pi pi-plus" expandIcon="pi pi-minus"
        [toggleable]="toggleablePanel" [collapsed]="false" styleClass="panel">
        <ng-template pTemplate="icons">

            <ng-container *ngTemplateOutlet="editModeButtons"></ng-container>
            <ng-container *ngTemplateOutlet="panelMenu; context: { hasMenu:menuOfPanel}"></ng-container>

        </ng-template>

        <ng-template pTemplate="header">
            <ng-content class="card-title" select="[card-title]">
            </ng-content>
        </ng-template>

        <ng-template pTemplate="content">

            <ng-content select="[card-content-before]"></ng-content>
            <ngx-masonry #masonry [options]="masonryOptions" [ordered]="true">
                <ng-container *ngFor="let meta of metaData">
                    <div *ngIf="meta.input && !meta.hidden" ngxMasonryItem [style.width.%]="widthInputList[meta.field]"
                        class="masonry-item container-form">
                        <div class="separate">
                            <lib-input-meta [params]="params[meta.field]" [inputType]="inputTypeList[meta.input]"
                                [metaDataOptions]="metaDataOptions"
                                (onCreated)="onCreateInput($event)"></lib-input-meta>

                        </div>
                    </div>
                </ng-container>

            </ngx-masonry>
            <ng-content select="[card-content-after]"></ng-content>
        </ng-template>
        <ng-template pTemplate="footer">
            <ng-content select="[card-footer]">
            </ng-content>
        </ng-template>
    </p-panel>

</ng-container>

<ng-template #editModeButtons>
    <ng-container *ngIf="!isAvoidEditMode">
        <button *ngIf="!hiddenWriteMode && !isEditMode" pButton class="p-panel-header-icon p-link" icon="pi pi-pencil"
            (click)="switchWriteMode()" pTooltip="Editar"></button>

    </ng-container>

    <button *ngIf="isEditMode" pButton class="p-panel-header-icon p-link" icon="pi pi-check" (click)="switchReadMode()"
        pTooltip="Ver"></button>
</ng-template>

<ng-template #panelMenu let-hasMenu="hasMenu">
    <ng-container *ngIf="hasMenu">
        <button pButton class="p-panel-header-icon p-link" (click)="menu.toggle($event)">
            <span class="pi pi-cog"></span>
        </button>
        <p-menu #menu id="config_menu" [model]="menuOfPanel" [popup]="true" appendTo="body"></p-menu>
    </ng-container>
</ng-template>