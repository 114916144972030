import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { PipeTransform } from '@angular/core';
import { DataType, TextBoolean } from '../../model/components/form.model';
import { ValuesTypeFn } from '../../model/pipes/pipes.model';
import { betweenLI } from '../../utils/util-form';

export const VALUES_TYPE_FN: ValuesTypeFn = {
    [DataType.Number]: (value: any, pipes: PipeTransform[] = []) => getTransformNumber(value, pipes),
    [DataType.Currency]: (value: any, pipes: PipeTransform[] = []) => getTransformCurrency(value, pipes),
    [DataType.Boolean]: (value: any) => (value ? TextBoolean.True : TextBoolean.False),
    [DataType.Tel]: (value: any) => getTransformTel(value),
    [DataType.Date]: (value: any, pipes: PipeTransform[] = []) => getTransformDate(value, pipes),
    [DataType.Percent]: (value: any, pipes: PipeTransform[] = []) => getTransformPercent(value, pipes),
};

const getPipe = (typePipe: any, pipes: PipeTransform[]) => pipes.find((_pipe: PipeTransform) => _pipe instanceof typePipe);
function getTransformNumber(value: any, pipes: PipeTransform[]): string | null {
    const decimalPipe = getPipe(DecimalPipe, pipes) as DecimalPipe;
    const number = parseFloat(value);
    return decimalPipe.transform(number, '1.0-2');
}

function getTransformPercent(value: string, pipes: PipeTransform[]): string | null {
    const percentPipe = getPipe(PercentPipe, pipes) as PercentPipe;
    return percentPipe.transform(parseFloat(value) / 100, '2.0-2');
}

function getTransformCurrency(value: any, pipes: PipeTransform[]): string | null {
    const currencyPipe = getPipe(CurrencyPipe, pipes) as CurrencyPipe;
    return currencyPipe.transform(value);
}

function getTransformDate(value: string, pipes: PipeTransform[]): string | null {
    const datePipe = getPipe(DatePipe, pipes) as DatePipe;
    return datePipe.transform(value, 'longDate');
}

function getTransformTel(value: String): string {
    const len = value.length;
    if (betweenLI(len, 3, 6)) return value.substring(0, 3);
    if (betweenLI(len, 6, 10)) return `${value.substring(0, 3)}-${value.substring(3, 6)}`;
    if (len === 10) return `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(6, 10)}`;
    return '';
}
