import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { DemoUser } from '../../../constants/oauth/oauth.constants';
import { OAuthModel } from '../../../model/login/login.model';
import { OAuthLibService } from '../../../services/authorization/oauth-lib.service';

@Component({
    selector: 'lib-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss', '../../../assets/shared.scss'],
})
export class MainLayoutComponent implements OnChanges {
    /**
     * Use it just for demo purpose
     */
    @Input() demo: boolean = false;

    public oauthModel$: Observable<OAuthModel> | null = null;

    constructor(private oauthService: OAuthLibService) {
        this.oauthModel$ = oauthService.oauth$;
    }

    public ngOnChanges({ demo: { firstChange } }: SimpleChanges): void {
        if (firstChange && this.demo) this.oauthModel$ = of<OAuthModel>(DemoUser).pipe(tap(this.oauthService.setOauth.bind(this.oauthService)));
    }
}
