import { FormControl } from '@angular/forms';
import { HeaderTableModel } from '../../model/components/dynamic-table.model';
import { MetaDataModel } from '../../model/components/form.model';
import { getIdTokenUuid } from '../../model/utils/utils.model';

export enum SelectionDynamicTableMode {
    None = 'none',
    Single = 'single',
    Multiple = 'multiple',
}

export enum TypeDynamicTable {
    Simple = 'simple',
    Api = 'api',
}
export function _extractColumns(metaDataList: MetaDataModel[]): HeaderTableModel[] {
    return metaDataList
        .filter((metaData: MetaDataModel) => !metaData.hidden)
        .map((metaData: MetaDataModel) => ({
            header: metaData.label,
            field: metaData.field,
            type: metaData.type,
            input: metaData.input,
        }));
}

export const SELECT_CONTROL_TOKEN: string = '_selection',
    ID_CONTROL_TOKEN: string = '_id',
    NEW_CONTROL_TOKEN: string = '_new';
export type ComplexFormControl = (isNewControl: boolean) => { [key: string]: FormControl };

export const getAdditionalControls: ComplexFormControl = (isNewControl: boolean) => ({
    [SELECT_CONTROL_TOKEN]: new FormControl(false),
    [ID_CONTROL_TOKEN]: new FormControl(getIdTokenUuid()),
    [NEW_CONTROL_TOKEN]: new FormControl(isNewControl),
});
