import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    API_COUNTRIES,
    Country,
    InputMode,
    InputSelectOptions,
    ListPanelConfiguration,
    ListPanelFormComponent,
    MetaDataOptions,
    NotificationService,
    PanelService,
    SharedApiService,
} from 'dashboard-lib';
import { ConfirmationService } from 'primeng/api';
import { map, of, tap } from 'rxjs';
import { AlertConfiguration } from 'src/app/model/alert.model';
import {
    AlertBlackListParams,
    AlertConfigurationClassificationParams,
    AlertConfigurationStateParams,
    AlertConfigurationTypeParams,
    AlertDialogConfigurationCountryParams,
    AlertListPanelParams,
    CheckStyleOptions,
} from '../../../configuration/dashboard.config';
import { AlertService } from '../../../services/alert.service';

@UntilDestroy()
@Component({
    selector: 'app-dialog-alert-configuration',
    templateUrl: './dialog-alert-configuration.component.html',
    styleUrls: ['./dialog-alert-configuration.component.scss'],
})
export class DialogAlertConfigurationComponent implements OnInit, OnChanges {
    @Input({ required: true }) open: boolean = false;

    @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ListPanelFormComponent) lstPanel: ListPanelFormComponent | null = null;

    get isValidToSave(): boolean | undefined {
        return this.control?.valid && this.isWriteMode;
    }

    get control(): FormGroup | undefined {
        return this.panelService.getPanelParams('list-pnl-alert-config')?.control;
    }

    private countryMetaOptions: InputSelectOptions<Country> = AlertDialogConfigurationCountryParams((countryISO: string) => {
        return countryISO
            ? this.apiService.getDataEPFromExternalApi$(API_COUNTRIES).pipe(
                  map((response: any) => {
                      const { data } = response;
                      const states = data?.find((_country: Country) => _country.iso2 === countryISO)?.states ?? [];
                      return states;
                  })
              )
            : of([]);
    });
    private isWriteMode: boolean = false;

    public listPanelConfig: ListPanelConfiguration<any> = {
        ...AlertListPanelParams,
    };
    public options: MetaDataOptions<any> = {
        type: {
            inputMetaOptions: AlertConfigurationTypeParams,
        },
        email: {
            metaDataStyleOptions: CheckStyleOptions,
        },
        country: {
            inputMetaOptions: this.countryMetaOptions,
        },
        region: {
            inputMetaOptions: AlertConfigurationStateParams(this.countryMetaOptions.cascade ?? []),
        },
        classification: {
            inputMetaOptions: AlertConfigurationClassificationParams,
        },
        users: {
            inputMetaOptions: AlertBlackListParams,
        },
    };

    constructor(
        private alertService: AlertService,
        private apiService: SharedApiService,
        private panelService: PanelService,
        private confirmationService: ConfirmationService,
        private notificationService: NotificationService
    ) {}

    public ngOnInit(): void {
        this.initListPanelConfiguration();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { open } = changes;
        if (!open.firstChange && this.open) {
            this.alertService
                .getAlertConfigurations()
                .pipe(untilDestroyed(this))
                .subscribe((alerts: AlertConfiguration[]) => {
                    this.listPanelConfig.dataList$.next(alerts);
                });
        }
    }

    public onOpenChange(visible: boolean): void {
        if (!visible) this.openChange.emit(visible);
    }

    public onSave(): void {
        this.confirmationService.confirm({
            acceptLabel: 'Guardar',
            rejectLabel: 'Cancelar',
            header: 'Estatus de alerta',
            message: '¿Está seguro de guardar la configuración de la alerta?',
            accept: () =>
                this.alertService
                    .saveConfiguration(this.control?.value)
                    .pipe(
                        untilDestroyed(this),
                        tap((response: any) => {
                            const { message } = response;
                            if (!message) {
                                this.control?.reset();
                                this.lstPanel?.clearSelection();
                                return this.notificationService.onSuccess('Se actualizó la configuración de la alerta con éxito', 'Alertas');
                            }
                        }),
                        tap(() => this.onOpenChange(false))
                    )
                    .subscribe(),
        });
    }

    public onPanelModeChange(mode: InputMode): void {
        this.isWriteMode = mode === InputMode.Write;
    }

    private initListPanelConfiguration(): void {
        if (this.listPanelConfig.delete) {
            this.listPanelConfig.delete.onDelete = (data: AlertConfiguration) => {
                this.alertService
                    .deleteAlertConfiguration(data)
                    .pipe(
                        untilDestroyed(this),
                        tap((response: any) => {
                            this.lstPanel?.clearSelection();
                            this.notificationService.onSuccess('Se eliminó correctamente la configuración', 'Alertas');
                        }),
                        tap(() => this.onOpenChange(false))
                    )
                    .subscribe();
            };
        }
    }
}
