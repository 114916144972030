import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private blockLoading: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    public block(): void {
        this.blockLoading.next(true);
    }

    public unblock(): void {
        this.blockLoading.next(false);
    }

    public getBlockLoading$(): Observable<boolean> {
        return this.blockLoading.asObservable();
    }
}
