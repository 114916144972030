<p-dialog [header]="'Cambiar estatus ['+ alert?.type +']'" [(visible)]="open" (visibleChange)="onOpenChange($event)"
    [modal]="true">
    <lib-panel-form [panelConfig]="panelConfig" [metaDataOptions]="options">
        <div card-title>
            <span>{{alert?.title + ' ['+ alert?.status +']'}}</span>
        </div>
        <div card-footer>
            <div class="card-actions">
                <p-button *ngIf="enableSaveAlert" label="Guardar" icon="pi pi-file-edit" styleClass="p-button-success"
                    (onClick)="onSave()"></p-button>
            </div>
        </div>
    </lib-panel-form>
</p-dialog>