import { Component, OnInit } from '@angular/core';
import { InputAreaOptions } from '../../../../model/components/input-meta/input-area.model';
import { AbstractInputMeta } from '../abstract-input-meta.class';

@Component({
    selector: 'lib-input-area',
    templateUrl: './input-area.component.html',
    styleUrls: ['./input-area.component.scss'],
})
export class InputAreaComponent extends AbstractInputMeta implements OnInit {
    get inputAreaOptions(): InputAreaOptions | undefined {
        return this.metaData.inputMetaOptions as InputAreaOptions;
    }

    public override ngOnInit(): void {
        super.ngOnInit();
    }

    protected override abstractInit(): void {}
    public override destroyObservables(): void {}
    public override onChangesMode(): void {}
}
