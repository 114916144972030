import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';
import { ValidationErrors } from '@angular/forms';
import { NgxMasonryOptions } from 'ngx-masonry';
import { MetaDataExtended, ValidationMetaData } from '../../model/components/meta-data.model';
import { PanelConfiguration } from '../../model/components/panel-form.model';
import { DataObject } from '../../model/template/template.model';

export const DefaultMasonryOptions: NgxMasonryOptions = {
    columnWidth: 5,
    itemSelector: '.masonry-item',
};

export const isPanelConfigAPI = (panelConfig: PanelConfiguration) => 'metaInfo' in panelConfig;
export function FadeInOut(timingIn: number, timingOut: number, height: boolean = false): AnimationTriggerMetadata {
    return trigger('fadeInOut', [
        transition(':enter', [
            style(height ? { opacity: 0, height: 0 } : { opacity: 0 }),
            animate(timingIn, style(height ? { opacity: 1, height: 'fit-content' } : { opacity: 1 })),
        ]),
        transition(':leave', [animate(timingOut, style(height ? { opacity: 0, height: 0 } : { opacity: 0 }))]),
    ]);
}

export const createWidthInputListForCard = (metaData: MetaDataExtended<any>[], panelWidth: number) => {
    return metaData.reduce(
        (_width: DataObject, meta: MetaDataExtended<any>) => ({
            ..._width,
            [meta.field]: (parseFloat(meta.width || '') || panelWidth) - (panelWidth * 3) / 100,
        }),
        {}
    );
};

export function getErrorMessage(errors: ValidationErrors | null, validationList: Array<ValidationMetaData>): string[] {
    const validationsObject = getValidationMap(validationList);
    return Object.keys(errors || {})
        .filter((errorKey: string) => (errors ? errors[errorKey] : false))
        .reduce((messages: string[], errorKey: string) => {
            const errorMessage = validationsObject[errorKey];
            return [...messages, errorMessage];
        }, []);
}

function getValidationMap(validationList: Array<ValidationMetaData>): DataObject {
    return validationList.reduce(
        (validations: DataObject, validation: ValidationMetaData) => ({
            ...validations,
            [validation.type]: validation.errorMessage,
        }),
        {}
    );
}
