import { Pipe, PipeTransform } from '@angular/core';
import { DISPLAY_VALUES } from '../constants/components/input-select.constants';
import { InputSelectOptions } from '../model/components/input-meta/input-select.model';
import { DataObject } from '../model/template/template.model';

@Pipe({
    name: 'dropdownValuesPipe',
})
export class DropdownValuesPipe implements PipeTransform {
    /**
     *
     * @param options
     * @param InputSelectOptions
     * @returns DataObject[]
     * @description if InputSelectOptions exists the options values are replaced for others with `displayValues` where has the text according to the configuration
     */
    transform(options: DataObject[], selectOptions: InputSelectOptions<any> | null | undefined): DataObject[] {
        if (selectOptions) {
            const { multiLabels, separator, customOptionText } = selectOptions;
            options = options.map((option: DataObject) => {
                const displayValue = customOptionText?.(option) ?? multiLabels?.map((label: string) => option[label]).join(separator || ' ');
                return {
                    ...option,
                    [DISPLAY_VALUES]: displayValue,
                };
            });

            selectOptions.optionLabel = DISPLAY_VALUES;
        }
        return options;
    }
}
