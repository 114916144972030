<div [style.width.%]="width" class="container-box">
    <ng-container *ngIf="title as titleBox">
        <label class="box-title p-lib-label">{{titleBox}}</label>

    </ng-container>
    <ng-container *ngIf="options$ | async as options">
        <p-listbox [style.width.%]="width" [options]="optionsComplex" [formControl]="formControl"
            optionLabel="displayValues" [filter]="true">
        </p-listbox>
    </ng-container>
</div>