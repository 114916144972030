import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { getHttpParams } from '../../constants/shared/shared.constants';
import { MetaData } from '../../model/components/meta-data.model';
import { ModeMetaData } from '../../model/components/shared-models.model';
import { ConfigService } from '../template/config.service';

@Injectable({
    providedIn: 'root',
})
export class MetaApiService {
    constructor(private httpCLient: HttpClient, private config: ConfigService) {}

    public getMetaData$(entity: string, mode: ModeMetaData): Observable<MetaData[]> {
        const httpParams = {
            entity,
            mode,
        };
        const params = getHttpParams(httpParams);
        const url = this.config.apiMetaDataUrl + '/metadata';
        const response = this.httpCLient.get(url, { params });
        return response.pipe(map((entityMetaData: any) => entityMetaData[entity])) as Observable<MetaData[]>;
    }
}
