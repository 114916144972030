import { Directive, Input, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LibStepParams, StepperData } from '../../../model/components/stepper.model';
import { getUuid } from '../../../model/utils/utils.model';
import { FormCardComponent } from '../../card/form-card.component';

@Directive()
export abstract class LibAbstractStep {
    // Queries
    @ViewChildren(FormCardComponent, { read: FormCardComponent }) private formCards?: QueryList<FormCardComponent>;

    @Input({ required: true }) params!: LibStepParams;

    private id: string = getUuid();

    public getStepId(): string {
        return this.id;
    }

    public abstract getComplete$(): BehaviorSubject<boolean>;
    public abstract getStepData(): any;
    public abstract setData(data: StepperData): void;
    public getQueryFormCards(): QueryList<FormCardComponent> | undefined {
        return this.formCards?.length === 0 ? undefined : this.formCards;
    }
}
