<p-toolbar>
	<div class="p-toolbar-group-start">
		<p-button label="Configuraciones de alerta" icon="pi pi-cog" styleClass="p-button-warning"
			(onClick)="openConfigAlert()"> </p-button>
		<p-button label="Lista usuarios lista blanca" icon="pi pi-list" styleClass="p-button-info"
			(onClick)="openWhitelistUserAlert()"> </p-button>
	</div>
	<div class="p-toolbar-group-end">
	</div>
</p-toolbar>


<div class="container">
	<div class="alert-list">
		<app-alert-list (selectedAlert)="onSelectedAlert($event)" (setAllAlertsEvt)="setAllAlertsEvt($event)"
			(setAllUsersWhitelistEvt)="setAllUsersWhitelistEvt($event)"></app-alert-list>
	</div>
</div>

<app-dialog-alert-configuration [(open)]="isOpenAlertConfigurationDialog"
	(openChange)="onOpenAlertDialogChange($event)"></app-dialog-alert-configuration>

<app-dialog-users-whitelist [(open)]="isOpenWhitelistDialog"
	(openChange)="onOpenUserWhitelistDialogChange($event)"></app-dialog-users-whitelist>

<app-dialog-status [(open)]="isOpenAlertStatusDialog" [alert]="selectedAlert"
	(openChange)="onOpenAlertDialogChange($event)"></app-dialog-status>

<app-dialog-alert [(open)]="isOpenAlertDialog" [alert]="selectedAlert" (onWriteMode)="onModeInAlert($event)"
	[header]="header" [pnlAlertMode]="pnlAlertMode" [isNew]="isNew"
	(openChange)="onOpenAlertDialogChange($event)"></app-dialog-alert>

<p-blockUI *ngIf="blockLoading$ | async as blockLoading" [blocked]="blockLoading">
	<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</p-blockUI>