import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es-MX';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElfNgRouterStoreModule } from '@ngneat/elf-ng-router-store';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { NgxMasonryModule } from 'ngx-masonry';
import { OAuthLibService } from '../lib/services/authorization/oauth-lib.service';
import { FormCardComponent } from './components/card/form-card.component';
import { DynamicDialogComponent } from './components/dialogs/dynamic-dialog/dynamic-dialog.component';
import { OverlayPanelComponent } from './components/dialogs/overlay-panel/overlay-panel.component';
import { ToastComponent } from './components/dialogs/toast/toast.component';
import { DummyMenuBarComponent } from './components/dummy/dummy-menu-bar/dummy-menu-bar.component';
import { DummyPanelComponent } from './components/dummy/dummy-panel/dummy-panel.component';
import { DummyComponent } from './components/dummy/dummy.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { ErrorPageComponent } from './components/error/error-page/error-page.component';
import { CardMessageComponent } from './components/forms/card-message/card-message.component';
import { InputAreaComponent } from './components/forms/input-meta/input-area/input-area.component';
import { InputBoolComponent } from './components/forms/input-meta/input-bool/input-bool.component';
import { InputComplexComponent } from './components/forms/input-meta/input-complex/input-complex.component';
import { InputMetaComponent } from './components/forms/input-meta/input-meta.component';
import { InputSelectComponent } from './components/forms/input-meta/input-select/input-select.component';
import { InputTableComponent } from './components/forms/input-meta/input-table/input-table.component';
import { InputTextComponent } from './components/forms/input-meta/input-text/input-text.component';
import { InputUnknownComponent } from './components/forms/input-meta/input-unknown/input-unknown.component';
import { ListPanelFormComponent } from './components/forms/list-panel-form/list-panel-form.component';
import { MetaComplexInputFormComponent } from './components/forms/meta-complex-input-form/meta-complex-input-form.component';
import { MetaInputFormComponent } from './components/forms/meta-input-form/meta-input-form.component';
import { PanelFormComponent } from './components/forms/panel-form/panel-form.component';
import { SelectSearchComponent } from './components/forms/select-search/select-search.component';
import { SimpleInputFormComponent } from './components/forms/simple-input-form/simple-input-form.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { MainLayoutComponent } from './components/layout/main-layout/main-layout.component';
import { MenuSidenavComponent } from './components/layout/sidenav/menu-sidenav/menu-sidenav.component';
import { SidenavComponent } from './components/layout/sidenav/sidenav.component';
import { ToolbarComponent } from './components/layout/toolbar/toolbar.component';
import { ListBoxComponent } from './components/list-box/list-box.component';
import { LoginCallbackComponent } from './components/login/login-callback/login-callback.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/login/logout/logout.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { ImageFileComponent } from './components/shared/image-file/image-file.component';
import { GeneralStepComponent } from './components/stepper/general-step/general-step.component';
import { StepComponent } from './components/stepper/step/step.component';
import { StepperContainerComponent } from './components/stepper/stepper-container/stepper-container.component';
import { DEFAULT_PROVIDERS, PIPE_PROVIDERS } from './constants/shared/shared.constants';
import { DashboardLibComponent } from './dashboard-lib.component';
import { AdDirective } from './directives/ad.directive';
import { LoginModule } from './modules/login/login.module';
import { PrimengModule } from './modules/primeng/primeng.module';
import { RoutingModule } from './modules/routing/routing.module';
import { DataTypePipe } from './pipes/data-type.pipe';
import { DropdownValuesPipe } from './pipes/dropdown-values.pipe';
import { ExtractMetaDataComplexPipe } from './pipes/extract-metadata-complex.pipe';
import { HiddenMetadataPipe } from './pipes/hidden-metadata.pipe';
import { MetaInputDropdownValuesPipe } from './pipes/meta-input-dropdown-values.pipe';
import { MetaDataHiddenPipe } from './pipes/metadata-hidden.pipe';
import { MetadataTypePipe } from './pipes/metadata-type.pipe';
import { LibFormService } from './services/components/lib-form.service';
import { GlobalErrorHandlerService } from './services/error/global-error-handler.service';
import { PROVIDE_AUTHENTICATION_INTERCEPTOR } from './services/interceptors/authentication.interceptor';
import { PROVIDE_DEFAULT_HEADERS_INTERCEPTOR } from './services/interceptors/default-headers.interceptor';
import { MetaApiService } from './services/shared/meta-api.service';
import { ListPanelOptionsPipe } from './pipes/list-panel-options.pipe';

registerLocaleData(localeEs);

@NgModule({
    declarations: [
        DashboardLibComponent,
        LoginCallbackComponent,
        LoginComponent,
        LogoutComponent,
        ErrorPageComponent,
        MainLayoutComponent,
        ToolbarComponent,
        FooterComponent,
        SidenavComponent,
        MenuSidenavComponent,
        FormCardComponent,
        ImageFileComponent,
        SelectSearchComponent,
        MetaInputFormComponent,
        MenuBarComponent,
        DynamicTableComponent,
        MetaComplexInputFormComponent,
        MetaDataHiddenPipe,
        ExtractMetaDataComplexPipe,
        SimpleInputFormComponent,
        OverlayPanelComponent,
        ToastComponent,
        DataTypePipe,
        ListBoxComponent,
        PanelFormComponent,
        MetaInputDropdownValuesPipe,
        StepperContainerComponent,
        StepComponent,
        DummyComponent,
        AdDirective,
        GeneralStepComponent,
        DummyMenuBarComponent,
        DynamicDialogComponent,
        DummyPanelComponent,
        InputMetaComponent,
        InputTextComponent,
        InputBoolComponent,
        MetadataTypePipe,
        HiddenMetadataPipe,
        InputComplexComponent,
        InputUnknownComponent,
        InputSelectComponent,
        DropdownValuesPipe,
        InputTableComponent,
        CardMessageComponent,
        InputAreaComponent,
        ListPanelFormComponent,
        ListPanelOptionsPipe,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        LoginModule,
        RoutingModule,
        PrimengModule,
        ReactiveFormsModule,
        FormsModule,
        NgxMasonryModule,
        OAuthModule.forRoot(),
        ElfNgRouterStoreModule,
    ],
    exports: [
        DashboardLibComponent,
        MainLayoutComponent,
        ImageFileComponent,
        FormCardComponent,
        PrimengModule,
        SelectSearchComponent,
        MenuBarComponent,
        DynamicTableComponent,
        MetaInputFormComponent,
        OverlayPanelComponent,
        ToastComponent,
        DataTypePipe,
        MetadataTypePipe,
        ListBoxComponent,
        NgxMasonryModule,
        PanelFormComponent,
        StepperContainerComponent,
        StepComponent,
        GeneralStepComponent,
        DummyMenuBarComponent,
        ElfNgRouterStoreModule,
        DummyComponent,
        DynamicDialogComponent,
        DummyPanelComponent,
        CardMessageComponent,
        ListPanelFormComponent,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        PROVIDE_DEFAULT_HEADERS_INTERCEPTOR,
        PROVIDE_AUTHENTICATION_INTERCEPTOR,
        LibFormService,
        MetaApiService,
        ...DEFAULT_PROVIDERS,
        ...PIPE_PROVIDERS,
        { provide: OAuthStorage, useValue: localStorage },
        OAuthLibService,
    ],
})
export class DashboardLibModule {}
