import { DataType, InputType, MetaDataJSON, ModeMetaData, ValidationType, sortBy } from 'dashboard-lib';
import { AlertClassification, AlertStatus, AlertType } from 'src/app/constants/dashboard.constants';

type MetaDataMap =
    | `alert-${ModeMetaData.New}`
    | `alertStatus-${ModeMetaData.New}`
    | `lada-${ModeMetaData.New}`
    | `lada-${ModeMetaData.Edit}`
    | `user-${ModeMetaData.New}`
    | `blacklist-${ModeMetaData.New}`;

const AlertNew: MetaDataJSON[] = [
    {
        field: 'id',
        type: DataType.Text,
        input: InputType.Text,
        editable: false,
        hidden: false,
        lookupType: null,
        placeholder: null,
        label: 'Id de alerta',
        metadataId: null,
        order: 1,
        endPoint: null,
        width: '100',
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'type',
        type: DataType.Text,
        input: InputType.Select,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Seleccione tipo de alerta',
        label: 'Selecciona el tipo de alerta',
        metadataId: null,
        order: 2,
        endPoint: null,
        width: null,
        conditions: [],
        options: [
            { text: 'Info', value: AlertType.INFO },
            { text: 'Advertencia', value: AlertType.WARNING },
            { text: 'Crítico', value: AlertType.CRITICAL },
        ],
        validations: [
            {
                type: ValidationType.Required,
                param: 'true',
                errorMessage: 'El tipo de alerta es requerido',
            },
        ],
    },
    {
        field: 'title',
        type: DataType.Text,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Escribe título de alarma',
        label: 'Título',
        metadataId: null,
        order: 2,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [
            {
                type: ValidationType.Required,
                param: 'true',
                errorMessage: 'El título de alerta es requerido',
            },
        ],
    },
    {
        field: 'message',
        type: DataType.Text,
        input: InputType.Textarea,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Escribe el mensaje de alarma',
        label: 'Mensaje',
        metadataId: null,
        order: 3,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [
            {
                type: ValidationType.Required,
                param: 'true',
                errorMessage: 'El mensaje de alerta es requerido',
            },
        ],
    },
    {
        field: 'isHtml',
        type: DataType.Boolean,
        input: InputType.Check,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: null,
        label: 'HTML',
        metadataId: null,
        order: 5,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'sendEmail',
        type: DataType.Boolean,
        input: InputType.Check,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: null,
        label: 'Enviar por correo',
        metadataId: null,
        order: 6,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'comments',
        type: DataType.Text,
        input: InputType.Textarea,
        editable: false,
        hidden: true,
        lookupType: null,
        placeholder: null,
        label: 'Comentarios',
        metadataId: null,
        order: 10,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [],
    },
];

const AlertDialogStatus: MetaDataJSON[] = [
    {
        field: 'status',
        type: DataType.Text,
        input: InputType.Select,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: null,
        label: 'Cambiar estatus',
        metadataId: null,
        order: 1,
        endPoint: null,
        width: null,
        conditions: [],
        options: [
            { value: AlertStatus.ACTIVO, text: 'Activo' },
            { value: AlertStatus.ATENDIDO, text: 'Atendido' },
            { value: AlertStatus.ESPERA, text: 'En espera' },
        ],
        validations: [
            {
                type: ValidationType.Required,
                param: 'true',
                errorMessage: 'Seleccione un estatus para actualizar',
            },
        ],
    },
    {
        field: 'comment',
        type: DataType.Text,
        input: InputType.Textarea,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Escribe un comentario para el cambio de estatus',
        label: 'Comentario',
        metadataId: null,
        order: 2,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [
            {
                type: ValidationType.Required,
                param: 'true',
                errorMessage: 'El comentario es requerido',
            },
        ],
    },
];

const AlertConfigurationsLada: MetaDataJSON[] = [
    {
        field: 'blackListName',
        type: DataType.Text,
        input: InputType.Text,
        editable: true,
        hidden: false,
        placeholder: 'Escribe el nombre de la lista negra',
        label: 'Nombre de lista negra',
        order: 6,
        width: null,
        conditions: [
            {
                fieldId: 'classification',
                value: AlertClassification.BLACKLIST,
                validations: [{ errorMessage: 'El nombre es requerido', param: 'true', type: ValidationType.Required }],
            },
        ],
        validations: [
            {
                type: ValidationType.Required,
                param: 'false',
                errorMessage: '',
            },
        ],
    },
    {
        field: 'users',
        type: DataType.Array,
        input: InputType.Complex,
        editable: true,
        placeholder: 'Lista de usuarios lista negra',
        label: 'Usuarios de lista negra',
        order: 7,
        validations: [],
        width: '100',
        conditions: [],
        metadataId: 'blacklist',
    },
    {
        field: 'country',
        type: DataType.Text,
        input: InputType.Endpoint,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Selecciona el país',
        label: 'País',
        metadataId: null,
        order: 6,
        endPoint: '+https://countriesnow.space/api/v0.1/countries/states',
        width: null,
        conditions: [
            {
                fieldId: 'classification',
                value: AlertClassification.LOCATION,
                validations: [{ errorMessage: 'EL país es requerido', param: 'true', type: ValidationType.Required }],
            },
        ],
        options: [],
        validations: [
            {
                type: ValidationType.Required,
                param: 'false',
                errorMessage: '',
            },
        ],
    },
    {
        field: 'region',
        type: DataType.Text,
        input: InputType.Select,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Selecciona la región',
        label: 'Región',
        metadataId: null,
        order: 7,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'city',
        type: DataType.Text,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Ingresa el nombre de la ciudad',
        label: 'Ciudad',
        metadataId: null,
        order: 8,
        endPoint: null,
        width: '100',
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'classification',
        type: DataType.Text,
        input: InputType.Select,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Selecciona el tipo clasificación',
        label: 'Clasificación',
        metadataId: null,
        order: 2,
        endPoint: null,
        width: null,
        conditions: [],
        options: [
            { text: 'Por ubicación', value: 'LOCATION' },
            { text: 'Por LADA', value: 'LADA' },
            { text: 'Por Lista negra', value: 'BLACKLIST' },
        ],
        validations: [
            {
                type: ValidationType.Required,
                param: 'true',
                errorMessage: 'La clasificación es requerida',
            },
        ],
    },
    {
        field: 'type',
        type: DataType.Text,
        input: InputType.Select,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Seleccione tipo de alerta',
        label: 'Selecciona el tipo de alerta',
        metadataId: null,
        order: 1,
        endPoint: null,
        width: null,
        conditions: [],
        options: [
            { text: 'Info', value: AlertType.INFO },
            { text: 'Advertencia', value: AlertType.WARNING },
            { text: 'Crítico', value: AlertType.CRITICAL },
        ],
        validations: [
            {
                type: ValidationType.Required,
                param: 'true',
                errorMessage: 'El tipo de alerta es requerido',
            },
        ],
    },
    {
        field: 'email',
        type: DataType.Boolean,
        input: InputType.Check,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: null,
        label: 'Enviar por correo',
        metadataId: null,
        order: 4,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'lada',
        type: DataType.Number,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Ingresa LADA',
        label: 'LADA',
        metadataId: null,
        order: 5,
        endPoint: null,
        width: null,
        keyFilter: 'int',
        conditions: [
            {
                fieldId: 'classification',
                value: 'LADA',
                validations: [
                    { errorMessage: 'La LADA es requerida', param: 'true', type: ValidationType.Required },
                    {
                        type: ValidationType.Pattern,
                        param: '[0-9]{3,10}',
                        errorMessage: 'Formato de LADA inválida (^3) o max 10',
                    },
                ],
            },
        ],
        options: [],
        validations: [
            {
                type: ValidationType.Required,
                param: 'false',
                errorMessage: '',
            },
        ],
    },
];

export const AlertConfigurationsLadaEdit: MetaDataJSON[] = [
    {
        field: 'id',
        type: DataType.Text,
        input: InputType.Text,
        editable: false,
        hidden: false,
        lookupType: null,
        placeholder: null,
        label: 'Id de Configuración',
        metadataId: null,
        order: 1,
        endPoint: null,
        width: '100',
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'type',
        type: DataType.Text,
        input: InputType.Select,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Seleccione tipo de alerta',
        label: 'Selecciona el tipo de alerta',
        metadataId: null,
        order: 2,
        endPoint: null,
        width: null,
        conditions: [],
        options: [
            { text: 'Info', value: AlertType.INFO },
            { text: 'Advertencia', value: AlertType.WARNING },
            { text: 'Crítico', value: AlertType.CRITICAL },
        ],
        validations: [
            {
                type: ValidationType.Required,
                param: 'true',
                errorMessage: 'El tipo de alerta es requerido',
            },
        ],
    },
    {
        field: 'email',
        type: DataType.Boolean,
        input: InputType.Check,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: null,
        label: 'Enviar por correo',
        metadataId: null,
        order: 5,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'lada',
        type: DataType.Number,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Ingresa LADA',
        label: 'LADA',
        metadataId: null,
        order: 3,
        endPoint: null,
        width: null,
        conditions: [
            {
                fieldId: 'classification',
                value: 'LADA',
                validations: [
                    { errorMessage: 'La LADA es requerida', param: 'true', type: ValidationType.Required },
                    {
                        type: ValidationType.Pattern,
                        param: '[0-9]{3,10}',
                        errorMessage: 'Formato de LADA inválida (^3) o max 10',
                    },
                ],
            },
        ],
        options: [],
        keyFilter: 'int',
        validations: [
            {
                type: ValidationType.Required,
                param: 'false',
                errorMessage: 'El campo es requerido',
            },
        ],
    },
    {
        field: 'country',
        type: DataType.Text,
        input: InputType.Endpoint,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Selecciona el país',
        label: 'País',
        metadataId: null,
        order: 6,
        endPoint: '+https://countriesnow.space/api/v0.1/countries/states',
        width: null,
        conditions: [
            {
                fieldId: 'classification',
                value: 'LOCATION',
                validations: [{ errorMessage: 'EL país es requerido', param: 'true', type: ValidationType.Required }],
            },
        ],
        options: [],
        validations: [
            {
                type: ValidationType.Required,
                param: 'false',
                errorMessage: 'El campo es requerido',
            },
        ],
    },
    {
        field: 'region',
        type: DataType.Text,
        input: InputType.Select,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Selecciona la región',
        label: 'Región',
        metadataId: null,
        order: 7,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'city',
        type: DataType.Text,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Ingresa el nombre de la ciudad',
        label: 'Ciudad',
        metadataId: null,
        order: 8,
        endPoint: null,
        width: '100',
        conditions: [],
        options: [],
        validations: [],
    },
    {
        field: 'classification',
        type: DataType.Text,
        input: InputType.Text,
        editable: false,
        hidden: true,
        lookupType: null,
        placeholder: null,
        label: 'Clasificación',
        metadataId: null,
        order: 9,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [],
    },
];
const AlertUsers: MetaDataJSON[] = [
    {
        field: 'id',
        type: DataType.Text,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Id de Usuario',
        label: 'Id de Usuario',
        metadataId: null,
        order: 1,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [{ errorMessage: 'Debe agregar un id', type: ValidationType.Required, param: 'true' }],
    },
    {
        field: 'name',
        type: DataType.Text,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Nombre de usuario',
        label: 'Nombre de usuario',
        metadataId: null,
        order: 1,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [{ errorMessage: 'Debe agregar un nombre de usuario', type: ValidationType.Required, param: 'true' }],
    },
];

const BlackListUsersMetaData: MetaDataJSON[] = [
    {
        field: 'userId',
        type: DataType.Text,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Id de Usuario',
        label: 'Id de Usuario',
        metadataId: null,
        order: 1,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [{ errorMessage: 'Debe agregar un id', type: ValidationType.Required, param: 'true' }],
    },
    {
        field: 'userName',
        type: DataType.Text,
        input: InputType.Text,
        editable: true,
        hidden: false,
        lookupType: null,
        placeholder: 'Nombre de usuario',
        label: 'Nombre de usuario',
        metadataId: null,
        order: 2,
        endPoint: null,
        width: null,
        conditions: [],
        options: [],
        validations: [{ errorMessage: 'Debe agregar un nombre de usuario', type: ValidationType.Required, param: 'true' }],
    },
];
const MetaDataAlert: Record<MetaDataMap, MetaDataJSON[]> = {
    'alert-new': AlertNew,
    'alertStatus-new': AlertDialogStatus,
    'lada-new': AlertConfigurationsLada,
    'lada-modify': AlertConfigurationsLadaEdit,
    'user-new': AlertUsers,
    'blacklist-new': BlackListUsersMetaData,
};

export const getMockMetaData = (entity: string, mode: string) => MetaDataAlert[`${entity}-${mode}` as MetaDataMap]?.sort(sortBy('order'));
