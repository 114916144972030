import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { PanelFormComponent } from '../../components/forms/panel-form/panel-form.component';
import { PanelForm, PanelParams } from '../../model/components/panel-form.model';
import { DataObject } from '../../model/template/template.model';
import { digger } from '../../utils/general-functions';
import { MetaFormControlService } from './meta-form-control.service';

@Injectable({
    providedIn: 'root',
})
export class PanelService {
    private panels: PanelForm = {};
    private _panels$: BehaviorSubject<PanelForm> = new BehaviorSubject(this.panels);
    private panels$: Observable<PanelForm> = this._panels$.asObservable();

    constructor(private fb: FormBuilder, private metaFormControl: MetaFormControlService) {}

    public registerPanel(panel: PanelFormComponent): void {
        const { control, reloadMasonryEvt } = panel;
        const patchValue = this.getPatchValueEvt(control);
        this.panels[panel.id] = {
            control,
            reloadMasonryEvt,
            patchValue,
        };
        this._panels$.next(this.panels);
    }

    public getPanelParams(panelId: string): PanelParams {
        return this.panels[panelId];
    }

    public getPanels$(): Observable<PanelForm> {
        return this.panels$;
    }
    private getPatchValueEvt(control: FormGroup) {
        return (value: any, paths?: Record<string, string>) => {
            control.patchValue(value);
            if (paths) {
                let arrayValues = {};
                const valuesToPatch = Object.keys(paths).reduce((object: any, key: string) => {
                    const val = digger(value, paths[key]);
                    if (Array.isArray(val)) {
                        arrayValues = { ...arrayValues, [key]: { value: val, control: control.get(key) } };
                        return object;
                    }
                    return { ...object, [key]: val };
                }, {});
                control.patchValue(valuesToPatch);
                this.patchArrayValues(arrayValues);
            }
        };
    }
    private patchArrayValues(arrayValues: Record<string, any>): void {
        Object.keys(arrayValues).forEach((key: string) => {
            const { value, control } = arrayValues[key];
            control.clear();
            value.map((val: DataObject) => {
                const rowGroup = Object.keys(val).reduce(
                    (object: any, rowKey: string) => ({
                        ...object,
                        [rowKey]: [''],
                    }),
                    {}
                );
                const formGroup = this.fb.group(rowGroup);
                this.metaFormControl.createTableControls(formGroup);
                formGroup.patchValue(val);
                control.push(formGroup);
            });
        });
    }
}
