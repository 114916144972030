import { Component } from '@angular/core';
import { AbstractInputMeta } from '../abstract-input-meta.class';

@Component({
    selector: 'lib-input-unknown',
    templateUrl: './input-unknown.component.html',
    styleUrls: ['./input-unknown.component.scss'],
})
export class InputUnknownComponent extends AbstractInputMeta {
    protected override abstractInit(): void {}
    public override destroyObservables(): void {}
    public override onChangesMode(): void {}
}
