import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from '../../components/error/error-page/error-page.component';
import { LoginCallbackComponent } from '../../components/login/login-callback/login-callback.component';
import { LoginComponent } from '../../components/login/login.component';
import { LogoutComponent } from '../../components/login/logout/logout.component';
import { AuthenticationGuard } from '../../services/guards/authentication.guard';

export const routes: Routes = [
    {
        path: 'login/callback',
        component: LoginCallbackComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'error',
        component: ErrorPageComponent,
    },
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(routes)],
    providers: [AuthenticationGuard],
})
export class RoutingModule {}
