import { Confirmation } from 'primeng/api';

export enum EStepStatus {
    COMPLETE = 'success',
    IN_PROGRESS = 'primary',
    INACTIVE = 'warning',
}
export enum EStepStatusLabel {
    COMPLETE = 'COMPLETO',
    IN_PROGRESS = 'EN PROGRESO',
    INACTIVE = 'PENDIENTE',
}

/**
 * @interface LibStepParams
 * @description Use to configure the custom stepper
 */
export interface LibStepperParams {
    /**
     * @field icon
     * @use Optional Icon to put in the stepper button
     */
    icon?: string;
    /**
     * @field title
     * @use Optional to set title in the top of the stepper component
     */
    title?: string;
    /**
     * @field width
     * @use width of stepper container
     * @default 100%
     */
    width?: number;
    /**
     * @field isLinear
     * @use To set the linear mode
     * @description Only when the completed is true you can access to the next step
     */
    isLinear?: boolean;
    /**
     * @field confirmation
     * @use if the last step require a confirmation to finalize
     */
    confirmation?: Confirmation;
    /**
     *  @field data
     *  @description the data to inject in all steps
     */
    data?: any;
}
/**
 * @interface LibStepParams
 * @description Use to configure the custom steps
 */
export interface LibStepParams {
    /**
     * @field icon
     * @use Optional Icon to put in the step button
     */
    icon?: string;
    /**
     * @field title
     * @use Optional to set title in the top of the step component custom
     */
    title?: string;
    /**
     * @field buttonName
     * @use Optional to set the name in the button to show the component
     * @default 'Step - {index}'
     */
    buttonName?: string;
    /**
     * @field disablePreviousStep
     * @description if is complete, avoid return to previous step and only works if stepper is in linear mode
     */
    disablePreviousStep?: boolean;
    /**
     * @field autoNext
     * @description when is true in automatic when the step is complete it goes to the next step
     */
    autoNext?: boolean;
}

export type LibExtendStepParams = LibStepParams & {
    /**
     * @field stepIndex
     * @use To set the stepIndex of the step in order to create
     * @description DO NOT MODIFY
     */
    stepIndex?: number;
    /**
     * @field status
     * @use To set the status in the stepper
     * @description DO NOT MODIFY
     */
    status?: EStepStatus;
    /**
     * @field statusLabel
     * @use To set the status label in the stepper
     * @description DO NOT MODIFY
     */
    statusLabel?: EStepStatusLabel;
    /**
     * @field isLoad
     * @use to identify if the component in the step y loaded
     * @description if inside of a StepComponent there are `FormCardComponent` this variable works to control it
     * DO NOT MODIFY
     */
    isLoad?: boolean;
};
/**
 * @interface StepperData
 * @description Use to set the data from the main stepper
 */
export interface StepperData {
    /**
     * @field dynamicData
     * @description data from dialogService
     */
    dynamicData?: any;
    /**
     * @field data
     * @description data from stepper params
     * @see {@link LibStepperParams}
     */
    data?: any;
}
