import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TEMPLATE_CONFIG } from '../../constants/template/template.constants';
import { ConfigurationModel } from './../../model/template/template.model';

@NgModule({
    declarations: [],
    imports: [CommonModule],
})
export class TemplateConfigModule {
    static forRoot(templateConfig: ConfigurationModel): ModuleWithProviders<TemplateConfigModule> {
        return {
            ngModule: TemplateConfigModule,
            providers: [
                {
                    provide: TEMPLATE_CONFIG,
                    useValue: templateConfig,
                },
            ],
        };
    }
}
