import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OAuthLibService } from '../../../services/authorization/oauth-lib.service';
import { ConfigService } from '../../../services/template/config.service';

@UntilDestroy()
@Component({
    selector: 'lib-login-callback',
    styleUrls: ['login-callback.component.scss'],
    templateUrl: 'login-callback.component.html',
})
export class LoginCallbackComponent implements OnInit {
    private code: string = '';
    public message: string = '';
    public messageLogin: string = 'Sistema';

    constructor(private oauthService: OAuthLibService, _config: ConfigService) {
        const { messageLogin } = _config.navigationData;
        if (messageLogin) {
            this.messageLogin = messageLogin;
        }
    }

    ngOnInit(): void {
        this.oauthService.callback();
    }
}
