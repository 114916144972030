import { v4 as uuid } from 'uuid';
import { DataObject } from '../template/template.model';
import moment from './moment-wrapper';

export enum UtilTypeDate {
    Mills = 'millis',
    Seconds = 'seconds',
    Minutes = 'minutes',
    Hours = 'hours',
}

export enum BaseTimeToConvert {
    MsToSc = 1000,
    MsToMn = 60000,
    MsToHr = 3600000,
    ScToMn = 60,
    ScToHr = 3600,
    MnToHr = 60,
}
/**
 * @enum CardOrientation
 * @usageNotes 
 * ```
 * Center = 'center',
   Start = 'left',
   End = 'end'
 * ```
 */
export enum CardOrientation {
    Center = 'center',
    Start = 'left',
    End = 'end',
}

export interface OptionsPayloadModel {
    formatDate: string;
}

export interface ApiDataModel {
    data: Array<DataObject>;
    lastKey: string | null;
    pageKey: string | null;
    pageSize: number;
    totalRecords: number;
}
export const NewToken: string = 'new$';
export const IdToken: string = 'temp$';
export type momentFn = (date: any) => moment.Moment;
export const _moment: momentFn = (date: any) => (moment as any).default(date);

export const getUuid: () => string = () => uuid();

export const getNewUuid: () => string = () => `${NewToken}-${uuid()}`;

export const getIdTokenUuid: () => string = () => `${IdToken}-${uuid()}`;

export const isNewDataId: (id: string) => boolean = (id: string) => id?.includes(NewToken);
export interface ErrorHandlerModel {
    [key: number]: string;
}

export enum SortDataType {
    Date,
    Text,
}
export interface SortProperties {
    type: SortDataType;
    isInverted?: boolean;
}
