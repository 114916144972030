import { Directionality } from '@angular/cdk/bidi';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  QueryList,
  forwardRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  stepComplete,
  stepInProgress,
} from '../../../constants/stepper/stepper.constants';
import { DynamicDialogData } from '../../../model/components/dialog.model';
import {
  EStepStatus,
  EStepStatusLabel,
  LibExtendStepParams,
  LibStepperParams,
  StepperData,
} from '../../../model/components/stepper.model';
import { NotificationService } from '../../../services/shared/notification.service';
import { FormCardComponent } from '../../card/form-card.component';
import { StepComponent } from '../step/step.component';

@UntilDestroy()
@Component({
  selector: 'lib-stepper-container',
  templateUrl: './stepper-container.component.html',
  styleUrls: ['./stepper-container.component.scss'],
  providers: [
    {
      provide: CdkStepper,
      useExisting: forwardRef(() => StepperContainerComponent),
    },
  ],
})
export class StepperContainerComponent
  extends CdkStepper
  implements AfterContentInit, AfterViewChecked
{
  constructor(
    _dir: Directionality,
    _changeDetectorRef: ChangeDetectorRef,
    _elementRef: ElementRef<HTMLElement>,
    private notificationService: NotificationService,
    @Optional() private dialogRef: DynamicDialogRef,
    @Optional() private dialogService: DynamicDialogConfig<DynamicDialogData>,
    private confirmService: ConfirmationService
  ) {
    super(_dir, _changeDetectorRef, _elementRef);
  }

  // Inputs
  @Input({ required: true }) params!: LibStepperParams;

  // Outputs
  // This is just for a stepper out off a dialog
  @Output() onFinalize: EventEmitter<any> = new EventEmitter();

  // Overrides variables
  override readonly steps: QueryList<StepComponent> =
    new QueryList<StepComponent>();

  // Private variables
  private _stepsAndSpaces: Array<StepComponent | null> = [];

  // getters

  get totalStepsWithLines(): number {
    return this.steps.length * 2 - 1;
  }
  get stepWidth(): number {
    return 100 / this.totalStepsWithLines;
  }
  get stepsAndSpaces(): Array<StepComponent | null> {
    if (this._stepsAndSpaces.length === 0) this.setStepAndSpaces();
    return this._stepsAndSpaces;
  }
  get isLastStep(): boolean {
    return this.selectedIndex === this.steps.length - 1;
  }
  /**
   * variable to use selected component, avoid for read `this.selected`
   */
  get _selected(): StepComponent {
    return this.selected as StepComponent;
  }

  // Public methods
  public selectStep(step: StepComponent, index: number): void {
    const isNext = this.selectedIndex < index;
    if (
      (isNext && this.isValidNextStep()) ||
      (!isNext && this.isValidPreviousStep(step))
    ) {
      return step.select();
    }
    const message = isNext
      ? 'Debe completarse para continuar'
      : 'No permite regresar a editar';
    const stepToCheck = isNext ? this._selected : step;
    this.showWarningStep(stepToCheck, message);
  }

  public override ngAfterContentInit(): void {
    super.ngAfterContentInit();
    const { isLinear } = this.params;
    this.linear = !!isLinear;
    this.initStepperSteps();
    this.setSelectionChange();
  }

  public override next(): void {
    if (this.isValidNextStep()) {
      super.next();
      if (this.isLastStep && this._selected.completed) {
        this.finalizeStepper();
      }
      return;
    }
    this.showWarningStep(this._selected, 'Debe completarse para continuar');
  }
  public override previous(): void {
    const previousStep = this.steps.get(this.selectedIndex - 1);
    if (this.isValidPreviousStep(previousStep)) {
      super.previous();
      return;
    }
    this.showWarningStep(previousStep, 'No permite regresar a editar');
  }

  public ngAfterViewChecked(): void {
    this.reloadFormCardsChecked();
  }

  // Private Methods

  private finalizeStepper(): void {
    const data = this.steps.map((step: StepComponent) => step.finalize());
    if (this.dialogService) {
      return this.finalizeDialogStepper(data);
    }
    this.finalizeStepperTraditional(data);
  }

  /**
   *
   * @param data
   * @description close the dialog and return the values on all steps
   */
  private finalizeDialogStepper(data: any): void {
    if (this.dialogService?.data?.confirmation) {
      const onAcceptFn = () => this.dialogRef.close(data);
      this.openConfirmationDialog(
        this.dialogService.data.confirmation,
        onAcceptFn
      );
      return;
    }
    this.dialogRef?.close(data);
  }
  /**
   *
   * @param data
   * @description emit the extracted values on all steps
   */
  private finalizeStepperTraditional(data: any): void {
    if (this.params.confirmation) {
      const onAcceptFn = () => this.onFinalize.emit(data);
      this.openConfirmationDialog(this.params.confirmation, onAcceptFn);
      return;
    }
    this.onFinalize.emit(data);
  }

  /**
   *
   * @param confirmation
   * @param onAccept
   * @description open the dialog and override the accept method to fire the user's accept function and the onAccept of this
   */
  private openConfirmationDialog(
    confirmation: Confirmation,
    onAccept: Function
  ): void {
    const { accept } = confirmation;
    if (accept) {
      confirmation.accept = () => {
        accept();
        onAccept();
      };
    }
    this.confirmService.confirm(confirmation);
  }

  /**
   * @description This function is to reload the masonry cards in case the steps contains FormCardComponent and use `isLoad` to avoid multiples operations in the hook cycle
   */
  private reloadFormCardsChecked(): void {
    if (!this._selected.params?.isLoad) {
      const { params } = this._selected;
      params.isLoad = true;
      const formCards = this._selected.getQueryFormCards();
      formCards?.forEach((formCard: FormCardComponent) =>
        formCard.reloadMasonryEvt()
      );
    }
  }

  /**
   *
   * @param reference
   * @param message
   * @description Get the Step params from reference and set the notification warning
   */
  private showWarningStep(
    reference: CdkStep | StepComponent | undefined,
    message: string
  ): void {
    if (reference) {
      const referenceCast = reference as StepComponent;
      const { params } = referenceCast;
      this.notificationService.onWarn(
        `[ ${params?.buttonName} ] ${message}`,
        'Advertencia'
      );
    }
  }

  /**
   * Check if is possible to change the step
   */
  private isValidNextStep(): boolean | undefined {
    return !this.params.isLinear || this._selected?.completed;
  }

  /**
   * Check if is possible to change the step to previous step
   */
  private isValidPreviousStep(
    previousStep: StepComponent | undefined
  ): boolean | undefined {
    return !this.params.isLinear || previousStep?.editable;
  }

  private getStepperData(): StepperData {
    return {
      data: this.params.data,
      dynamicData: this.dialogService?.data,
    };
  }

  /**
   *
   * @returns LibStepObjectParams
   * @description This method loops through the steps and init the params to each step then initializes the required values of each step's parameter
   */
  private initStepperSteps(): void {
    const stepperData = this.getStepperData();
    this.steps.forEach((_step: StepComponent, index: number) => {
      const { params } = _step;
      _step.completed = false;
      if (this.linear) {
        _step.editable = !params?.disablePreviousStep;
      }
      this.initStepParams(_step, params, index);
      _step.setData(stepperData);
    });
  }

  /**
   *
   * @param step
   * @param params
   * @param stepIndex
   * @description Initializes the step to INACTIVE status that sets the style for the DOM, gets the observable for the step's `complete` and subscribes and assigns the `stepComplete` operator
   */
  private initStepParams(
    step: StepComponent,
    params: LibExtendStepParams,
    stepIndex: number
  ): void {
    params.stepIndex = stepIndex;
    params.status = EStepStatus.INACTIVE;
    params.statusLabel = EStepStatusLabel.INACTIVE;
    params.isLoad = false;
    const complete$ = step.getComplete$();
    complete$
      .pipe(untilDestroyed(this), stepComplete(this, params))
      .subscribe((isComplete: boolean) => {
        if (params.autoNext && isComplete) {
          this.next();
        }
      });
  }
  /**
   * @description Create an array of type `Array<StepComponent | null> `in which you assign the steps and null to operate the separator in the DOM.
   * If there are 3 steps return [step, null, step, null, step] that represents `step ---- step ---- step`
   */
  private setStepAndSpaces(): void {
    this._stepsAndSpaces = this.steps.reduce(
      (
        array: Array<StepComponent | null>,
        step: StepComponent,
        index: number
      ) => {
        array.push(step);
        if (index !== this.steps.length - 1) {
          array.push(null);
        }
        return array;
      },
      []
    );
  }
  /**
   * @description Subscribes to the stepper `selectionChange` to assign the stepsProgress operator to its pipe.
   * assign the reloadFormCards operator to its pipe
   */
  private setSelectionChange(): void {
    this.selectionChange.pipe(stepInProgress(this)).subscribe();
  }
}
