export const environment = {
    production: true,
    mock: false,
    appVersion: `${require('../../package.json').version}`,
    clientId: '67q4d813t8s456fipn1t7tieag',
    scope: 'email openid profile',
    uriCallback: 'https://easypay.logialabs.com/login/callback',
    logoutUrl: 'https://easypay.logialabs.com/logout',
    oauthUrl: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_MzsMWUJhj',
    apiUrl: 'https://api.easypay.logialabs.com',
    apiMetaDataUrl: 'https://dev.logiasystems.com',
    revocationEndpoint: 'https://access.easypay.logialabs.com/oauth2/revoke',
    apiKey: '3cI18TZeIM9EhvZrKFOEDaedSGBLziZ8myCfI0Aj',
};
