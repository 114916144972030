import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputMode, LoadingService, PanelForm, PanelParams, PanelService } from 'dashboard-lib';
import { Observable, tap } from 'rxjs';
import { Alert, AlertSelectedAction } from '../model/alert.model';
@UntilDestroy()
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
    public isOpenAlertStatusDialog: boolean = false;
    public isOpenAlertDialog: boolean = false;
    public isOpenAlertConfigurationDialog: boolean = false;
    public isOpenWhitelistDialog: boolean = false;
    public selectedAlert: Alert | null = null;
    public header: string = 'Ver alerta';
    public isNew: boolean = false;
    public pnlAlertMode: InputMode = InputMode.Read;

    private alertPnlParam?: PanelParams;
    private actions: Record<string, Function> = {
        edit: this.openEditAlert.bind(this),
        status: this.openStatusAlert.bind(this),
    };
    private allAlertsEvt?: Function;
    private allUsersWhitelistEvt?: Function;
    public blockLoading$: Observable<boolean> | null = null;

    constructor(panelService: PanelService, private loadingService: LoadingService) {
        panelService
            .getPanels$()
            .pipe(
                untilDestroyed(this),
                tap((pnlForm: PanelForm) => {
                    this.alertPnlParam = pnlForm['pnl-alert'];
                })
            )
            .subscribe();
        this.blockLoading$ = this.loadingService.getBlockLoading$();
    }

    public onSelectedAlert(alertAction: AlertSelectedAction): void {
        const { action, alert } = alertAction;
        this.selectedAlert = alert;
        const actionMethod = this.actions[action];
        actionMethod();
    }

    public onModeInAlert(isWriteMode: boolean): void {
        this.pnlAlertMode = isWriteMode ? InputMode.Write : InputMode.Read;
    }

    public openConfigAlert(): void {
        this.isOpenAlertConfigurationDialog = true;
    }
    public openWhitelistUserAlert(): void {
        this.isOpenWhitelistDialog = true;
    }

    public onOpenAlertDialogChange(isOpen: boolean): void {
        if (!isOpen) this.allAlertsEvt?.();
    }

    public onOpenUserWhitelistDialogChange(isOpen: boolean): void {
        if (!isOpen) this.allUsersWhitelistEvt?.();
    }

    public setAllAlertsEvt(allAlertsEvt: Function): void {
        this.allAlertsEvt = allAlertsEvt;
    }

    public setAllUsersWhitelistEvt(allUsersWhitelistEvt: Function): void {
        this.allUsersWhitelistEvt = allUsersWhitelistEvt;
    }

    private openEditAlert(): void {
        if (this.selectedAlert) {
            this.alertPnlParam?.reloadMasonryEvt();
            this.alertPnlParam?.control?.patchValue(this.selectedAlert);
            this.openAlertDialog(false, InputMode.Read);
        }
    }

    private openAlertDialog(isNew: boolean, mode: InputMode): void {
        this.header = isNew ? 'Crear alerta' : 'Ver alerta';
        this.isNew = isNew;
        this.pnlAlertMode = mode;
        this.isOpenAlertDialog = true;
    }

    private openStatusAlert(): void {
        this.isOpenAlertStatusDialog = true;
    }
}
