import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthLibService } from '../authorization/oauth-lib.service';
import { USE_AUTH, USE_BEARER } from './interceptors.constants';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(private oauthService: OAuthLibService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('token')) {
            const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
            return next.handle(req.clone({ headers }));
        }
        const isBearer = req.context.get(USE_BEARER);
        const useAuth = req.context.get(USE_AUTH);
        if (useAuth) {
            const accessToken = this.oauthService.getToken();
            const token = isBearer && accessToken ? `Bearer ${accessToken}` : null;
            let headers = req.headers.set('Authorization', `${token}`);
            return next.handle(req.clone({ headers }));
        }
        return next.handle(req);
    }
}

export const PROVIDE_AUTHENTICATION_INTERCEPTOR: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
};
