<div class="main-wrapper" [ngClass]="topErrorKlass">
    <ng-container [ngTemplateOutlet]="template"></ng-container>

    <ng-container *ngIf="abstractInputMeta?.showError">
        <small *ngFor="let errorMessage of abstractInputMeta.errorMessages" class="p-error small-error"
            [ngClass]="smallErrorReverseKlass">{{errorMessage}}</small>
    </ng-container>
</div>

<!-- Simple view container -->
<ng-template #groupWrapper>
    <div class="p-field">
        <label *ngIf="!hiddenLabel" class="p-lib-label">{{metaData.label}}</label>
        <div class="input-container">
            <div class="element-action">
                <span *ngIf="isReadMode">{{readValue | metadataTypePipe:metaData}}</span>
                <div #templateContainer></div>
            </div>
            <div class="icon-action">
                <button *ngIf="metaData.buttonAction?.action as action" pButton pRipple type="button" icon="pi pi-info"
                    (click)="action()" class="p-button-rounded p-button-outlined"></button>
            </div>
        </div>

    </div>
</ng-template>

<!-- Group view container -->
<ng-template #simpleWrapper>
    <div class="p-inputgroup" class="group">
        <span *ngIf="isReadMode">{{readValue | metadataTypePipe:metaData}}</span>
        <div #templateContainer></div>
    </div>
</ng-template>