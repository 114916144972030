import { Pipe, PipeTransform } from '@angular/core';
import { DISPLAY_VALUES } from '../components/forms/meta-input-form/meta-input-form.constants';
import { DropdownTypeConfig } from '../model/components/meta-input-form.model';
import { DataObject } from '../model/template/template.model';

@Pipe({
    name: 'metaInputDropdownValues',
})
export class MetaInputDropdownValuesPipe implements PipeTransform {
    /**
     *
     * @param options
     * @param dropdownTypeConfig
     * @returns DataObject[]
     * @description if dropdownTypeConfig exists the options values are replaced for others with `displayValues` where has the text according to the configuration
     */
    transform(options: DataObject[], dropdownTypeConfig: DropdownTypeConfig | null | undefined): DataObject[] {
        if (dropdownTypeConfig) {
            const { multiLabels, separator, customOptionLabel } = dropdownTypeConfig;
            options = options.map((option: DataObject) => {
                const displayValue = !!customOptionLabel
                    ? customOptionLabel(option)
                    : (multiLabels || []).map((label: string) => option[label]).join(separator || ' ');

                return {
                    ...option,
                    [DISPLAY_VALUES]: displayValue,
                };
            });

            dropdownTypeConfig.optionLabel = DISPLAY_VALUES;
        }
        return options;
    }
}
