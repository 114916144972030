import { TemplateRef } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Confirmation } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { CardOrientation } from '../utils/utils.model';
import { DataObject } from './../template/template.model';
import { DropdownTypeConfig } from './meta-input-form.model';

export enum InputType {
    Text = 'text',
    Select = 'select',
    Lookup = 'lookup',
    Endpoint = 'endpoint',
    EndpointMulti = 'endpoint-multi',
    SelectMulti = 'select-multi',
    LookupMulti = 'lookup-multi',
    Check = 'check',
    Textarea = 'textarea',
    Date = 'date',
    DateTime = 'date-time',
    InputSelect = 'input-select',
    Unknown = 'unknown',
    Complex = 'complex',
}

export enum DataType {
    Text = 'string',
    Array = 'array',
    Object = 'object',
    Number = 'number',
    Currency = 'currency',
    Boolean = 'boolean',
    Tel = 'tel',
    Date = 'date',
    Time = 'time',
    Percent = 'percent',
}

export enum ValidationType {
    Email = 'email',
    Max = 'max',
    Min = 'min',
    Required = 'required',
    MinLength = 'minlength',
    MaxLength = 'maxlength',
    Pattern = 'pattern',
}

export enum FormMode {
    Read = 'read',
    Write = 'write',
}

export interface MultipleLabelConfig {
    fieldLabels: string[];
    separator: string;
}
export interface SelectSearchModel {
    optionLabel: string;
    filterBy: string;
    values: Array<DataObject>;
    placeholder: string;
    optionDisabled?: string;
    multipleLabel?: MultipleLabelConfig;
}

export enum MetaDataMode {
    New = 'new',
    Edit = 'modify',
    Table = 'table',
    ReadOnly = 'readOnly',
}

export type GroupControlNameModel = 'input' | 'option';

export interface EntityMetaDataModel {
    [key: string]: MetaDataModel[];
}

export interface MetaConditionModel {
    fieldId: string;
    validations: MetaValidationModel[];
    value: string;
    path?: string;
}

export interface MetaOptionModel {
    value: any;
    text: string;
}

/**
 * path: control name to set validation if null is ignored
 */
export interface MetaValidationModel {
    type: ValidationType;
    param: any;
    errorMessage: string;
    path?: GroupControlNameModel;
}

export interface MetaDataModel {
    field: string;
    type: DataType;
    input: InputType | null;
    label: string;
    lookupType?: string | null;
    metadataId?: string | null;
    placeholder: string | null;
    /**
     * @field {endPoint} used to assign and url endpoint to get data from the default API library or meta data API (*)
     * @example
     *  endPoint = 'somepath'
     *  url = apiUrl + `/somepath`;
     *  @example
     *  endPoint = '*somepath'
     *  url = apiMetaDataUrl + `/somepath`;
     */
    endPoint?: string | null;
    options?: Array<MetaOptionModel>;
    validations: Array<MetaValidationModel>;
    conditions?: MetaConditionModel[];
    editable: boolean;
    hidden?: boolean;
    // Width to object to fit in card
    width?: string;
    //metadata en caso de no usar api
    complexMetadata?: ComplexMetaDataModel;

    //variables para procesar la conversión de datos como un pipe
    customDataPipeFn?: (value: any, meta: MetaDataModel | Partial<MetaDataModel>) => any;

    //En caso de requerir cambio de tipo del MetaDataMode
    metaDataMode?: MetaDataMode;

    // Asigna eventos a control
    valueChanges?: (value: any) => void;
}

/**
 * @interface ExtendedMetaDataModel
 * @see {@link MetaDataModel}
 * @description is an extension of MetaDataModel used to add specific configurations assigned outside to the metadata model service API
 */
export type ExtendedMetaDataModel = MetaDataModel & {
    /**
     * @field {DropdownTypeConfig} used just fot the inputs type dropdown
     * @see {@link DropdownTypeConfig}
     */
    dropDownConfig?: DropdownTypeConfig;
};

export type InputDataModel = Pick<MetaDataModel, 'label' | 'input' | 'placeholder' | 'type' | 'options'> & {
    isReadMode: boolean;
    iconAction: InputActionList;
};

export interface ComplexMetaDataModel {
    field: string;
    metaData: MetaDataModel[];
}

export interface ComplexMetaDataModelObject {
    [key: string]: ComplexMetaDataModelEntity;
}

export interface ComplexMetaDataModelEntity {
    metaData: MetaDataModel[];
    formArray: FormArray;
    isOnceRequired?: boolean;
    field: string;
}

export type ComplexMenuItemEvent = {
    // Default complex action
    action: ComplexAction;
    // Compplex MetaData object
    metaDataComplex: ComplexMetaDataModelEntity | null;
    // Data from control value
    data?: DataObject;
    // event for dispatch
    event?: Function;
};

export interface InputAction {
    icon?: string;
    action: () => void;
}

export interface InputActionList {
    [key: string]: InputAction;
}

export enum TextBoolean {
    True = 'Sí',
    False = 'No',
}

export interface MetaConditionHiddenModel {
    field: string;
    conditions: ConditionHiddenModel[];
    // Debe contener el valor  | ex. foo | baz
    elseConditions?: ConditionHiddenModel;
}

export interface ConditionHiddenModel {
    value: any;
    path?: string;
    fieldsToHidden: string[];
    fieldsToShow: string[];
}

export interface InputTemplateModel {
    [key: string]: TemplateRef<any>;
}

export interface InputFormGroupModel {
    input: FormControl;
    option: FormControl;
}

export enum ComplexAction {
    Add = 'add',
    Del = 'del',
    Save = 'save',
}

export interface ComplexActionModel {
    action: ComplexAction;
    metaData: MetaDataModel;
    actionEvent: (confirmation: Confirmation | null, accept: Function, reject?: Function) => void;
    metaDataComplex?: MetaDataModel[];
    data?: DataObject | null;
}

export interface ComplexConfigOptions {
    [key: string]: ComplexOptions;
}

export interface ComplexOptions {
    columns?: number;
    complexType?: MetaComplexType;
    // Use for hide the search bar in a complex table view
    hiddenSearchBar?: boolean;
    // Use it to update the values
    nextFormArray?: BehaviorSubject<FormArray>;
}

export enum MetaComplexType {
    default = 'default',
    table = 'table',
}

export interface FormCardStyleOptions {
    width?: number;
    orientation?: CardOrientation;
    columns: number;
    paddingContainer?: string;
    masonryOptions?: NgxMasonryOptions;
    isAppendToBody?: boolean;
}
export interface FormCardActionOptions {
    orientation: CardOrientation;
    actionList?: InputActionList;
}

export interface FormCardModel {
    styleOptions: FormCardStyleOptions;
    formMode: FormMode;
    metaData: string | MetaDataModel[];
    actionOptions?: FormCardActionOptions;
    readOnly?: boolean;
    metaDataHidden?: MetaConditionHiddenModel[];
    record?: any;
}
