<div class="main">
    <ng-container *ngIf="oauthModel$ | async as oauth">
        <lib-sidenav *ngIf="oauth.isLogged" class="sidenav"></lib-sidenav>
        <div class="main-container">
            <lib-toolbar *ngIf="oauth.isLogged"></lib-toolbar>
            <div class="content-wrapper">
                <div class="overflow-container">
                    <div class="overflow-content">
                        <ng-content></ng-content>
                    </div>
                </div>
            </div>
            <lib-footer>
            </lib-footer>
        </div>
    </ng-container>

</div>