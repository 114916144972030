<div *ngIf="table$ | async as tableConfig" class="card">
    <!-- {{pe(tableConfig)}} -->
    <!-- <p-toast></p-toast> -->
    <p-toolbar *ngIf="!isReadMode" styleClass="mb-4 gap-2">
        <div class="p-toolbar-group-start">
            <p-menubar [model]="menuTable"></p-menubar>
            <p-button *ngFor="let btn of tableOptions?.actionLeftButtons" [label]="btn.label || ''" [icon]="btn.icon+''"
                [pTooltip]="btn.tooltip" (onClick)="buttonAction($event,btn)" styleClass="p-button-sm action-buttons"
                [style]="{
                    'background-color': btn.bgColor,
                    color: btn.color,
                    border: '2px solid ' + btn.bgColor,
                }"></p-button>

        </div>

        <div class="p-toolbar-group-center">

        </div>

        <div class="p-toolbar-group-end">
            <p-button *ngFor="let btn of tableOptions?.actionRightButtons" [label]="btn.label || ''"
                [icon]="btn.icon+''" [pTooltip]="btn.tooltip" (onClick)="buttonAction($event,btn)"
                styleClass="action-buttons" [style]="{
                    'background-color': btn.bgColor,
                    color: btn.color,
                    border: '2px solid ' + btn.bgColor,
                }"></p-button>

            <span *ngIf="!tableOptions?.searchOptions?.hiddenSearchBar" class="p-input-icon-right padding-toolbar">
                <i class="pi pi-search"></i>
                <input type="text" pInputText [formControl]="searchControl"
                    [placeholder]="searchOptions?.placeholder || 'Buscar...'" />
                <!-- <input type="text" pInputText [formControl]="searchControl"
                    (input)="dt.filterGlobal(searchControl.value,'contains')"
                    [placeholder]="searchOptions?.placeholder || 'Buscar...'" /> -->
            </span>

        </div>
    </p-toolbar>

    <p-table #dt [value]="dataTableFiltered ?? dataTable" [rows]="tableOptions?.rowPerPage || 10"
        [paginator]="!tableOptions?.disablePaginator" [globalFilterFields]="tableOptions?.globalFilterFields"
        [tableStyle]="tableOptions?.tableStyle" [(selection)]="selectedData" [rowHover]="!tableOptions?.disableRowHover"
        [dataKey]="tableOptions?.dataKey || '_id'"
        [currentPageReportTemplate]="tableOptions?.currentPageReportTemplate || 'Mostrando {first} - {last} de {totalRecords} entradas'"
        [showCurrentPageReport]="true" [columns]="headers" responsiveLayout="scroll" [metaKeySelection]="false"
        [showCurrentPageReport]="true" [resizableColumns]="true" [autoLayout]="true" [totalRecords]="dataTable.length"
        editMode="row" [rowsPerPageOptions]="[10,25,50]">

        <!-- <ng-template pTemplate="caption">
      
            <ng-content select="[table-caption]"></ng-content>
        </ng-template> -->

        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="!isReadMode" class="p-table-th-tool">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let header of headers;let i =index" [pSortableColumn]="header.field" [id]="'header-' + i">
                    {{header.header}} <p-sortIcon [field]="header.field"></p-sortIcon></th>
                <th class="p-table-th-tool"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded" let-editing="editing"
            let-rowIndex="rowIndex" let-colIndex="colIndex">

            <tr #htmlTableRowElement [pSelectableRow]="rowData" [pEditableRow]="rowData"
                [pSelectableRowIndex]="rowIndex" (dblclick)="onRowDblClick(rowData,rowIndex)"
                [ngClass]="{'text-decoration-deleted': rowData['_deleted']}">
                <td *ngIf="!isReadMode">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td pResizableColumn *ngFor="let column of columns">
                    <p-cellEditor *ngIf="inputTypeParams[rowIndex]?.[column.field] as params">

                        <ng-template pTemplate="input">
                            <lib-input-meta [params]="params" [inputType]="inputTypeList[params.metaData.input || '']"
                                (onCreated)="onCreateInput($event)"></lib-input-meta>
                        </ng-template>

                        <ng-template pTemplate="output">
                            <ng-container> {{rowData | metadataTypePipe:params.metaData}} </ng-container>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <!-- <td>
                    rating
                    <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false">    </p-rating>
                </td>
                <td>
                    severity
                    <p-tag [value]="product.inventoryStatus" [severity]="getSeverity(product.inventoryStatus)"></p-tag>
                </td> -->
                <td *ngIf="!isReadMode" class="p-table-th-tool ">
                    <button *ngIf="!editing && !rowData['_deleted']" pButton pRipple pInitEditableRow
                        icon="pi pi-pencil" class="p-button-rounded p-button-info mr-2 action-buttons"
                        (click)="onRowEditInit(rowData,rowIndex)"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                        (click)="onRowEditSave(rowData,rowIndex)"
                        class="p-button-success p-button-rounded action-buttons"></button>
                    <button *ngIf="!rowData['_deleted'] && !editing" pButton pRipple icon="pi pi-trash"
                        class="p-button-rounded p-button-warning action-buttons"
                        (click)="deleteItem([rowData])"></button>
                    <button *ngIf="editing && rowData['_updated']" pButton pRipple type="button" pCancelEditableRow
                        icon="pi pi-times" (click)="onRowEditCancel(rowData,rowIndex)"
                        class="p-button-danger p-button-rounded action-buttons"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template *ngIf="tableOptions?.footerMessage" pTemplate="summary">
            <div class="flex align-items-center justify-content-between">
                {{tableOptions?.footerMessage}}
                <!-- Total de {{dataTable ?dataTable.length : 0 }} registros -->
            </div>
        </ng-template>
    </p-table>
</div>
<!-- <p-dialog [(visible)]="productDialog" [style]="{ width: '450px' }" header="Product Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <img [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + product.image" [alt]="product.image"
            class="block m-auto pb-3" *ngIf="product.image" />
        <div class="field">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" [(ngModel)]="product.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !product.name">Name is required.</small>
        </div>
        <div class="field">
            <label for="description">Description</label>
            <textarea id="description" pInputTextarea [(ngModel)]="product.description" required rows="3"
                cols="20"></textarea>
        </div>

        <div class="field">
            <label for="inventoryStatus">Inventory Status</label>
            <p-dropdown [(ngModel)]="product.inventoryStatus" inputId="inventoryStatus" [options]="statuses">
                <ng-template pTemplate="selectedItem">
                    <p-tag [value]="product.inventoryStatus.toUpperCase()"
                        [severity]="getSeverity(product.inventoryStatus.toUpperCase())"></p-tag>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <p-tag [value]="option.label" [severity]="getSeverity(option.label)"></p-tag>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field">
            <label class="mb-3">Category</label>
            <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                    <p-radioButton id="category1" name="category" value="Accessories"
                        [(ngModel)]="product.category"></p-radioButton>
                    <label for="category1">Accessories</label>
                </div>
                <div class="field-radiobutton col-6">
                    <p-radioButton id="category2" name="category" value="Clothing"
                        [(ngModel)]="product.category"></p-radioButton>
                    <label for="category2">Clothing</label>
                </div>
                <div class="field-radiobutton col-6">
                    <p-radioButton id="category3" name="category" value="Electronics"
                        [(ngModel)]="product.category"></p-radioButton>
                    <label for="category3">Electronics</label>
                </div>
                <div class="field-radiobutton col-6">
                    <p-radioButton id="category4" name="category" value="Fitness"
                        [(ngModel)]="product.category"></p-radioButton>
                    <label for="category4">Fitness</label>
                </div>
            </div>
        </div>

        <div class="formgrid grid">
            <div class="field col">
                <label for="price">Price</label>
                <p-inputNumber id="price" [(ngModel)]="product.price" mode="currency" currency="USD"
                    locale="en-US"></p-inputNumber>
            </div>
            <div class="field col">
                <label for="quantity">Quantity</label>
                <p-inputNumber id="quantity" [(ngModel)]="product.quantity"></p-inputNumber>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog> -->

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>