import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PrimeIcons } from 'primeng/api';
import { InputBooleanOptions } from '../../../../model/components/input-meta/input-boolean.model';
import { AbstractInputMeta } from '../abstract-input-meta.class';

@UntilDestroy()
@Component({
    selector: 'lib-input-bool',
    templateUrl: './input-bool.component.html',
    styleUrls: ['./input-bool.component.scss'],
})
export class InputBoolComponent extends AbstractInputMeta {
    get buttonLabel(): string {
        return this.inputBoolOptions?.labelInput || this.metaData.label;
    }
    get inputBoolOptions(): InputBooleanOptions | undefined {
        return this.metaData.inputMetaOptions as InputBooleanOptions;
    }
    get onIcon(): string {
        return this.inputBoolOptions?.onIcon || PrimeIcons.CHECK_CIRCLE;
    }
    get offIcon(): string {
        return this.inputBoolOptions?.offIcon || PrimeIcons.MINUS_CIRCLE;
    }
    get onLabel(): string {
        return `${this.buttonLabel} ( ${this.inputBoolOptions?.onLabel || 'Sí'} )`;
    }
    get offLabel(): string {
        return `${this.buttonLabel} ( ${this.inputBoolOptions?.offLabel || 'No'} )`;
    }
    get labelReplacedKlass(): string {
        return !this.metaData.metaDataStyleOptions?.hiddenLabel ? 'p-togglebutton-height' : '';
    }

    protected override abstractInit(): void {
        if (this.formControl.value == null) this.formControl.setValue(false);
    }

    public override destroyObservables(): void {}
    public override onChangesMode(): void {}
}
