import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, map, of, startWith } from 'rxjs';
import { VALIDATIONS } from '../../constants/form/form.constants';
import { DataObject } from '../../model/template/template.model';
import { SharedApiService } from '../shared/shared-api.service';
import {
    ComplexMetaDataModel,
    ComplexMetaDataModelObject,
    DataType,
    EntityMetaDataModel,
    MetaConditionModel,
    MetaDataMode,
    MetaDataModel,
    MetaValidationModel,
} from './../../model/components/form.model';

@UntilDestroy()
@Injectable()
export class LibFormService {
    constructor(private fb: FormBuilder, private sharedApiService: SharedApiService) {}

    public getFormGroup(metaData: MetaDataModel[]): FormGroup {
        const controls = metaData.reduce((group: DataObject, meta: MetaDataModel) => {
            return { ...group, [meta.field]: this.getControl(meta) };
        }, {});
        return this.fb.group(controls);
    }

    public getComplexMetaDataObject$(
        formGroup: FormGroup,
        metaDataList: MetaDataModel[]
    ): Observable<ComplexMetaDataModelObject> {
        const complexMetaDataList$: Observable<ComplexMetaDataModel>[] = metaDataList
            .filter((meta: MetaDataModel) => meta.metadataId || meta.complexMetadata)
            .map((meta: MetaDataModel) => {
                return meta.complexMetadata ? of(meta.complexMetadata) : this.getComplexMetaDataModel$(meta);
            });
        return this.getComplexMetaDataModelObject$(formGroup, complexMetaDataList$);
    }

    public setCondition(formGroup: FormGroup, metaData: MetaDataModel) {
        if (metaData.conditions) {
            metaData.conditions.forEach((condition: MetaConditionModel) => {
                const controlCondition = formGroup.get(condition.fieldId) as FormControl;
                const control = formGroup.get(metaData.field) as FormControl;
                controlCondition?.valueChanges.pipe(untilDestroyed(this), startWith(null)).subscribe((value: any) => {
                    const _value = typeof value === 'boolean' ? String(value) : value;
                    const valueToCheck = condition.path ? value[condition.path] : _value;
                    const isMatchCondition = this.isMatchCondition(valueToCheck, condition.value);
                    if (isMatchCondition) {
                        this.setValidationControl(control, condition.validations);
                    } else {
                        control.clearValidators();
                        this.setValidationControl(control, metaData.validations);
                    }
                    control.updateValueAndValidity({ emitEvent: false });
                });
            });
        }
    }

    private isMatchCondition(value: string | [], condition: string | null): boolean {
        if (Array.isArray(value)) {
            return value?.length === 0 && !condition;
        }
        if (value != null && condition?.includes('~')) {
            const val = condition.split('~')[1];
            return val !== value;
        }
        return value === condition;
    }

    private getComplexMetaDataModelObject$(
        formGroup: FormGroup,
        complexMetaDataList$: Observable<ComplexMetaDataModel>[]
    ) {
        return complexMetaDataList$.length === 0
            ? of({})
            : combineLatest(complexMetaDataList$).pipe(
                  map((complexMetaDataList: ComplexMetaDataModel[]) =>
                      this.getComplexMetaDataEntityObject$(formGroup, complexMetaDataList)
                  )
              );
    }

    private getComplexMetaDataEntityObject$(
        formGroup: FormGroup,
        complexMetaDataList: ComplexMetaDataModel[]
    ): ComplexMetaDataModelObject {
        return complexMetaDataList.reduce(
            (complexMetaDataObject: ComplexMetaDataModelObject, complexMetaData: ComplexMetaDataModel) => {
                const _complexMetaDataObject = {
                    [complexMetaData.field]: {
                        field: complexMetaData.field,
                        metaData: complexMetaData.metaData,
                        formArray: formGroup.get(complexMetaData.field) as FormArray,
                    },
                };
                return { ...complexMetaDataObject, ..._complexMetaDataObject };
            },
            {}
        );
    }

    private getAbstractControl(type: DataType): FormControl | FormArray | FormGroup {
        if (type === DataType.Array) {
            return this.fb.array([]);
        }
        if (type === DataType.Object) {
            return new FormGroup({
                input: new FormControl(null),
                option: new FormControl(null),
            });
        }
        return new FormControl();
    }

    private getControl(meta: MetaDataModel): AbstractControl {
        const control = this.getAbstractControl(meta.type);
        if (meta.valueChanges) control.valueChanges.pipe(untilDestroyed(this)).subscribe(meta.valueChanges);
        const validationForControl =
            control instanceof FormGroup
                ? this.setValidationGroupControl.bind(this)
                : this.setValidationControl.bind(this);
        validationForControl(control, meta.validations);
        control.updateValueAndValidity();
        return control;
    }

    private setValidationGroupControl(control: AbstractControl, validations: MetaValidationModel[]): void {
        validations.forEach((validationModel: MetaValidationModel) => {
            if (validationModel.path) {
                const ctrl = (control as FormGroup).controls[validationModel.path];
                this.setValidation(ctrl, validationModel);
            }
        });
    }
    private setValidationControl(control: AbstractControl, validations: MetaValidationModel[]): void {
        validations.forEach((validationModel: MetaValidationModel) => this.setValidation(control, validationModel));
    }

    private setValidation(control: AbstractControl, validationModel: MetaValidationModel) {
        const validation = this.getValidation(validationModel);
        if (validation) {
            if (control.hasValidator(validation)) {
                control.setValidators(validation);
            } else {
                control.addValidators(validation);
            }
        }
    }

    private getValidation(validation: MetaValidationModel): any {
        const { type, param } = validation;
        const validations = VALIDATIONS(param);
        return validations[type];
    }

    private getComplexMetaDataModel$(meta: MetaDataModel): Observable<ComplexMetaDataModel> {
        const metadataId: string = meta.metadataId || '';
        const metaDataMode = meta.metaDataMode || MetaDataMode.New;
        return this.sharedApiService.getMetaData$(metadataId, metaDataMode).pipe(
            map((entityMetaDataModel: EntityMetaDataModel) => {
                const metaDataList: MetaDataModel[] = entityMetaDataModel[metadataId];
                const complexMetaData: ComplexMetaDataModel = {
                    field: meta.field,
                    metaData: metaDataList,
                };
                return complexMetaData;
            })
        );
    }
}
