<p-card [header]="params.title" [subheader]="params.subTitle" styleClass="card-message"
    [style]="{'box-shadow':borderColor +' 0px 1.6px 3.6px 0px, '+ borderColor +' 0px 0.3px 0.9px 0px'}">
    <ng-template pTemplate="title" let-title>
        <i *ngIf="params.icon" [class]="params.icon" class="card-icon"
            [style.box-shadow]="'0px 0px 5px 0px ' + borderColor" [style.color]="borderColor"></i>
    </ng-template>
    <ng-template pTemplate="header">
        <span class="top-line" [style.background]="borderColor"></span>
    </ng-template>

    <p *ngIf="params.body">{{params.body}}</p>
    <ng-content select="[content]"></ng-content>

    <ng-template *ngIf="params.actions" pTemplate="footer">
        <div class="footer">
            <ng-content select="[topFooter]"></ng-content>
            <div class="action-buttons">
                <p-button *ngFor="let btn of params.actions" [label]="btn.label || ''" [icon]="btn.icon+''"
                    [pTooltip]="btn.tooltip" (onClick)="buttonAction($event,btn)"
                    styleClass="p-button-sm action-buttons" [size]="btn.size" [style]="{
                        'background-color': btn.bgColor,
                        color: btn.color,
                        border: '2px solid ' + btn.bgColor,
                    }"></p-button>
            </div>
            <ng-content select="[bottomFooter]"></ng-content>
        </div>
    </ng-template>
</p-card>