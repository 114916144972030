import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { ConfirmationService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { getAdditionalControls } from '../../constants/dynamic-table/dynamic-table.constants';
import { actionEventComplex, isLoadableInput } from '../../constants/form/form.constants';
import { AvatarImageDefault } from '../../constants/shared/shared.constants';
import {
    ComplexAction,
    ComplexActionModel,
    ComplexConfigOptions,
    ComplexMenuItemEvent,
    ComplexMetaDataModelEntity,
    ComplexMetaDataModelObject,
    ConditionHiddenModel,
    ExtendedMetaDataModel,
    FormMode,
    InputActionList,
    MetaConditionHiddenModel,
    MetaDataModel,
} from '../../model/components/form.model';
import { DropDownCascade } from '../../model/components/meta-input-form.model';
import { DataObject } from '../../model/template/template.model';
import { CardOrientation } from '../../model/utils/utils.model';
import { LibFormService } from '../../services/components/lib-form.service';
import { LoadingService } from '../../services/shared/loading.service';
import { setHiddenMetaData } from '../../utils/util-form';
import { MetaInputFormComponent } from '../forms/meta-input-form/meta-input-form.component';
@UntilDestroy()
@Component({
    selector: 'lib-form-card',
    templateUrl: './form-card.component.html',
    styleUrls: ['./form-card.component.scss'],
})
export class FormCardComponent implements OnInit, OnChanges {
    private _width: number = 100;
    private _paddingContainer: string = '30px 0';
    private _orientation: string = CardOrientation.Start;
    private _orientationActions: string = CardOrientation.Start;
    private _columns: number = 1;
    private loadedInputs: DataObject = {};
    private _masonryOptions: NgxMasonryOptions = {
        columnWidth: 5,
        itemSelector: '.masonry-item',
    };

    private widthInputsCard: { [key: string]: number } | null = null;

    private lastUuid: string | null = null;
    private firstUuid: string | null = null;
    private renderedLastComponent: boolean = false;

    @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent | null = null;
    @ViewChildren(MetaInputFormComponent) cmpts!: QueryList<MetaInputFormComponent>;

    @Input() set width(_width: number) {
        this._width = _width;
    }

    @Input() set orientation(_orientation: string) {
        this._orientation = _orientation;
    }

    @Input() set orientationActions(_orientationActions: string) {
        this._orientationActions = _orientationActions;
    }

    @Input() set columns(_columns: number) {
        this._columns = _columns;
    }

    @Input() set paddingContainer(_paddingContainer: string) {
        this._paddingContainer = _paddingContainer;
    }

    @Input() set masonryOptions(_masonryOptions: NgxMasonryOptions) {
        const { columnWidth, itemSelector } = this._masonryOptions;
        this._masonryOptions = { ..._masonryOptions, columnWidth, itemSelector };
    }

    @Input() metaData: ExtendedMetaDataModel[] = [];
    @Input() formMode: FormMode = FormMode.Read;
    @Input() readOnly: boolean = false;
    @Input() iconInputActionList: InputActionList | null = null;
    @Input() metaDataHidden: MetaConditionHiddenModel[] = [];
    @Input() isAppendToBody: boolean = false;
    @Input() complexConfigOptions: ComplexConfigOptions | null = null;
    @Input() record: any = null;

    @Input() formGroup!: FormGroup;
    @Output() formGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    @Output() onMenuComplexEvent: EventEmitter<ComplexActionModel> = new EventEmitter();

    get width() {
        return this._width;
    }

    get orientation() {
        return this._orientation;
    }

    get orientationActions() {
        return this._orientationActions;
    }

    get widthCard(): number {
        return 100 / this._columns;
    }
    get widthForm(): { [key: string]: number } {
        if (!this.widthInputsCard) this.setWidthInputsCard();
        return this.widthInputsCard || {};
    }

    get controls() {
        return this.formGroup.controls as { [key: string]: FormControl };
    }

    get iconActionList(): InputActionList {
        return this.iconInputActionList ? this.iconInputActionList : {};
    }

    get paddingContainer(): string {
        return this._paddingContainer;
    }

    get masonryOptions(): NgxMasonryOptions {
        return this._masonryOptions;
    }

    public img = AvatarImageDefault;
    public WRITE_MODE = FormMode.Write;
    public metaDataComplexList$: Observable<ComplexMetaDataModelObject> | null = null;
    public reloadMasonryEvt: Function = this.reloadMasonry.bind(this);

    constructor(private formService: LibFormService, private confirmService: ConfirmationService, private loadingService: LoadingService) {}

    public ngOnInit(): void {
        this.formGroup = this.formService.getFormGroup(this.metaData);
        this.metaDataComplexList$ = this.getMetaDataComplexList$(this.formGroup);
        this.setConditionsToForm(this.formGroup);
        this.setConditionsHiddenToForm();

        this.loadedInputs = this.metaData
            .filter((meta: MetaDataModel) => isLoadableInput(meta.input))
            .reduce((inputs: DataObject, meta: MetaDataModel) => {
                return { ...inputs, [meta.field]: false };
            }, {});
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { formMode, record } = changes;
        if (formMode) {
            this.reloadMasonry();
            this.checkFormModeActions(formMode);
        }
        if (record) {
            // ToDo: Refactor the way to create a complex object when the record changes
        }
    }

    public onRenderInput(component: MetaInputFormComponent): void {
        if (this.cmpts) {
            const { first, last } = this.cmpts;
            if (first?.uuid && this.firstUuid == null) {
                this.firstUuid = this.cmpts.first?.uuid;
                this.loadingService.block();
            }
            if (last?.uuid && this.lastUuid == null) {
                this.lastUuid = this.cmpts.last?.uuid;
            }
            if (this.lastUuid != null && component.uuid === this.lastUuid && !this.renderedLastComponent) {
                this.renderedLastComponent = true;
                this.reloadMasonry();
                this.loadingService.unblock();
            }
        }
    }

    public onLoadInput(metaData: MetaDataModel | Partial<MetaDataModel>) {
        this.loadedInputs[metaData.field || ''] = true;
        if (this.isAllLoaded()) {
            this.reloadMasonry();
        }
    }

    public addComplexEvent(complexMetaData: ComplexMetaDataModelEntity) {
        if (this.record) {
            const isPropertyArray = this.record[complexMetaData.field]?.length;
            const propertyObjectArray = isPropertyArray ? this.record[complexMetaData.field] : [1];
            propertyObjectArray?.map(() => {
                const group = this.formService.getFormGroup(complexMetaData.metaData);
                const additionalControls = getAdditionalControls(false);
                Object.keys(additionalControls).forEach((key: string) => group.addControl(key, additionalControls[key]));
                complexMetaData.formArray.push(group);
            });
            if (!isPropertyArray) {
                this.record = { ...this.record, [complexMetaData.field]: [this.record[complexMetaData.field]] };
            }
        }
        this.formGroup.patchValue(this.record);
        this.formGroupChange.emit(this.formGroup);
    }

    public menuComplexEvent(complexItemEvent: ComplexMenuItemEvent, metaData: MetaDataModel): void {
        const { action, metaDataComplex, event } = complexItemEvent;
        if (metaDataComplex) {
            const actionEvent = actionEventComplex(event, this.formGroup, metaData, this.confirmService);

            if (action === ComplexAction.Add) {
                this.reloadMasonry();
            }
            return this.onMenuComplexEvent.emit({
                action,
                metaData,
                data: complexItemEvent.data,
                metaDataComplex: metaDataComplex.metaData,
                actionEvent,
            });
        }
        return this.onMenuComplexEvent.emit({ action, metaData, actionEvent: () => {} });
    }

    public onCascade(dropDownCascades: DropDownCascade[]): void {
        dropDownCascades.forEach(this.setCascadeToDropDown.bind(this));
    }

    private setCascadeToDropDown(dropDownCascade: DropDownCascade): void {
        const { fieldToCascade, options } = dropDownCascade;
        const metaToCascade = this.cmpts.find((metaInput: MetaInputFormComponent) => metaInput.metaData.field === fieldToCascade);
        if (metaToCascade) metaToCascade.setOptions(options);
    }

    private checkFormModeActions(formMode: SimpleChange): void {
        if (formMode.previousValue === FormMode.Write && formMode.currentValue === FormMode.Read) {
            this.formGroup.patchValue(this.record);
        }
    }

    private getMetaDataComplexList$(formGroup: FormGroup): Observable<ComplexMetaDataModelObject> {
        return this.formService.getComplexMetaDataObject$(formGroup, this.metaData).pipe(
            tap((complexObject: ComplexMetaDataModelObject) => {
                if (Object.keys(complexObject).length === 0) {
                    formGroup.patchValue(this.record);
                    this.formGroupChange.emit(formGroup);
                }
            })
        );
    }

    private setConditionsToForm(formGroup: FormGroup) {
        this.metaData.forEach((metaData: MetaDataModel) => this.formService.setCondition(formGroup, metaData));
    }

    private setConditionsHiddenToForm() {
        this.metaDataHidden.forEach((metaCondition: MetaConditionHiddenModel) =>
            this.getValueChangesHiddenCondition(metaCondition)?.pipe(untilDestroyed(this)).subscribe()
        );
    }

    private getValueChangesHiddenCondition(metaCondition: MetaConditionHiddenModel): Observable<any> | undefined {
        const control = this.formGroup.get(metaCondition.field);
        return control?.valueChanges.pipe(
            tap(value => {
                if (metaCondition.elseConditions) {
                    const valueToCheck = value && metaCondition.elseConditions.path ? value[metaCondition.elseConditions.path] : value;
                    this.emitElseHiddenCondition(valueToCheck, metaCondition.elseConditions);
                }
                const conditions = metaCondition.conditions.filter((condition: ConditionHiddenModel) => {
                    if (value && condition.path) {
                        const extractValue = value[condition.path];
                        return extractValue === condition.value;
                    }
                    return value === condition.value;
                });
                conditions.forEach(this.emitHiddenCondition.bind(this));
                this.metaData = [...this.metaData];
                setTimeout(this.reloadMasonry.bind(this), 500);
            })
        );
    }

    private emitElseHiddenCondition(value: any, elseConditions: ConditionHiddenModel): void {
        const values = elseConditions.value.split('|');
        const isMatch = values.includes(value);
        if (!isMatch) {
            this.emitHiddenCondition(elseConditions);
        }
    }

    private emitHiddenCondition(condition: ConditionHiddenModel): void {
        setHiddenMetaData(this.metaData, true, ...condition.fieldsToHidden);
        setHiddenMetaData(this.metaData, false, ...condition.fieldsToShow);
    }

    private reloadMasonry(): void {
        this.masonry?.reloadItems();
        this.masonry?.layout();
    }

    private isAllLoaded(): boolean {
        return Object.values(this.loadedInputs).every(isTrue => isTrue);
    }

    private setWidthInputsCard(): void {
        this.widthInputsCard = this.metaData.reduce((_width: DataObject, meta: ExtendedMetaDataModel) => {
            return {
                ..._width,
                [meta.field]: (parseFloat(meta.width || '') || this.widthCard) - (this.widthCard * 3) / 100,
            };
        }, {});
    }
}
