import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs';
import { TableControlNames } from '../../constants/components/input-table.constants';
import { ConditionMetaData, MetaDataExtended } from '../../model/components/meta-data.model';
import { DataObject } from '../../model/template/template.model';
import { getUuid } from '../../model/utils/utils.model';
import { createConditionsForControl, createControl } from '../../utils/controls-operators';
import { SharedApiService } from '../shared/shared-api.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class MetaFormControlService {
    constructor(private sharedApi: SharedApiService, private fb: FormBuilder) {}

    public initControl(metaData: MetaDataExtended<any>[]): FormGroup {
        const formGroup = this.createFormGroup(metaData);
        metaData.forEach((meta: MetaDataExtended<any>) => this.addConditionsToControlGroup(formGroup, meta));
        return formGroup;
    }

    public createTableControls(controlRow: FormGroup): void {
        controlRow.addControl(TableControlNames.ID, new FormControl<string>(`${TableControlNames.ID}-${getUuid()}`));
        controlRow.addControl(TableControlNames.DELETED, new FormControl<boolean>(false));
        controlRow.addControl(TableControlNames.UPDATED, new FormControl<boolean>(false));
        controlRow.addControl(TableControlNames.NEW, new FormControl<boolean>(true));
    }

    // public initConditionsHidden(reloadMasonry: Function, metaData: MetaDataExtended<any>[], formGroup: FormGroup): void {
    //     const conditionsMetaData = metaData.flatMap((meta: MetaDataExtended<any>) => meta.conditionsHidden || []);
    //     conditionsHiddenToForm$(reloadMasonry, metaData, formGroup, conditionsMetaData)
    //         .filter(Boolean)
    //         .forEach((condition$: Observable<any> | undefined) => condition$?.pipe(untilDestroyed(this)).subscribe());
    // }

    // public createMetaFormConfig(
    //     metaData: MetaData[],
    //     reloadMasonry: Function,
    //     panelConfiguration: PanelConfiguration,
    //     panelMetaDataExtendedList: PanelMetaDataExtendedList,
    //     metaDataOptions?: MetaDataOptions<any>
    // ): MetaFormConfig {
    //     const metaDataBH$ = new BehaviorSubject<MetaDataExtended<any>[]>([]);
    //     const metaFormConfig: MetaFormConfig = {};
    //     const metaData$ = this.getObsMetaData(metaDataBH$, metaFormConfig, reloadMasonry, panelConfiguration);
    //     const metaDataExtended = this.extendedMetaData(metaData, panelConfiguration, panelMetaDataExtendedList, metaDataOptions);
    //     metaDataBH$.next(metaDataExtended);
    //     return { ...metaFormConfig, metaData$ };
    // }

    // private getObsMetaData(
    //     metaDataBH$: BehaviorSubject<MetaDataExtended<any>[]>,
    //     metaFormConfig: MetaFormConfig,
    //     reloadMasonry: Function,
    //     panelConfiguration: PanelConfiguration
    // ): Observable<MetaDataExtended<any>[]> {
    //     return metaDataBH$.asObservable().pipe(
    //         tap((meta: MetaDataExtended<any>[]) => {
    //             const initInputTypeParams = () =>
    //                 this.getInputTypeParams(meta, metaFormConfig.control as FormGroup, panelConfiguration.inputMode, reloadMasonry);
    //             metaFormConfig.control = this.getFormGroupFromMeta(meta, reloadMasonry);
    //             metaFormConfig.widthInputList = this.getWidthListFromMeta(meta, panelConfiguration.styleOptions);
    //             metaFormConfig.createdInputList = this.getInputListFromMeta(meta);
    //             metaFormConfig.paramsInputList = initInputTypeParams();
    //             metaFormConfig.initInputTypeParams = initInputTypeParams;
    //         })
    //     );
    // }

    // private getFormGroupFromMeta(_metaData: MetaDataExtended<any>[], reloadMasonry: Function): FormGroup {
    //     const formGroup = this.initControl(_metaData);
    //     this.initConditionsHidden(reloadMasonry, _metaData, formGroup);
    //     return formGroup;
    // }

    // private getWidthListFromMeta(_metaData: MetaDataExtended<any>[], styleOptions: PanelStyleOptions): { [key: string]: number } {
    //     const widthCard = 100 / styleOptions.columns;
    //     return createWidthInputListForCard(_metaData, widthCard);
    // }

    // // ToDo: Cambiar la condición a false, solo es para los complex
    // private getInputListFromMeta(metaData: MetaDataExtended<any>[]): { [field: string]: boolean } {
    //     return metaData.reduce(
    //         (inputList: { [field: string]: boolean }, meta: MetaDataExtended<any>) => ({ ...inputList, [meta.field]: !meta.input }),
    //         {}
    //     );
    // }

    // private getInputTypeParams(
    //     metaData: MetaDataExtended<any>[],
    //     formGroup: FormGroup,
    //     inputMode: InputMode,
    //     reloadMasonry: Function
    // ): { [key: string]: InputTypeParams<any> } {
    //     return metaData.reduce(
    //         (inputList: { [field: string]: InputTypeParams<any> }, meta: MetaDataExtended<any>) => ({
    //             ...inputList,
    //             [meta.field]: {
    //                 metaData: meta,
    //                 control: formGroup.controls[meta.field],
    //                 mode: inputMode,
    //                 reloadMasonry,
    //             },
    //         }),
    //         {}
    //     );
    // }

    // private extendedMetaData(
    //     _metaData: MetaData[],
    //     panelConfiguration: PanelConfiguration,
    //     panelMetaDataExtendedList: PanelMetaDataExtendedList,
    //     metaDataOptions?: MetaDataOptions<any>
    // ): MetaDataExtended<any>[] {
    //     return _metaData.map((metaData: MetaData) => {
    //         const _metaParams = metaDataOptions?.[metaData.field];
    //         const metaDataExtended = (_metaParams ? { ...metaData, ..._metaParams } : metaData) as MetaDataExtended<any>;
    //         this.setErrorMessageStyle(metaDataExtended, panelConfiguration);
    //         panelMetaDataExtendedList = { ...panelMetaDataExtendedList, [metaData.field]: { metaDataExtended } };
    //         return metaDataExtended;
    //     });
    // }

    // private setErrorMessageStyle(metaDataExtended: MetaDataExtended<any>, panelConfiguration: PanelConfiguration): void {
    //     const errorMessageInTop = (metaDataExtended.metaDataStyleOptions as Object)?.hasOwnProperty('errorMessageInTop')
    //         ? metaDataExtended.metaDataStyleOptions?.errorMessageInTop
    //         : panelConfiguration.styleOptions.errorMessageInTop;
    //     const { metaDataStyleOptions } = metaDataExtended;
    //     metaDataExtended.metaDataStyleOptions = { ...metaDataStyleOptions, errorMessageInTop };
    // }

    private createFormGroup(metaData: MetaDataExtended<any>[]): FormGroup {
        const controls = metaData.reduce(
            (group: DataObject, meta: MetaDataExtended<any>) => ({ ...group, [meta.field]: createControl(meta, this) }),
            {}
        );
        return this.fb.group(controls);
    }

    /**
     *
     * @param formGroup
     * @param metaData
     * @description The method loops through all the metadata conditions and creates the subscriptions to the `metaDataControl`'s valueChanges to perform the comparison and assign the new validations.
     */
    private addConditionsToControlGroup(formGroup: FormGroup, metaData: MetaDataExtended<any>): void {
        const metaDataControl = formGroup.get(metaData.field) as FormControl;
        const conditionsForControlFn = createConditionsForControl(metaDataControl, metaData.validations);

        metaData.conditions?.forEach((condition: ConditionMetaData) => {
            const conditionToCompare = conditionsForControlFn(condition);
            const controlCondition = formGroup.get(condition.fieldId) as FormControl;
            controlCondition?.valueChanges.pipe(untilDestroyed(this), startWith(null)).subscribe(conditionToCompare);
        });
    }
}
