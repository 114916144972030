import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    ButtonActionResponse,
    CardMessageParams,
    DataObject,
    SortDataType,
    SortProperties,
    filterMultipleByTerms,
    generateMapRandomColor,
    isEmptyArray,
    sortBy,
} from 'dashboard-lib';
import { LazyLoadEvent, PrimeIcons, SelectItemGroup } from 'primeng/api';
import { BehaviorSubject, Observable, map, startWith, tap } from 'rxjs';
import { AlertTypeImage, SortAlertOptions, filterAlertOptions } from '../../configuration/dashboard.config';
import { AlertSort, AlertSortAttr, AlertType } from '../../constants/dashboard.constants';
import { Alert, AlertResponse, AlertSelectedAction } from '../../model/alert.model';
import { AlertService } from '../../services/alert.service';
@UntilDestroy()
@Component({
    selector: 'app-alert-list',
    templateUrl: './alert-list.component.html',
    styleUrls: ['./alert-list.component.scss'],
})
export class AlertListComponent implements OnInit {
    private _alertsParams$: BehaviorSubject<CardMessageParams<Alert>[]> = new BehaviorSubject([] as CardMessageParams<Alert>[]);
    private pageNumber: number = 1;

    public alertsParams: CardMessageParams<Alert>[] = [];
    public alertsParams$: Observable<CardMessageParams<Alert>[]> = this._alertsParams$.asObservable();
    public sortOptions = SortAlertOptions;
    public filterOptions: SelectItemGroup[] = filterAlertOptions;
    public sortControl: FormControl = new FormControl(AlertSort.DateAsc);
    public filterControl: FormControl = new FormControl();
    public options: DataObject[] = [];
    public first: number = 0;
    public rows: number = 100;
    public loading: boolean = false;
    public totalRecords: number = 0;

    @Output() selectedAlert: EventEmitter<AlertSelectedAction> = new EventEmitter();
    @Output() setAllAlertsEvt: EventEmitter<Function> = new EventEmitter();
    @Output() setAllUsersWhitelistEvt: EventEmitter<Function> = new EventEmitter();

    get dateFormat(): string {
        return 'dd MMM yyyy HH:mm:ss';
    }

    constructor(private alertService: AlertService, private el: ElementRef, private renderer: Renderer2) {}

    public ngOnInit(): void {
        this.setAllAlerts();
        this.initControlsEvent();
        this.setAllAlertsEvt.emit(this.setAllAlerts.bind(this));
        this.setAllUsersWhitelistEvt.emit(this.setAllAlerts.bind(this));
    }

    public editAlert(alert: Alert): void {
        this.selectedAlert.emit({ alert, action: 'edit' });
    }

    public loadingItems(event: LazyLoadEvent): void {
        this.loading = true;
        const page = event.first! / event.rows!;
        this.pageNumber = page ? page + 1 : 1;
        this.setAllAlerts();
    }

    public editStatusAlert(alert: Alert): void {
        this.selectedAlert.emit({ alert, action: 'status' });
    }
    public updateFirst(event: any) {
        this.first = event.first;
    }

    public onPage(event: any): void {
        this.loadingItems({
            first: event.first,
            rows: event.rows,
        });
    }

    private setAllAlerts(): void {
        this.alertService
            .getAlerts(this.pageNumber, this.rows)
            .pipe(
                untilDestroyed(this),
                map((alertResponse: AlertResponse) => {
                    this.totalRecords = alertResponse.total;
                    return alertResponse.results;
                }),
                map((alerts: Alert[]) => alerts.map<CardMessageParams<Alert>>(this.getCardMessageParamsByAlert.bind(this))),
                tap((alerts: CardMessageParams<Alert>[]) => {
                    this.loading = false;
                    this.alertsParams = alerts;
                    this.filterAlerts(this.filterControl.value ?? []);
                    this.sortAlert(this.sortControl.value);
                })
            )
            .subscribe();
    }

    private initControlsEvent(): void {
        this.sortControl.valueChanges.pipe(untilDestroyed(this), startWith(AlertSort.DateAsc), tap(this.sortAlert.bind(this))).subscribe();

        this.filterControl.valueChanges
            .pipe(
                untilDestroyed(this),
                tap(this.filterAlerts.bind(this)),
                tap(() => this.sortAlert(this.sortControl.value))
            )
            .subscribe(this.assignRandomColorsToChips.bind(this));
    }

    private filterAlerts(filters: string[]): void {
        if (isEmptyArray(filters)) return this._alertsParams$.next(this.alertsParams);
        const alertFilter = filterMultipleByTerms<CardMessageParams<Alert>>(this.alertsParams, filters, ['data.type', 'data.status', 'data.userId']);
        this._alertsParams$.next(alertFilter);
    }

    private assignRandomColorsToChips() {
        const chips = this.el.nativeElement.querySelectorAll('.p-multiselect-token');

        chips.forEach((chip: any) => {
            const mapColor = generateMapRandomColor();
            this.renderer.setStyle(chip, 'background-color', mapColor.bg);
            this.renderer.setStyle(chip, 'color', mapColor.color);
        });
    }
    private sortAlert(value: any): void {
        const alertSortValue = value as keyof typeof AlertSort;
        const props: SortProperties = {
            type: alertSortValue === AlertSort.DateAsc || alertSortValue === AlertSort.DateDesc ? SortDataType.Date : SortDataType.Text,
            isInverted: value === AlertSort.DateDesc,
        };
        const attr = AlertSortAttr[alertSortValue];
        const sortFn = sortBy(`data.${attr}`, props);
        const alerts = this._alertsParams$.getValue();
        this._alertsParams$.next(alerts.sort(sortFn));
        this.first = 0;
    }

    private getCardMessageParamsByAlert(alert: Alert): CardMessageParams<Alert> {
        const alertType = alert.type as keyof typeof AlertType;
        const params = AlertTypeImage[alertType];
        return {
            subTitle: alert.message,
            title: `${alert.title} [${alert.status}]`,
            data: alert,
            actions: [
                {
                    action: (data?: ButtonActionResponse<Alert>) => data && this.editAlert(data.selected),
                    icon: PrimeIcons.EYE,
                    label: 'Ver',
                    size: 'small',
                    bgColor: 'green',
                },
                {
                    action: (data?: ButtonActionResponse<Alert>) => data && this.editStatusAlert(data.selected),
                    icon: PrimeIcons.REFRESH,
                    label: 'Estatus / Comentario',
                    size: 'small',
                    bgColor: 'blue',
                },
            ],
            ...params,
        };
    }
}
