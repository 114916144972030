<ng-container *ngIf="controls && metaDataComplexList$ | async  as metaComplex">
  <div [style.justify-content]="orientation" [style.padding]="paddingContainer" class="card-container">
    <div [style.width.%]="width" class="card-wrapper">
      <!-- <mat-card class="mat-elevation-z15">

        <mat-card-header class="card-header">
          <mat-card-title>
            <ng-content class="card-title" select="[card-title]">
            </ng-content>

          </mat-card-title>
          <mat-card-subtitle>
            <ng-content select="[card-subtitle]"></ng-content>
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <ng-content select="[card-top-content]">

          </ng-content>
          <ngx-masonry #masonry [options]="masonryOptions" [ordered]="true">

            <ng-container *ngFor="let meta of metaData | metaDataHiddenPipe">
              <div ngxMasonryItem [style.width.%]="widthForm[meta.field]" class="masonry-item container-form">
                <div class="separate">
                  <ng-container *ngIf="meta.input == null">

                    <ng-container *ngIf=" metaComplex | extractMetaDataComplex : meta as metaDataEntityComplex">
                      <lib-meta-complex-input-form (menuComplexEvent)="menuComplexEvent($event,meta)"
                        (addComplexEvent)="addComplexEvent($event)" [metaData]="meta"
                        [metaDataComplex]="metaDataEntityComplex" [formMode]="formMode" [readOnly]="readOnly"
                        [complexConfigOptions]="complexConfigOptions" [reloadMasonry]="reloadMasonryEvt">
                      </lib-meta-complex-input-form>

                      <ng-container *ngIf="!metaDataEntityComplex.formArray.valid">
                        <small class="error-message">Campo requiere revisión</small>
                      </ng-container>

                    </ng-container>

                  </ng-container>
                </div>


                <ng-container *ngIf="meta.input">
                  <div class="separate">
                    <lib-meta-input-form class="" [metaData]="meta" [iconAction]="iconActionList[meta.field]"
                      [control]="controls[meta.field]" [formMode]="formMode" [readOnly]="readOnly"
                      [isAppendToBody]="isAppendToBody" (onLoad)="onLoadInput($event)"
                      (onRender)="onRenderInput($event)" [reloadMasonry]="reloadMasonryEvt"
                      (onCascade)="onCascade($event)">
                    </lib-meta-input-form>
                  </div>
                </ng-container>

              </div>
            </ng-container>

          </ngx-masonry>
          <ng-content select="[card-content]">

          </ng-content>
        </mat-card-content>
        <p-divider class="divider-actions" type="dashed">
        </p-divider>
         <mat-card-actions class="card-actions" [style.justify-content]="orientationActions">
          <ng-content select="[card-actions]"></ng-content>
        </mat-card-actions> 
      </mat-card> -->
    </div>
  </div>
</ng-container>