import { Component, Input, OnInit } from '@angular/core';
import { AvatarImageDefault } from '../../../constants/shared/shared.constants';

@Component({
    selector: 'lib-image-file',
    templateUrl: './image-file.component.html',
    styleUrls: ['./image-file.component.scss'],
})
export class ImageFileComponent implements OnInit {
    private _avatar!: string;
    private _width: number = 50;

    @Input() isLoadFile: boolean = true;
    @Input() set width(_width: number) {
        this._width = _width;
    }

    @Input() set avatar(_avatar: string) {
        this._avatar = _avatar || AvatarImageDefault;
    }

    get width() {
        return this._width;
    }

    get avatar(): string {
        return this._avatar;
    }

    constructor() {}

    ngOnInit(): void {}
}
