import { Type, ViewContainerRef } from '@angular/core';
import { MetaDataOptions } from '../../../model/components/meta-data.model';
import { DataObject } from '../../../model/template/template.model';
import { InputComplexComponent } from './input-complex/input-complex.component';
import { InputTypeParams } from './input-meta.model';

export const createComponent = (
    inputType: Type<any>,
    viewContainerRef: ViewContainerRef,
    params: InputTypeParams<any>,
    metaDataOptions?: MetaDataOptions<any>
) => {
    const componentRef = viewContainerRef.createComponent(inputType);
    Object.keys(params).forEach((key: string) => componentRef.setInput(key, (params as DataObject)[key]));
    if (inputType.name === InputComplexComponent.name) {
        componentRef.setInput('metaDataOptions', metaDataOptions);
    }
    return { component: componentRef, instance: componentRef.instance };
};
