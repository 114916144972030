<ng-container *ngIf="general$ | async">
    <ng-container *ngIf="isRenderer">
        <p-menubar [model]="params?.menus || []">
            <ng-template pTemplate="start">
                <img [src]="imageUrl" height="40" class="mr-2" />
            </ng-template>
            <ng-template pTemplate="end">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input class="search" *ngIf="!params?.hiddenSearch" [formControl]="searchControl"
                        (keydown.enter)="onKeydown()" type="text" pInputText
                        [placeholder]="params?.searchText || 'Buscar...'">
                </span>

            </ng-template>
        </p-menubar>
        <p-tabMenu #tab [model]="params?.tabs || []"></p-tabMenu>
    </ng-container>

</ng-container>

<router-outlet></router-outlet>



<!-- Old Code -->
<!-- <p-menubar [model]="menus">
    <ng-template pTemplate="start">
        <img [src]="iconMenu" alt="" height="30" class="mr-2">
    </ng-template>
    <ng-template pTemplate="end">
        <div class="p-inputgroup">
            <ng-content></ng-content>
            <input *ngIf="!menuBarOptions?.hiddenSearch" [ngClass]="searchTextClass" [formControl]="searchControl"
                (keydown.enter)="onKeydown()" type="text" pInputText placeholder="Buscar...">
            <button *ngIf="!menuBarOptions?.hiddenSearch" [disabled]="!searchControl.valid" (click)="onClickSearch()"
                type="button" pButton pRipple icon="pi pi-search" styleClass="p-button-warn"></button>
        </div>
    </ng-template>
</p-menubar> -->