import { DataType, InputType, MetaDataModel, ValidationType } from '../../model/components/form.model';

export const DUMMY_METADATA: MetaDataModel[] = [
    {
        editable: true,
        field: 'name',
        input: InputType.Text,
        label: 'Ingresa dummy name',
        placeholder: 'Dummy name',
        type: DataType.Text,
        validations: [
            {
                errorMessage: 'Debe elegir un dummy dato',
                param: 'true',
                type: ValidationType.Required,
            },
        ],
    },
    {
        editable: true,
        field: 'ops',
        input: InputType.Select,
        label: 'Select Dummy',
        placeholder: 'Seleccionar Dummy option',
        type: DataType.Text,
        validations: [
            {
                errorMessage: 'Seleccione alguno',
                param: 'true',
                type: ValidationType.Required,
            },
        ],
        options: [
            { text: 'Dummy 1', value: 'D1' },
            { text: 'Dummy 2', value: 'D2' },
        ],
    },
];
