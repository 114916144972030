<ng-container *ngIf="alertsParams$ | async as alertsParams">
	<div class="container">
		<p-dataView #dv [value]="alertsParams" [lazy]="true" [paginator]="true" [rows]="rows"
			[totalRecords]="totalRecords" [loading]="loading" (onLazyLoad)="loadingItems($event)"
			(onPage)="updateFirst($event)">
			<ng-template pTemplate="header">
				<div class="view">
					<p-dataViewLayoutOptions layout="grid"></p-dataViewLayoutOptions>
				</div>
				<div>
					<div class="p-inputgroup">
						<span class="p-inputgroup-addon">Filtrar por: </span>
						<p-multiSelect styleClass="sort" [formControl]="filterControl" [options]="filterOptions"
							[group]="true" defaultLabel="Selecciona los filtros" display="chip">
						</p-multiSelect>
					</div>
					<div class="p-inputgroup">
						<span class="p-inputgroup-addon">Ordenar por: </span>
						<p-dropdown [options]="sortOptions" [formControl]="sortControl"
							placeholder="Opciones de ordenamiento" styleClass="sort" optionLabel="text"
							optionValue="value"></p-dropdown>
					</div>

				</div>
			</ng-template>
			<ng-template let-alertParam pTemplate="listItem">
				<ng-container *ngTemplateOutlet="view; context:{alertParam: alertParam, width: 100}"></ng-container>
			</ng-template>
			<ng-template let-alertParam pTemplate="gridItem">
				<ng-container *ngTemplateOutlet="view; context:{alertParam: alertParam, width: 50}"></ng-container>
			</ng-template>
		</p-dataView>
	</div>
</ng-container>

<ng-template #view let-alertParam="alertParam" let-width="width">
	<lib-card-message [params]="alertParam" [style.width.%]="width" [style.padding.px]="5">
		<div content>
			<div class="history">
				<div><strong>Usuario: </strong> {{alertParam.data.userName ?? 'SN' }} [{{alertParam.data.userId}}]
				</div>
				<div><strong>Creación: </strong> {{alertParam.data.createdAt | date: dateFormat}}
				</div>
				<div><strong>Actualizado:</strong> {{ alertParam.data.updatedBy | uppercase }} [ {{
					alertParam.data.updatedAt |
					date: dateFormat}} ]
				</div>
			</div>
			<div class="comments">
				<p-accordion styleClass="accordion">
					<p-accordionTab *ngFor="let comment of alertParam.data.comments"
						[header]="(comment.createdBy | uppercase) + ' [' + (comment.createdAt | date: dateFormat) + ']'">
						<p>{{comment.commentary}}</p>
					</p-accordionTab>
				</p-accordion>
			</div>
		</div>
	</lib-card-message>
</ng-template>