import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { VALUES_TYPE_FN } from '../constants/shared/pipes.constants';
import { DataType, InputType, MetaDataModel } from '../model/components/form.model';
import { isNull } from '../utils/general-functions';

@Pipe({
    name: 'dataTypePipe',
})
export class DataTypePipe implements PipeTransform {
    private pipes: PipeTransform[];
    private types = [InputType.Endpoint, InputType.Lookup, InputType.LookupMulti];

    constructor(decimalPipe: DecimalPipe, currencyPipe: CurrencyPipe, datePipe: DatePipe, percentPipe: PercentPipe) {
        this.pipes = [decimalPipe, currencyPipe, datePipe, percentPipe];
    }
    transform(value: any, meta: MetaDataModel | Partial<MetaDataModel>): any {
        if (isNull(value)) return '--';
        if (!!meta.customDataPipeFn) return meta.customDataPipeFn(value, meta);
        if (meta.input && this.types.includes(meta.input)) return value.name;
        if (!!meta.type) return this.getValueFromType(value, meta.type);
        return value;
    }

    private getValueFromType(value: any, type: DataType): any {
        const transformFn = VALUES_TYPE_FN[type];
        if (transformFn != null) return transformFn(value, this.pipes);
        return value;
    }
}
