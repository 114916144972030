import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DropDownElements, MultiDropDownElements, OPTION_LABEL, OPTION_VALUE } from '../constants/components/input-select.constants';
import { VALUES_TYPE_FN } from '../constants/shared/pipes.constants';
import { DataType, InputType } from '../model/components/form.model';
import { InputSelectOptions } from '../model/components/input-meta/input-select.model';
import { MetaDataExtended } from '../model/components/meta-data.model';
import { isNull } from '../utils/general-functions';

@Pipe({
    name: 'metadataTypePipe',
})
export class MetadataTypePipe implements PipeTransform {
    private pipes: PipeTransform[];

    constructor(decimalPipe: DecimalPipe, currencyPipe: CurrencyPipe, datePipe: DatePipe, percentPipe: PercentPipe) {
        this.pipes = [decimalPipe, currencyPipe, datePipe, percentPipe];
    }
    transform(value: any, meta: MetaDataExtended<any>): any {
        if (meta.input === InputType.Complex) return '';
        if (typeof value === 'string' && value?.includes('#')) value = value.split('#')[1]; //ToDo resolver conflicto de meta actualizado
        if (isNull(value)) return '--';
        if (meta.customDataPipeFn) return meta.customDataPipeFn(value, meta);
        if (DropDownElements.includes(meta.input || InputType.Unknown)) return this.getValueFromDropDown(value, meta);
        if (MultiDropDownElements.includes(meta.input || InputType.Unknown)) return this.getValueFromMultiDropDown(value, meta);
        if (meta.type && meta.type !== DataType.Text) return this.getValueFromType(value, meta.type);
        if (typeof value === 'object') return value[meta.field];
        return value;
    }

    private getValueFromDropDown(value: any, meta: MetaDataExtended<any>): any {
        if (value.name) return value.name;
        // if (!meta.inputMetaOptions) return '--';
        const opts = meta.inputMetaOptions as InputSelectOptions<any>;
        const { multiLabels, optionValue, separator } = opts;
        value = value[meta.field] ?? value;
        const option = meta.options?.find((opt: any) => opt[optionValue || OPTION_VALUE] === value);
        if (option) return opts.customOptionText?.(option) ?? multiLabels?.map((label: string) => option[label]).join(separator || ' ');
        return '--';
    }

    private getValueFromType(value: any, type: DataType): any {
        const transformFn = VALUES_TYPE_FN[type];
        if (transformFn != null) return transformFn(value, this.pipes);
        return value;
    }

    private getValueFromMultiDropDown(value: any, meta: MetaDataExtended<any>): any {
        const opts = meta.inputMetaOptions as InputSelectOptions<any>;
        const keyValue = opts.optionLabel || OPTION_LABEL;
        if (Array.isArray(value)) return value.map((option: any) => option[keyValue] ?? value).join(',');
        return value[keyValue] ?? value;
    }
}
