import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { OAuthLibService } from '../../../services/authorization/oauth-lib.service';
import { ConfigService } from '../../../services/template/config.service';

@Component({
    selector: 'lib-logout',
    styleUrls: ['logout.component.scss'],
    templateUrl: 'logout.component.html',
})
export class LogoutComponent implements OnInit {
    public messageLogout: string = 'Iniciar sesión en APP';

    constructor(private oauthService: OAuthLibService, _config: ConfigService, private oauthServiceOIDC: OAuthService) {
        const { messageLogout } = _config.navigationData;
        if (messageLogout) {
            this.messageLogout = messageLogout;
        }
    }

    ngOnInit() {
        this.oauthServiceOIDC.logOut();
    }
}
