import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationService } from 'primeng/api';
import { switchMap, tap } from 'rxjs';
import { DISPLAY_VALUES } from '../../../constants/components/input-select.constants';
import { MetaDataOptions } from '../../../model/components/meta-data.model';
import {
    ListPanelConfiguration,
    ListPanelMetaData,
    ListPanelMetaInfo,
    PanelConfiguration,
    PanelForm,
    PanelParams,
} from '../../../model/components/panel-form.model';
import { InputMode } from '../../../model/components/shared-models.model';
import { PanelService } from '../../../services/components/panel.service';
import { LoadingService } from '../../../services/shared/loading.service';
import { isEmptyArray } from '../../../utils/general-functions';

@UntilDestroy()
@Component({
    selector: 'lib-list-panel-form',
    templateUrl: './list-panel-form.component.html',
    styleUrls: ['./list-panel-form.component.scss'],
})
export class ListPanelFormComponent implements OnInit {
    public listBoxControl: FormControl = new FormControl();
    public panelConfig!: PanelConfiguration;
    public dataList: Array<any> = [];
    public listPnlConfig!: ListPanelConfiguration<any>;
    public panelTitle: string = '';
    public showPanel: string = 'none';
    public isMultipleChecked: boolean = false;
    public panelMode: InputMode = InputMode.Read;

    private pnlForm: PanelParams | null = null;
    private action!: { title: string; meta: ListPanelMetaInfo | ListPanelMetaData; hideButton?: boolean };

    get optionLabel(): string {
        return this.listPnlConfig.optionLabel ?? DISPLAY_VALUES;
    }

    get isSelected(): boolean {
        const { value } = this.listBoxControl;
        return Array.isArray(value) ? !isEmptyArray(value) : !!this.listBoxControl.value;
    }

    get isMultiple(): boolean | undefined {
        return this.listPnlConfig.isMultiple;
    }

    get selectedObjects(): number {
        const { value } = this.listBoxControl;
        if (Array.isArray(value)) return value.length;
        return !value ? 0 : 1;
    }

    get panelControl(): FormGroup | undefined {
        return this.pnlForm?.control;
    }

    @Input() metaDataOptions?: MetaDataOptions<any>;
    @Input({ required: true }) set listPanelConfig(listPnlConfig: ListPanelConfiguration<any>) {
        this.listPnlConfig = listPnlConfig;
        const { actionDefault } = this.listPnlConfig;
        this.action = this.listPnlConfig[actionDefault] as { title: string; meta: ListPanelMetaInfo | ListPanelMetaData; hideButton?: boolean };
        const panelFactory = 'metaData' in this.action.meta ? this.getPanelConfig.bind(this) : this.getPanelConfigAPI.bind(this);
        this.panelConfig = panelFactory();
        this.panelTitle = this.listPnlConfig.titlePanel(null);
        this.panelMode = this.panelConfig.inputMode;
    }

    @Output() panelModeChange: EventEmitter<InputMode> = new EventEmitter<InputMode>();

    constructor(private panelService: PanelService, private confirmationService: ConfirmationService, private loadingService: LoadingService) {}

    public ngOnInit(): void {
        this.panelService
            .getPanels$()
            .pipe(
                untilDestroyed(this),
                switchMap((pnlForm: PanelForm) => {
                    this.pnlForm = pnlForm[this.listPnlConfig.id];
                    return this.listPnlConfig.dataList$;
                }),
                tap((dataList: Array<any>) => {
                    this.dataList = dataList;
                })
            )
            .subscribe();
    }
    public onPanelModeChange(mode: InputMode): void {
        this.panelModeChange.emit(mode);
        this.refreshPanel(mode, false);
    }

    public onSelectedData(data: any): void {
        const { value } = data;
        if (Array.isArray(value)) return;
        this.panelTitle = this.listPnlConfig.titlePanel(value);
        this.showPanel = 'block';
        this.pnlForm?.patchValue(value, this.listPnlConfig.paths);
        this.refreshPanel(InputMode.Read, false);
        this.panelConfig = { ...this.panelConfig, avoidEditMode: this.listPnlConfig.avoidEditMode };
    }

    public clearSelection(): void {
        this.showPanel = 'none';
        this.listBoxControl.reset();
    }

    public newEvent(): void {
        this.panelTitle = this.listPnlConfig.newAction?.title ?? '';
        this.clearSelection();
        this.showPanel = 'block';
        this.refreshPanel(InputMode.Write, true);
        this.panelControl?.reset();
        this.panelConfig = { ...this.panelConfig, avoidEditMode: false };
    }

    public deleteEvent(): void {
        const _delete = this.listPnlConfig.delete;
        if (_delete) {
            const { confirmation, onDelete } = _delete;
            if (confirmation) {
                const accept = () => {
                    confirmation.accept?.();
                    onDelete?.(this.listBoxControl.value);
                };
                const message = `${confirmation.message} (${this.selectedObjects} seleccionados)`;
                this.confirmationService.confirm({ ...confirmation, accept, message });
                return;
            }
            return onDelete?.(this.listBoxControl.value);
        }
    }

    private refreshPanel(inputMode: InputMode, isNew: boolean): void {
        const metaInfo =
            isNew && 'mode' in (this.listPnlConfig.newAction?.meta || {})
                ? (this.listPnlConfig.newAction?.meta as ListPanelMetaInfo)
                : (this.listPnlConfig.editAction?.meta as ListPanelMetaInfo);
        this.panelMode = inputMode;
        this.panelConfig = { ...this.panelConfig, inputMode, metaInfo };
        this.metaDataOptions = { ...this.metaDataOptions };
    }

    private getPartialPanelConfig(): Pick<PanelConfiguration, 'id' | 'inputMode' | 'styleOptions' | 'avoidEditMode' | 'menu' | 'hiddenConditions'> {
        const { id, inputMode, styleOptions, avoidEditMode, menu, hiddenConditions } = this.listPnlConfig;
        return {
            id,
            inputMode,
            styleOptions,
            avoidEditMode,
            menu,
            hiddenConditions,
        };
    }

    private getPanelConfigAPI(): PanelConfiguration {
        const partialPanelConfig = this.getPartialPanelConfig();
        const { entity, mode } = this.action.meta as ListPanelMetaInfo;
        return { ...partialPanelConfig, metaInfo: { entity, mode } };
    }
    //ToDo por resolver comportamiento
    private getPanelConfig(): PanelConfiguration {
        const partialPanelConfig = this.getPartialPanelConfig();
        const metaData = (this.action.meta as ListPanelMetaData).metaData;
        return { ...partialPanelConfig, metaData };
    }
}
