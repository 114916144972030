import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    Type,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, MonoTypeOperatorFunction, Observable, tap } from 'rxjs';
import { InputTypeList } from '../../../constants/components/input.constants';
import { DefaultMasonryOptions, FadeInOut, createWidthInputListForCard, isPanelConfigAPI } from '../../../constants/components/panel.constants';
import { DropDownCascade } from '../../../model/components/input-meta/input-select.model';
import { ExtendedMenuItem } from '../../../model/components/menu-bar.model';
import { MetaDataExtended, MetaDataOptions } from '../../../model/components/meta-data.model';
import {
    ConditionHiddenParams,
    GlobalMetaData,
    PanelConditionHidden,
    PanelConfig,
    PanelConfigAPI,
    PanelConfiguration,
    PanelMetaDataExtendedList,
} from '../../../model/components/panel-form.model';
import { InputMode } from '../../../model/components/shared-models.model';
import { MetaFormControlService } from '../../../services/components/meta-form-control.service';
import { PanelService } from '../../../services/components/panel.service';
import { MetaApiService } from '../../../services/shared/meta-api.service';
import { deepCloneObject } from '../../../utils/clone-object';
import { digger } from '../../../utils/general-functions';
import { getCommonParamsCompare } from '../../../utils/util-form';
import { AbstractInputMeta } from '../input-meta/abstract-input-meta.class';
import { InputMetaComponent } from '../input-meta/input-meta.component';
import { InputTypeParams, RecordInputTypeParams } from '../input-meta/input-meta.model';
@UntilDestroy()
@Component({
    selector: 'lib-panel-form',
    templateUrl: './panel-form.component.html',
    styleUrls: ['./panel-form.component.scss'],
    animations: [FadeInOut(500, 500)],
})
export class PanelFormComponent implements OnInit, OnChanges, OnDestroy {
    private panelMetaDataExtendedList: PanelMetaDataExtendedList = {};
    private initFormGroupTap: MonoTypeOperatorFunction<MetaDataExtended<any>[]> = tap(this.initFormGroup.bind(this));
    private initWidthInputTap: MonoTypeOperatorFunction<MetaDataExtended<any>[]> = tap(this.createWidthList.bind(this));
    private initCreatedInputTap: MonoTypeOperatorFunction<MetaDataExtended<any>[]> = tap(this.createInputList.bind(this));
    private initInputTypeParamsTap: MonoTypeOperatorFunction<MetaDataExtended<any>[]> = tap(this.initInputTypeParams.bind(this));
    private _metaData$: BehaviorSubject<MetaDataExtended<any>[]> = new BehaviorSubject<MetaDataExtended<any>[]>([]);
    private _masonryOptions: NgxMasonryOptions = DefaultMasonryOptions;
    private _panelConfiguration!: PanelConfiguration;
    private formGroup: FormGroup | null = null;
    private createdInputList: Record<string, boolean> = {};
    private paramsInputList: RecordInputTypeParams<any> = {};

    public blockedPanel: boolean = false;
    public widthInputList: { [key: string]: number } = {};
    public reloadMasonryEvt: Function = this.reloadMasonry.bind(this);
    public metaData$: Observable<MetaDataExtended<any>[]> = this._metaData$
        .asObservable()
        .pipe(
            this.initFormGroupTap.bind(this),
            this.initWidthInputTap.bind(this),
            this.initCreatedInputTap.bind(this),
            this.initInputTypeParamsTap.bind(this)
        );
    public toggleablePanel?: boolean;
    public orientationPanelAction?: string;

    @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent | null = null;
    @ViewChildren(InputMetaComponent) cmpts!: QueryList<InputMetaComponent>;

    @Input({ required: true }) set panelConfig(_panelConfig: PanelConfiguration) {
        this._panelConfiguration = _panelConfig;
        if (this._panelConfiguration.menu) {
            this._panelConfiguration.menu = this._panelConfiguration.menu.map((menu: MenuItem) => ({
                ...menu,
                command: () => menu?.command?.({ item: this.control.value }),
            }));
        }
        const { masonryOptions, toggleablePanel, orientationPanelAction } = _panelConfig.styleOptions;
        if (masonryOptions) {
            const { columnWidth, itemSelector } = this._masonryOptions;
            this._masonryOptions = { ...masonryOptions, columnWidth, itemSelector };
        }
        this.toggleablePanel = toggleablePanel;
        this.orientationPanelAction = orientationPanelAction;
    }
    @Input() metaDataOptions?: MetaDataOptions<any>;

    @Input() panelMode?: InputMode;

    @Output() panelModeChange: EventEmitter<InputMode> = new EventEmitter<InputMode>();

    get _panelConfig(): PanelConfig {
        return this._panelConfiguration as PanelConfig;
    }
    get _panelConfigAPI(): PanelConfigAPI {
        return this._panelConfiguration as PanelConfigAPI;
    }

    get id(): string {
        return this._panelConfiguration.id;
    }

    get inputMode(): InputMode {
        return this._panelConfiguration.inputMode;
    }

    get isEditMode(): boolean {
        return this.inputMode === InputMode.Write;
    }

    get isAvoidEditMode(): boolean | undefined {
        return this._panelConfiguration.avoidEditMode;
    }

    get hiddenWriteMode(): boolean | undefined {
        return this._panelConfiguration.styleOptions.hiddenWriteMode;
    }
    get hideFooter(): boolean | undefined {
        return this._panelConfiguration.styleOptions.hideFooter;
    }

    get masonryOptions(): NgxMasonryOptions {
        return this._masonryOptions;
    }

    get controls(): { [key: string]: FormControl } {
        return this.formGroup?.controls as { [key: string]: FormControl };
    }

    get params(): RecordInputTypeParams<any> {
        return this.paramsInputList;
    }

    get menuOfPanel(): ExtendedMenuItem[] | undefined {
        return this._panelConfiguration.menu;
    }

    get _metaData(): MetaDataExtended<any>[] {
        return this._metaData$.getValue();
    }

    get control(): FormGroup {
        return this.formGroup ?? new FormGroup({});
    }

    get inputTypeList(): Record<string, Type<any>> {
        return InputTypeList;
    }

    get hiddenConditions(): Record<string, PanelConditionHidden> | undefined {
        return this._panelConfiguration.hiddenConditions;
    }

    constructor(private panelService: PanelService, private metaApiService: MetaApiService, private metaFormControlService: MetaFormControlService) {}

    public ngOnInit(): void {
        const initPanel = isPanelConfigAPI(this._panelConfiguration) ? this.initPanelConfigAPI.bind(this) : this.initPanelConfig.bind(this);
        initPanel();
        this.blockPanel(true);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { metaDataOptions: metaDataOptionsChanges, panelConfig: panelConfigChanges, panelMode: panelModeChanges } = changes;
        if (metaDataOptionsChanges && !panelConfigChanges) this.setMetaDataValue(this._metaData);
        if (panelModeChanges && this.panelMode) {
            const switchMode = this.panelMode === InputMode.Read ? this.switchReadMode.bind(this) : this.switchWriteMode.bind(this);
            switchMode();
        } else {
            this.reloadMasonryEvt();
        }
        if (
            metaDataOptionsChanges &&
            panelConfigChanges &&
            this._panelConfigAPI?.metaInfo?.mode !== panelConfigChanges.previousValue?.metaInfo?.mode
        ) {
            this.initPanelConfigAPI();
            this.blockPanel(true);
        }
    }

    public ngOnDestroy(): void {
        this.cmpts.forEach((cmp: InputMetaComponent) => cmp.destroyInputObservables());
    }

    public getPanelId(): string {
        return this.id;
    }

    public switchWriteMode(): void {
        if (!this._panelConfiguration.avoidEditMode) {
            this._panelConfiguration.inputMode = InputMode.Write;
            this.initInputTypeParams();
        }
        this.initSwitchMode();
    }

    public switchReadMode(): void {
        this._panelConfiguration.inputMode = InputMode.Read;
        this.initInputTypeParams();
        this.initSwitchMode();
    }

    public onCreateInput(component: AbstractInputMeta): void {
        const cascade = (component.metaData.inputMetaOptions as any)?.['cascade'] ?? [];
        const childFields = cascade.flatMap((_cascade: DropDownCascade) => _cascade.children?.flatMap(a => a.field));
        const isCreatedButHide = childFields.includes(component.metaData.field);
        const isCreated = this.createdInputList[component.metaData.field || ''];
        if (!isCreated || isCreatedButHide) this.createdInputList[component.metaData.field || ''] = true;
        if (this.allInputsAreCreated()) {
            this.blockPanel(false);
            this.reloadMasonry();
            this.panelService.registerPanel(this);
        }
    }

    private initSwitchMode(): void {
        this.panelMode = this._panelConfiguration.inputMode;
        this.reloadMasonryEvt();
        this.panelModeChange.emit(this.panelMode);
    }

    private initPanelConfigAPI(): void {
        const {
            metaInfo: { entity, mode },
        } = this._panelConfigAPI;

        this.metaApiService.getMetaData$(entity, mode).pipe(untilDestroyed(this)).subscribe(this.setMetaDataValue.bind(this));
    }

    private initPanelConfig(): void {
        this.setMetaDataValue(this._panelConfig.metaData);
    }

    private setMetaDataValue(metaData: GlobalMetaData[]): void {
        const metaDataExtended = this.extendedMetaData(metaData);
        this._metaData$.next(metaDataExtended);
    }

    private setFieldPath(metaData: MetaDataExtended<any>): void {
        if (!metaData.customDataPipeFn && metaData.path) {
            metaData.customDataPipeFn = (value: any, meta: MetaDataExtended<any>) => {
                if (meta.path) return digger(this.control.value, meta.path);
                return '--';
            };
        }
    }

    private extendedMetaData(_metaData: GlobalMetaData[]): MetaDataExtended<any>[] {
        const clonedMetaDataOptions = deepCloneObject<MetaDataOptions<any>>(this.metaDataOptions ?? {});
        return _metaData.map((metaData: GlobalMetaData) => {
            const _metaParams = clonedMetaDataOptions?.[metaData.field];
            const metaDataExtended = (_metaParams ? { ...metaData, ..._metaParams } : metaData) as MetaDataExtended<any>;
            this.setFieldPath(metaDataExtended);
            this.setErrorMessageStyle(metaDataExtended);
            this.panelMetaDataExtendedList = { ...this.panelMetaDataExtendedList, [metaData.field]: { metaDataExtended } };
            delete clonedMetaDataOptions?.[metaData.field];
            return metaDataExtended;
        });
    }

    private setErrorMessageStyle(metaDataExtended: MetaDataExtended<any>): void {
        const errorMessageInTop = (metaDataExtended.metaDataStyleOptions as Object)?.hasOwnProperty('errorMessageInTop')
            ? metaDataExtended.metaDataStyleOptions?.errorMessageInTop
            : this._panelConfiguration.styleOptions.errorMessageInTop;
        const { metaDataStyleOptions } = metaDataExtended;
        metaDataExtended.metaDataStyleOptions = { ...metaDataStyleOptions, errorMessageInTop };
    }

    private initFormGroup(): void {
        this.formGroup = this.metaFormControlService.initControl(this._metaData);
        this.formGroup.valueChanges.pipe(untilDestroyed(this), tap(this.hiddenFields.bind(this))).subscribe();
        // this.metaFormControlService.initConditionsHidden(this.reloadMasonry.bind(this), this._metaData, this.formGroup);
    }

    private hiddenFields(formValueObj: any): void {
        const valuesToCompare = getCommonParamsCompare(formValueObj, this.hiddenConditions);
        valuesToCompare.forEach(({ param, valueObject }) => {
            const conditions = this.hiddenConditions?.[param].conditions.filter((condition: ConditionHiddenParams) => {
                const { value, path, compareType } = condition;
                const valueToCompare = path ? digger(formValueObj, path) : valueObject;
                if (compareType === 'equals') return valueToCompare === value;
                if (compareType === 'distinct') return valueToCompare !== value;
                return JSON.stringify(valueToCompare).toLowerCase().includes(JSON.stringify(value));
            });
            conditions?.forEach(({ fieldsToHidden, fieldsToShow }) => this.showHiddenField(fieldsToHidden, fieldsToShow));
        });
        this.control.updateValueAndValidity({ emitEvent: false });
    }

    private showHiddenField(fieldsToHidden: string[], fieldsToShow: string[]): void {
        this._metaData.forEach((meta: MetaDataExtended<any>) => {
            if (fieldsToHidden.includes(meta.field)) meta.hidden = true;
            if (fieldsToShow.includes(meta.field)) meta.hidden = false;
            this.reloadMasonryEvt();
        });
    }

    private createWidthList(): void {
        const widthCard = 100 / this._panelConfiguration.styleOptions.columns;
        this.widthInputList = createWidthInputListForCard(this._metaData, widthCard);
    }

    // ToDo: Cambiar la condición a false, solo es para los complex
    private createInputList(): void {
        this.createdInputList = this._metaData
            .filter((meta: MetaDataExtended<any>) => !meta.hidden)
            .reduce((inputList: { [field: string]: boolean }, meta: MetaDataExtended<any>) => {
                const conditions = Object.values(this.hiddenConditions ?? {}).flatMap(({ conditions }) => conditions);
                const isHide = conditions.some((_condition: ConditionHiddenParams) => _condition.fieldsToHidden.includes(meta.field));
                return { ...inputList, [meta.field]: isHide || !meta.input };
            }, {});
    }

    private initInputTypeParams(): void {
        this.paramsInputList = this._metaData.reduce(
            (inputList: { [field: string]: InputTypeParams<any> }, meta: MetaDataExtended<any>) => ({
                ...inputList,
                [meta.field]: {
                    metaData: meta,
                    control: this.controls[meta.field],
                    mode: this._panelConfiguration.inputMode,
                    reloadMasonry: this.reloadMasonryEvt,
                },
            }),
            {}
        );
    }

    private reloadMasonry(): void {
        this.masonry?.reloadItems();
        this.masonry?.layout();
    }

    private allInputsAreCreated(): boolean {
        return Object.entries(this.createdInputList)
            .map(([, isCreated]) => isCreated)
            .every(isCreated => isCreated);
    }

    private blockPanel(blocked: boolean): void {
        setTimeout(() => (this.blockedPanel = blocked), 50);
    }
}
