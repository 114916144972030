<ng-container *ngIf="values" class="dynamic-table">

    <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
            <ng-content select="[left-toolbar-actions]">

            </ng-content>
            <ng-container *ngIf="showActionButtons">
                <button pButton pRipple icon="pi pi-plus" class="p-button-success mr-2 action-button"
                    (click)="newItem()"></button>
                <button pButton pRipple icon="pi pi-trash" class="p-button-danger action-button" (click)="deleteItem()"
                    [disabled]="!selectionDataTable?.length"></button>
            </ng-container>

        </ng-template>

        <ng-template pTemplate="right">
            <ng-content select="[right-toolbar-actions]">

            </ng-content>

            <div *ngIf="!dynamicTable.hiddenSearchBar" class="search-container">
                <span class="p-input-icon-right ml-auto element">
                    <input pInputText type="text" placeholder="Buscar" [formControl]="searchInstableControl" />
                    <i class="pi pi-search"></i>
                </span>
            </div>
        </ng-template>
    </p-toolbar>

    <p-table #table [value]="values" [columns]="columns" responsiveLayout="scroll" [(selection)]="selectionDataTable"
        [metaKeySelection]="false" [selectionMode]="selectionDynamicTableMode" [paginator]="!dynamicTable.isLazyLoad"
        [showCurrentPageReport]="true" [rows]="rows" [loading]="loading" [resizableColumns]="true" [autoLayout]="true"
        [totalRecords]="totalRecords" [dataKey]="idDataKey" editMode="row"
        (onHeaderCheckboxToggle)="onSelectAll($event)"
        currentPageReportTemplate="{first} - {last} de {totalRecords} resultados" [rowsPerPageOptions]="[10,25,50]"
        [rows]="10" styleClass="p-datatable-sm p-datatable-gridlines" (onRowSelect)="onSelectedRowControl($event,true)"
        (onRowUnselect)="onSelectedRowControl($event,false)">

        <ng-template *ngIf="dynamicTable.showHeader" pTemplate="caption">
            <ng-content select="[caption]">

            </ng-content>
            <ng-container *ngIf="dynamicTable.type === 'simple' && !dynamicTable.hiddenSearchBar">
                <div class="search-container">
                    <span class="p-input-icon-right ml-auto element">
                        <input pInputText type="text" placeholder="Buscar" [formControl]="searchInstableControl" />
                        <i class="pi pi-search"></i>
                    </span>
                </div>
            </ng-container>

        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngIf="showCheckColumn" class="th-check">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let column of columns">
                    {{column.header}}
                </th>
                <th *ngIf="showToolActionColumn"></th>
                <th *ngIf="showEditActionColumn"> </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded" let-editing="editing"
            let-rowIndex="rowIndex">
            <tr #htmlTableRowElement [pSelectableRow]="rowData" [pEditableRow]="rowData"
                [pSelectableRowIndex]="rowIndex" (dblclick)="onRowDblClick(rowData)">

                <td *ngIf="dynamicTable.editProps && !isReadOnly" class="th-data">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>

                <!-- Data to show -->
                <td pResizableColumn *ngFor="let column of columns" class="th-data">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-container *ngIf="getMetaData(column.field) as meta">
                                <lib-meta-input-form class="adjust-meta-input" [metaData]="meta"
                                    [control]="getControl(column.field,rowIndex)" [formMode]="InputFormMode"
                                    [isAppendToBody]="true"
                                    (keydown.enter)="onRowEditSaveEnter(rowData,rowIndex,htmlTableRowElement)"
                                    (keydown.escape)="onRowEditCancelEnter(rowData,rowIndex)" [hiddenLabel]="true">
                                </lib-meta-input-form>
                            </ng-container>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{rowData[column.field] | dataTypePipe: column }}
                        </ng-template>
                    </p-cellEditor>
                </td>


                <td class="th-data" *ngIf="showEditActionColumn" class="td-tool-button"
                    [ngClass]="{'td-tool-editing':editing}">
                    <button *ngIf="!editing" pInitEditableRow type="button" pButton pRipple icon="pi pi-pencil"
                        (click)="onRowEditInit(rowData,rowIndex)" tooltipStyleClass="tooltip"
                        class="p-button-secondary p-button-rounded p-button-outlined"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                        (click)="onRowEditSave(rowData,rowIndex)"
                        class="p-button-success p-button-rounded p-button-outlined"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                        (click)="onRowEditCancel(rowData,rowIndex)"
                        class="p-button-danger p-button-rounded p-button-outlined"></button>
                </td>

                <td class="th-data" *ngIf="dynamicTable.dynamicToolAction" class="td-tool-button">
                    <ng-container *ngFor="let tool of dynamicTable.dynamicToolAction">
                        <button *ngIf="!tool.hidden" type="button" pButton pRipple icon="{{tool.icon}}"
                            pTooltip="{{tool.tooltip}}" [fitContent]="true" tooltipPosition="left"
                            tooltipStyleClass="tooltip" (click)="onActionEvent(rowData,tool)"
                            tooltipStyleClass="tooltip"></button>
                    </ng-container>
                </td>
            </tr>
        </ng-template>

        <ng-template *ngIf="dynamicTable.showFooter" pTemplate="footer" let-columns>
            <tr>
                <td *ngIf="showCheckColumn"></td>
                <td *ngFor="let column of columns">
                    {{column.header}}
                </td>
                <td *ngIf="showToolActionColumn"></td>
                <td *ngIf="showEditActionColumn"> </td>

            </tr>
        </ng-template>
        <ng-template *ngIf="dynamicTable.showSummary" pTemplate="summary">
            <ng-content select="[summary]"></ng-content>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>

                <td *ngIf="showCheckColumn"></td>
                <td [attr.colspan]="columns.length">No hay datos</td>
                <td *ngIf="showToolActionColumn"></td>
                <td *ngIf="showEditActionColumn"> </td>
            </tr>
        </ng-template>
    </p-table>
    <ng-container *ngIf="dynamicTable.isLazyLoad">
        <div class="paginator-lib mb-3">
            <p-button type="button" icon="pi pi-chevron-left" (click)="prev()" styleClass="p-button-text">
            </p-button>
            <p-button type="button" icon="pi pi-refresh" (click)="reset()" styleClass="p-button-text"></p-button>
            <p-button type="button" icon="pi pi-chevron-right" (click)="next()" styleClass="p-button-text">
            </p-button>
        </div>
    </ng-container>
</ng-container>