<p-dialog [header]="header" [(visible)]="open" (visibleChange)="onOpenChange($event)" [modal]="true"
	[style]="{ width: '50vw' }">
	<lib-panel-form [panelConfig]="panelConfig" [metaDataOptions]="options" [panelMode]="pnlAlertMode"
		(panelModeChange)="onPanelModeChange($event)">
		<div card-title>
			<span>Alerta</span>
		</div>
		<div card-content-after>
			<div class="accordion-content">
				<p-accordion class="accordion-container" styleClass="accordion">
					<p-accordionTab *ngFor="let comment of comments"
						[header]="(comment.createdBy | uppercase) + ' [' + (comment.createdAt | date: dateFormat) + ']'">
						<p>{{comment.commentary}}</p>
					</p-accordionTab>
				</p-accordion>
				<p-button *ngIf="isWriteMode && !isNew" icon="pi pi-plus" size="small"
					styleClass="p-button-warning p-button-rounded p-button-outlined"
					(onClick)="op.toggle($event)"></p-button>
			</div>
		</div>
	</lib-panel-form>
</p-dialog>

<p-overlayPanel #op>
	<div class="container-comment">
		<span>Comentario</span>
		<textarea #txtComment rows="5" cols="30" pInputTextarea></textarea>
		<p-button icon="pi pi-save" styleClass="p-button-success" [style.margin-left]="'auto'"
			label="Guardar comentario" size="small" (onClick)="onSaveComment($event,op,txtComment)"></p-button>
	</div>
</p-overlayPanel>