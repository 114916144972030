import { Component } from '@angular/core';
import { NavigationConfig } from '../../../model/template/template.model';
import { ConfigService } from '../../../services/template/config.service';
import { ToolBarActionModel } from './../../../model/template/template.model';

@Component({
    selector: 'lib-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
    public navigationData: NavigationConfig;

    constructor(config: ConfigService) {
        this.navigationData = config.navigationData;
    }

    public buttonEvent(toolButton: ToolBarActionModel): void {
        toolButton.action?.next(toolButton);
    }
}
