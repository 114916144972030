import { Component, OnInit } from '@angular/core';
import { OAuthLibService } from '../../services/authorization/oauth-lib.service';

@Component({
    selector: 'lib-login',
    template: '',
})
export class LoginComponent implements OnInit {
    constructor(private oauthService: OAuthLibService) {}

    ngOnInit() {
        this.oauthService.login();
    }
}
