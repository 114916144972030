import { AbstractControl } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { ExtendedMenuItem } from '../model/components/menu-bar.model';

export function isNull(value: any): boolean {
    if (typeof value === 'number' || typeof value === 'boolean' || (typeof value === 'string' && value.trim() !== '')) return false;
    return !isNotNull(value);
}

export function isNotNull(...values: any[]): boolean {
    return values.every(Boolean);
}

export function isValidControl(control: AbstractControl): boolean {
    return control.valid && control.touched;
}

export function isDisabledControl(control: AbstractControl): boolean {
    return control.pristine || control.invalid;
}

export function isEmptyArray(array: Array<any>): boolean {
    return array.length === 0;
}

export function isEmptyObject(object: Object): boolean {
    return isEmptyArray(Object.keys(object));
}

export function getRouterLinkArray(routerLink: any): string[] {
    if (routerLink instanceof String) return routerLink.split('/').filter(Boolean);
    if (Array.isArray(routerLink)) return routerLink;
    return [];
}

type ExtendedOrMenuItem = ExtendedMenuItem | MenuItem;
/**
 *
 * @param items
 * @description returns all menus/tabs in deep
 */
export function getAllDeepItems(items: ExtendedOrMenuItem[]): ExtendedOrMenuItem[] {
    return items.reduce((allItems: ExtendedOrMenuItem[], item: ExtendedOrMenuItem) => {
        const { items } = item;
        allItems.push(item);
        if (items) {
            const subMenus = getAllDeepItems(items);
            allItems = [...allItems, ...subMenus];
        }
        return allItems;
    }, []);
}

/**
 * @param items
 * @returns ExtendedMenuItem[]
 * @description returns all menus/tabs in deep with the override for all
 */
export function overrideItems(items: ExtendedOrMenuItem[], overrideItem: ExtendedMenuItem): ExtendedOrMenuItem[] {
    return items.map((item: ExtendedOrMenuItem) => {
        const { items } = item;
        item = { ...item, ...overrideItem };
        if (items) return { ...item, items: overrideItems(items, overrideItem) };
        return item;
    }, []);
}

export function deepCompareObjects<T>(obj1: T & undefined, obj2: T & undefined): boolean {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

    const keys1 = Object.keys(obj1 as any);
    const keys2 = Object.keys(obj2 as any);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepCompareObjects<T>(obj1?.[key], obj2?.[key])) return false;
    }

    return true;
}

/**
 * Extrae los valores de un objeto de acuerdo al path
 * @param obj
 * @param path Puede ser '.' o '/' representan objeto y arreglo respectivamente
 * @param returnObjects Indica si devuelve solo los valores o el objeto encontrado
 * @returns
 */
export function digger<T>(obj: T, path: string, returnObjects?: boolean): any {
    const pathParts = path.split('.');

    for (let part of pathParts) {
        if (part.includes('/')) {
            const [arrayKey, restPath] = part.split('/');
            if ((obj as any)[arrayKey] && Array.isArray((obj as any)[arrayKey])) {
                // Devuelve los objetos completos que contienen coincidencias o solo su valor
                return (obj as any)[arrayKey].filter((item: any) => (returnObjects ? digger(item, restPath) !== null : digger(item, restPath)));
            }
            return null;
        }
        if (obj && obj.hasOwnProperty(part)) {
            obj = (obj as any)[part];
            continue;
        }
        return null;
    }
    return obj;
}
export function multipleDigger<T>(obj: T, paths: string[], returnObjects?: boolean): any {
    return paths.flatMap((path: string) => digger(obj, path, returnObjects));
}

export function isColorDark(hexColor: string) {
    // Convertir el color hex a RGB
    const r = parseInt(hexColor.substring(1, 3), 16) / 255;
    const g = parseInt(hexColor.substring(3, 5), 16) / 255;
    const b = parseInt(hexColor.substring(5, 7), 16) / 255;

    // Calcular la luminancia
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    // Un valor de luminancia menor a 0.5 indica que el color es oscuro
    return luminance < 0.5;
}
export function generateRandomHexColor(): string {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Genera un número aleatorio entre 0 y 16777215 y lo convierte a hexadecimal.
    return '#' + randomColor.padStart(6, '0'); // Asegura que el string tenga 6 caracteres, rellenando con ceros al inicio si es necesario.
}
export function generateMapRandomColor() {
    const color = generateRandomHexColor();
    return {
        bg: color,
        color: isColorDark(color) ? 'white' : 'black',
    };
}
