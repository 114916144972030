export enum AlertType {
    INFO = 'INFO',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL',
    RESTRICTED = 'RESTRICTED',
    NOT_ALLOWED = 'NOT_ALLOWED',
}

export enum AlertClassification {
    LADA = 'LADA',
    LOCATION = 'LOCATION',
    BLACKLIST = 'BLACKLIST',
}

export enum AlertStatus {
    ACTIVO = 'ACTIVO',
    // INACTIVO = 'INACTIVO',
    ESPERA = 'ESPERA',
    ATENDIDO = 'ATENDIDO',
}

export enum AlertSort {
    DateAsc = 'DateAsc',
    DateDesc = 'DateDesc',
    Type = 'Type',
    Status = 'Status',
}
export const AlertSortAttr: Record<AlertSort, string> = {
    DateAsc: 'updatedAt',
    DateDesc: 'updatedAt',
    Status: 'status',
    Type: 'type',
};
