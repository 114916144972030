import { ChildCascade, ChildrenFunction } from '../../model/components/input-meta/input-select.model';
import { InputType } from '../../model/components/shared-models.model';
import { SharedApiService } from '../../services/shared/shared-api.service';

export const DISPLAY_VALUES = 'displayValues';
export const OPTION_LABEL = 'text';
export const OPTION_VALUE = 'value';

export const SimpleDropDownElements: InputType[] = [InputType.Select, InputType.SelectMulti];
export const EndPointDropDownElements: InputType[] = [InputType.Endpoint, InputType.EndpointMulti];
export const LookupDropDownElements: InputType[] = [InputType.Lookup, InputType.LookupMulti];
export const MultiDropDownElements: InputType[] = [InputType.LookupMulti, InputType.SelectMulti, InputType.EndpointMulti];
export const DropDownElements: InputType[] = [InputType.Select, InputType.Endpoint, InputType.Lookup];
export const AllDropDownElements: InputType[] = [...DropDownElements, ...MultiDropDownElements];

export const getChildrenFunctions = (children: ChildCascade[]) =>
    children.reduce((functions: Array<ChildrenFunction>, child: ChildCascade) => (child.cascadeFn ? [...functions, child.cascadeFn] : functions), []);

export const getEndPointMethod = (path: string, sharedApiService: SharedApiService) => {
    const identifiers = ['*', '+'];
    const identifiersMethods: { [key: string]: any } = {
        '*': sharedApiService.getDataEPFromApi$.bind(sharedApiService),
        '+': sharedApiService.getDataEPFromExternalApi$.bind(sharedApiService),
    };
    const identifierEndPoint = path.charAt(0);
    if (identifiers.includes(identifierEndPoint)) {
        return identifiersMethods[identifierEndPoint];
    }
    return sharedApiService.getDataEP$.bind(sharedApiService);
};
