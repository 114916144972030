<p>dummy works! Message: {{message}}</p>
<button pButton (click)="setComplete()">Dummy button</button>

<ng-container *ngIf="cardDummy">
    <lib-form-card [(formGroup)]="formGroup" [metaData]="meta" [formMode]="formWriteMode"
        [iconInputActionList]="iconAction">

        <div card-title>Dummy Lib Card</div>

    </lib-form-card>

</ng-container>