import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputMode, ListPanelConfiguration, ListPanelFormComponent, MetaDataOptions, NotificationService, PanelService } from 'dashboard-lib';
import { ConfirmationService } from 'primeng/api';
import { tap } from 'rxjs';
import { UserWhitelistListPanelParams } from 'src/app/configuration/dashboard.config';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralUser } from '../../../model/alert.model';

@UntilDestroy()
@Component({
    selector: 'app-dialog-users-whitelist',
    templateUrl: './dialog-users-whitelist.component.html',
    styleUrls: ['./dialog-users-whitelist.component.scss'],
})
export class DialogUsersWhitelistComponent implements OnInit {
    @Input({ required: true }) open: boolean = false;
    @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild(ListPanelFormComponent) lstPanel: ListPanelFormComponent | null = null;

    private isWriteMode: boolean = false;

    public pnlListWhitelistUser: ListPanelConfiguration<any> = { ...UserWhitelistListPanelParams };
    public options: MetaDataOptions<any> = {
        id: {
            valueInputChanges: (control: AbstractControl, value: any) => {
                (control as FormControl).patchValue((value ?? '').toUpperCase(), { emitEvent: false });
            },
        },
    };

    get isValidToSave(): boolean | undefined {
        return this.control?.valid && this.isWriteMode;
    }

    get control(): FormGroup | undefined {
        return this.panelService.getPanelParams('list-pnl-users-whitelist')?.control;
    }

    constructor(
        private alertService: AlertService,
        private panelService: PanelService,
        private confirmationService: ConfirmationService,
        private notificationService: NotificationService
    ) {}

    public ngOnInit(): void {
        this.initListPanelConfiguration();
    }
    public ngOnChanges(changes: SimpleChanges): void {
        const { open } = changes;
        if (!open.firstChange && this.open) {
            this.alertService
                .getAllUsersFromWhiteList()
                .pipe(untilDestroyed(this))
                .subscribe((users: GeneralUser[]) => {
                    this.pnlListWhitelistUser.dataList$.next(users);
                });
        }
    }

    public onOpenChange(visible: boolean): void {
        this.openChange.emit(visible);
    }

    public onPanelModeChange(mode: InputMode): void {
        this.isWriteMode = mode === InputMode.Write;
    }

    public onSave(): void {
        this.confirmationService.confirm({
            acceptLabel: 'Agregar a lista blanca',
            rejectLabel: 'Cancelar',
            header: 'Lista blanca de usuarios',
            message: '¿Está seguro de agregar el usuario a lista blanca?',
            accept: () =>
                this.alertService
                    .saveWhiteListUsers(this.control?.value)
                    .pipe(
                        untilDestroyed(this),
                        tap((response: any) => {
                            const { message } = response;
                            if (!message) {
                                this.control?.reset();
                                this.lstPanel?.clearSelection();
                                return this.notificationService.onSuccess('Se agregó el usuario a lista blanca', 'Lista blanca de usuarios');
                            }
                        }),
                        tap(() => this.onOpenChange(false))
                    )
                    .subscribe(),
        });
    }

    private initListPanelConfiguration(): void {
        if (this.pnlListWhitelistUser.delete) {
            this.pnlListWhitelistUser.delete.onDelete = (user: GeneralUser) => {
                this.alertService
                    .deleteUserFromWhiteList(user)
                    .pipe(
                        untilDestroyed(this),
                        tap((response: any) => {
                            this.lstPanel?.clearSelection();
                            this.notificationService.onSuccess('Se eliminó el usuario de la lista blanca', 'Lista blanca de usuarios');
                        }),
                        tap(() => this.onOpenChange(false))
                    )
                    .subscribe();
            };
        }
    }
}
