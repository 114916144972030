<p-toolbar styleClass="border-toolbar">
  <div class="p-toolbar-group-start">
    <ng-content select="[toolbar-start]">
    </ng-content>
  </div>
  <div class="p-toolbar-group-end">
    <ng-container *ngFor="let actionBtn of navigationData.toolbar">
      <button pButton pRipple type="button" [icon]="actionBtn.icon" [label]="actionBtn.title || ''"
        class="p-button-rounded p-button-outlined  p-button-sm" (click)="buttonEvent(actionBtn)"></button>
    </ng-container>
    <ng-content select="[toolbar-end]">
    </ng-content>
  </div>
</p-toolbar>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>