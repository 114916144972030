import { Component, Input } from '@angular/core';
import { CardMessageParams } from '../../../model/components/card-message.model';
import { MetaDataButtonAction } from '../../../model/components/meta-data.model';

@Component({
    selector: 'lib-card-message',
    templateUrl: './card-message.component.html',
    styleUrls: ['./card-message.component.scss'],
})
export class CardMessageComponent {
    @Input({ required: true }) params!: CardMessageParams<any>;

    get borderColor(): string {
        return this.params.borderColor ?? 'black';
    }

    public buttonAction(event: MouseEvent, btn: MetaDataButtonAction<any>): void {
        btn.action({ event, data: [], selected: this.params.data });
    }
}
