import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastModel } from './toast.model';

@Injectable({ providedIn: 'root' })
export class ToastService {
    private toastMessage$: Subject<ToastModel> = new Subject();

    constructor() {}

    public getToastMessage$() {
        return this.toastMessage$;
    }

    public onMessage(message: ToastModel) {
        this.toastMessage$.next(message);
    }
}
