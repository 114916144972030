import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DEFAULT_PROVIDERS, DashboardLibModule, TemplateConfigModule } from 'dashboard-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertListComponent } from './components/alert-list/alert-list.component';
import { DialogAlertConfigurationComponent } from './components/alert-list/dialog-alert-configuration/dialog-alert-configuration.component';
import { DialogAlertComponent } from './components/alert-list/dialog-alert/dialog-alert.component';
import { DialogStatusComponent } from './components/alert-list/dialog-status/dialog-status.component';
import { DialogUsersWhitelistComponent } from './components/alert-list/dialog-users-whitelist/dialog-users-whitelist.component';
import { TemplateConfig } from './configuration/template.config';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PROVIDE_API_RESPONSE } from './interceptors/api.interceptor';
import { PROVIDE_EASYPAY_HEADERS } from './interceptors/easy-pay.interceptor';
@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        AlertListComponent,
        DialogStatusComponent,
        DialogAlertComponent,
        DialogAlertConfigurationComponent,
        DialogUsersWhitelistComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TemplateConfigModule.forRoot(TemplateConfig),
        DashboardLibModule,
        ReactiveFormsModule,
    ],
    providers: [...DEFAULT_PROVIDERS, PROVIDE_EASYPAY_HEADERS, PROVIDE_API_RESPONSE],
    bootstrap: [AppComponent],
})
export class AppModule {}
