import { Pipe, PipeTransform } from '@angular/core';
import { DISPLAY_VALUES } from '../components/forms/meta-input-form/meta-input-form.constants';
import { DataObject } from '../model/template/template.model';

@Pipe({
    name: 'listPanelOptions',
})
export class ListPanelOptionsPipe implements PipeTransform {
    transform(options: DataObject[], customOptionLabel?: (option: DataObject) => string): DataObject[] {
        if (customOptionLabel) {
            options = options.map((option: DataObject) => {
                const displayValue = customOptionLabel(option);

                return {
                    ...option,
                    [DISPLAY_VALUES]: displayValue,
                };
            });
        }
        return options;
    }
}
