import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService, SharedApiService } from 'dashboard-lib';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertClassification } from '../constants/dashboard.constants';
import { Alert, AlertConfiguration, AlertDialogStatus, AlertResponse, GeneralUser } from '../model/alert.model';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    constructor(private sharedApiService: SharedApiService, private httpClient: HttpClient, private notificationService: NotificationService) {}

    /*****************************
     * Alerts Methods
     *****************************/

    public saveAlertComment(alertId: string, comment: string): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/alerts/${alertId}/comments`, { comment });
    }

    public updateStatus(alertStatus: AlertDialogStatus): Observable<any> {
        const { alertId, comment, status } = alertStatus;
        return this.httpClient.put(`${environment.apiUrl}/alerts/${alertId}/status`, { status, comment });
    }

    public getAlerts(pageNumber: number, pageSize: number = 100): Observable<AlertResponse> {
        const params = {
            pageNumber,
            pageSize,
        };
        return this.sharedApiService.getDataEP$<AlertResponse>('alerts', params);
    }

    public getAlertById(id: string): Observable<Alert> {
        return this.sharedApiService.getDataEP$<Alert>(`alerts/${id}`);
    }

    /*****************************
     * Configuration Alerts Methods
     *****************************/
    public getAlertConfigurations(): Observable<AlertConfiguration[]> {
        return this.sharedApiService.getDataEP$('configs').pipe(map((response: any) => response.results));
    }

    public getAlertConfigurationsById(id: string): Observable<AlertConfiguration> {
        return this.sharedApiService.getDataEP$<AlertConfiguration>(`configs/${id}`);
    }

    public saveConfiguration(configuration: any): Observable<any> {
        const { type, email, lada, country, region, city, classification, blackListName, users } = configuration;
        const paramsPayload = () => {
            if (classification === AlertClassification.LADA) {
                return { lada };
            }
            if (classification === AlertClassification.LOCATION) {
                return { city, region, country };
            }
            // by default blacklist
            return {
                blackListName,
                users: users
                    .filter(({ _deleted }: any) => !_deleted)
                    .map(({ userName, userId }: any) => ({
                        userName,
                        userId,
                    })),
            };
        };

        const payload = {
            type,
            email: !!email,
            params: paramsPayload(),
        };
        return this.httpClient.post(`${environment.apiUrl}/configs/classifications/${classification}`, payload);
    }

    public deleteAlertConfiguration(alert: AlertConfiguration): Observable<any> {
        return this.httpClient.delete(`${environment.apiUrl}/configs/${alert.id}`);
    }

    /*****************************
     * WhiteList Methods
     *****************************/
    public saveWhiteListUsers(user: GeneralUser): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/configs/whitelist/users`, user);
    }

    public deleteUserFromWhiteList(user: GeneralUser): Observable<any> {
        return this.httpClient.delete(`${environment.apiUrl}/configs/whitelist/users/${user.userId}`);
    }

    public getAllUsersFromWhiteList(): Observable<GeneralUser[]> {
        return this.sharedApiService.getDataEP$('configs/whitelist/users').pipe(map((response: any) => response.results));
    }

    public getUserByIdFromWhiteList(id: string): Observable<GeneralUser> {
        return this.sharedApiService.getDataEP$<GeneralUser>(`configs/whitelist/users/${id}`);
    }
}
