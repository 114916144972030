import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, map, tap } from 'rxjs';
import { getErrorMessage, inputTemplates } from '../../../constants/form/form.constants';
import { TransformationTextType } from '../../../model/components/menu-bar.model';
import { DropDownCascade, DropdownTypeConfig } from '../../../model/components/meta-input-form.model';
import { InputMode } from '../../../model/components/shared-models.model';
import { DataObject } from '../../../model/template/template.model';
import { getUuid } from '../../../model/utils/utils.model';
import { SharedApiService } from '../../../services/shared/shared-api.service';
import { isEmptyArray, isNotNull } from '../../../utils/general-functions';
import {
    DataType,
    ExtendedMetaDataModel,
    FormMode,
    InputAction,
    InputFormGroupModel,
    InputTemplateModel,
    InputType,
    MetaConditionModel,
    MetaDataModel,
    MetaOptionModel,
    MetaValidationModel,
} from './../../../model/components/form.model';
import { EndPointDropDownElements, LookupDropDownElements, OPTION_LABEL, OPTION_VALUE, SimpleDropDownElements } from './meta-input-form.constants';
@UntilDestroy()
@Component({
    selector: 'lib-meta-input-form',
    templateUrl: './meta-input-form.component.html',
    styleUrls: ['./meta-input-form.component.scss'],
})
export class MetaInputFormComponent implements OnInit, OnChanges, AfterViewChecked {
    @ViewChild('textTemplate', { static: true }) textTemplate!: TemplateRef<any>;
    @ViewChild('dropTemplate', { static: true }) dropTemplate!: TemplateRef<any>;
    @ViewChild('lookTemplate', { static: true }) lookTemplate!: TemplateRef<any>;
    @ViewChild('lookMultiTemplate', { static: true }) lookMultiTemplate!: TemplateRef<any>;
    @ViewChild('selectMultiTemplate', { static: true }) selectMultiTemplate!: TemplateRef<any>;
    @ViewChild('endPointTemplate', { static: true }) endPointTemplate!: TemplateRef<any>;
    @ViewChild('endPointMultiTemplate', { static: true }) endPointMultiTemplate!: TemplateRef<any>;
    @ViewChild('boolTemplate', { static: true }) boolTemplate!: TemplateRef<any>;
    @ViewChild('areaTemplate', { static: true }) areaTemplate!: TemplateRef<any>;
    @ViewChild('dateTemplate', { static: true }) dateTemplate!: TemplateRef<any>;
    @ViewChild('dateTimeTemplate', { static: true }) dateTimeTemplate!: TemplateRef<any>;
    @ViewChild('inputSelect', { static: true }) inputSelect!: TemplateRef<any>;

    @Input() metaData!: ExtendedMetaDataModel | Partial<ExtendedMetaDataModel>;
    @Input() control: AbstractControl = new FormControl();
    @Input() formMode: FormMode | InputMode = FormMode.Read;
    @Input() readOnly: boolean = false;
    @Input() iconAction: InputAction | null = null;
    @Input() isAppendToBody: boolean = false;
    @Input() hiddenLabel: boolean = false;
    @Input() reloadMasonry: Function | null = null;
    @Input() transformTextType: TransformationTextType | null = null;

    @Output() onLoad: EventEmitter<ExtendedMetaDataModel | Partial<ExtendedMetaDataModel>> = new EventEmitter();
    @Output() onRender: EventEmitter<MetaInputFormComponent> = new EventEmitter();
    @Output() onCreated: EventEmitter<MetaInputFormComponent> = new EventEmitter();
    @Output() onCascade: EventEmitter<DropDownCascade[]> = new EventEmitter();

    get showMetaInputForm(): boolean {
        if (this.metaData.hidden) {
            this.control.setErrors(null);
        }
        return isNotNull(this.control, this.metaData, !this.metaData.hidden);
    }

    get isReadMode(): boolean {
        if (this.readOnly || this.formMode !== FormMode.Write) {
            return true;
        }
        if (this.formMode === FormMode.Write && !this.metaData.editable) {
            return true;
        }
        return false;
    }

    get template(): TemplateRef<any> | null {
        if (this.metaData.input) {
            const templateRef = this.templates[this.metaData.input];
            return templateRef ? templateRef : null;
        }
        return null;
    }

    get formControl(): FormControl {
        return this.control as FormControl;
    }

    get inputFormGroup(): InputFormGroupModel {
        const { input, option } = (this.control as FormGroup).controls;
        return { input: input as FormControl, option: option as FormControl };
    }

    get optionLabel(): string {
        return this.dropdownTypeConfig?.optionLabel || OPTION_LABEL;
    }

    get optionValue(): string {
        return this.dropdownTypeConfig?.optionValue == null ? OPTION_VALUE : this.dropdownTypeConfig?.optionValue;
    }

    get dropDownClasses(): { [klass: string]: boolean } {
        let klasses = {};
        if (this.dropdownTypeConfig) {
            const { afterButton, prevButton } = this.dropdownTypeConfig;
            klasses = {
                'lib-dropdown': !!afterButton || !!prevButton,
                'remove-right-border': !!afterButton,
                'remove-left-border': !!prevButton,
            };
        }
        return klasses;
    }

    public Type = InputType;
    public DataType = DataType;
    public errorMessages: string[] = [];
    public showError: boolean = false;
    public lookupValues: Observable<DataObject[]> | null = null;
    public endPointValues: Observable<DataObject[]> | null = null;
    public FORM_WRITE = FormMode.Write;
    public isSimpleWrapper: boolean = true;
    public inputTextClass: DataObject | string = '';
    public uuid: string = getUuid();
    public dropdownTypeConfig: DropdownTypeConfig | null | undefined = null;

    private templates: InputTemplateModel = {};

    public constructor(private sharedApiService: SharedApiService) {}

    ngAfterViewChecked(): void {
        this.onRender.emit(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { metaData, formMode } = changes;
        const _metaData = metaData?.currentValue as MetaDataModel;
        if (_metaData) {
            const metaOptions = _metaData.options;
            if (
                !metaOptions?.find((option: MetaOptionModel) => option.text === '-' && option.value == null) &&
                _metaData.input != InputType.SelectMulti
            ) {
                const _options = (metaData.currentValue as MetaDataModel).options || [];
                this.metaData.options = [{ text: '-', value: null }, ..._options];
            }
        }
    }

    public ngOnInit(): void {
        setTimeout(this.init.bind(this));
    }

    public setOptions(options$: Observable<DataObject[]> | undefined): void {
        if (options$) {
            const inputType = this.metaData.input || InputType.Unknown;
            if (SimpleDropDownElements.includes(inputType)) {
                options$?.pipe(untilDestroyed(this)).subscribe((options: DataObject[]) => (this.metaData.options = options as MetaOptionModel[]));
                return;
            }
            if (EndPointDropDownElements.includes(inputType)) {
                this.endPointValues = options$;
                return;
            }
            this.lookupValues = options$;
        }
    }

    private init(): void {
        this.templates = inputTemplates(this);
        this.setDefaultDataBehavior();
        this.setValidations();
        this.control.updateValueAndValidity();
        this.isSimpleWrapper = this.metaData.input != InputType.InputSelect;
        if (this.transformTextType) this.inputTextClass = { [this.transformTextType]: true };
        this.onCreated.emit(this);
    }

    private setValidations(): void {
        this.control.valueChanges.pipe(untilDestroyed(this), tap(this.reloadLayout.bind(this))).subscribe(this.emitValidations.bind(this));
    }

    private reloadLayout(): void {
        if (this.control.invalid && this.reloadMasonry) {
            this.reloadMasonry();
        }
    }

    private emitValidations(): void {
        this.showError = !this.control.valid;
        const validationConditions = this.metaData.conditions?.flatMap((condition: MetaConditionModel) => condition.validations) || [];
        const validations = isEmptyArray(validationConditions) ? this.metaData.validations || [] : validationConditions;
        const errorMessagesMethod = this.control instanceof FormGroup ? this.setErrorsGroup.bind(this) : this.setErrors.bind(this);
        errorMessagesMethod(validations);
    }

    private setCascadeMode(): void {
        const cascade = this.metaData?.dropDownConfig?.cascade;
        if (cascade) {
            this.control.valueChanges
                .pipe(
                    untilDestroyed(this),
                    tap((value: any) => {
                        const dropDownCascades = cascade.map((dropDownCascade: DropDownCascade) => {
                            const { cascadeFn, options } = dropDownCascade;
                            if (options) {
                                return { ...dropDownCascade, options };
                            }
                            if (cascadeFn) {
                                return { ...dropDownCascade, options: cascadeFn(value) };
                            }
                            return dropDownCascade;
                        });

                        this.onCascade.emit(dropDownCascades);
                    })
                )
                .subscribe();
        }
    }

    private setLookupOptions(): void {
        const { lookupType } = this.metaData;
        const params = {
            type: lookupType,
        };
        this.lookupValues = this.sharedApiService.getDataEP$<DataObject[]>('lookups', params).pipe(tap(() => this.onLoad.emit(this.metaData)));
        if (this.formMode === FormMode.Read) this.lookupValues.pipe(untilDestroyed(this)).subscribe();
    }

    private setEndPointOptions(): void {
        const { endPoint } = this.metaData;
        const getData = endPoint?.includes('*')
            ? this.sharedApiService.getDataEPFromApi$.bind(this.sharedApiService)
            : this.sharedApiService.getDataEP$.bind(this.sharedApiService);
        this.endPointValues = getData(endPoint || '').pipe(
            map(response => (Array.isArray(response) ? response : response.data)),
            tap(() => this.onLoad.emit(this.metaData))
        );
        if (this.formMode === FormMode.Read) this.endPointValues.pipe(untilDestroyed(this)).subscribe();
    }

    private isLookupElement(): boolean {
        const inputType = this.metaData.input || InputType.Unknown;
        return !!this.metaData.lookupType && LookupDropDownElements.includes(inputType);
    }

    private isEndPointElement(): boolean {
        const inputType = this.metaData.input || InputType.Unknown;
        return EndPointDropDownElements.includes(inputType);
    }

    private setDefaultDataBehavior(): void {
        this.dropdownTypeConfig = this.metaData.dropDownConfig;
        if (this.isLookupElement()) return this.setLookupOptions();
        if (this.metaData.input === InputType.Check && !this.control.value) return this.control.setValue(false);
        if (this.isEndPointElement()) return this.setEndPointOptions();
        if (this.dropdownTypeConfig) this.setCascadeMode();
    }

    private setErrors(validations: MetaValidationModel[]): void {
        this.errorMessages = getErrorMessage(this.control.errors, validations);
    }

    private setErrorsGroup(validations: MetaValidationModel[]): void {
        let errors: string[] = [];
        if (this.control instanceof FormGroup) {
            const controls = this.control.controls;
            Object.keys(controls).forEach((key: string) => {
                const control = controls[key];
                const errorMessages = getErrorMessage(control.errors, validations);
                errors = [...errors, ...errorMessages];
            });
        }
        this.errorMessages = errors;
    }
}
