<div class="container">
    <div class="container-box">

        <p-toolbar [style.width.%]="100">
            <div class="p-toolbar-group-start">
                <ng-container *ngIf="listPnlConfig.title as titleBox">
                    <label class="box-title p-lib-label">{{titleBox}}</label>
                </ng-container>
            </div>
            <div class="p-toolbar-group-end">
                <p-toggleButton *ngIf="isMultiple" #toggleMultiple onLabel="Simple" offLabel="Múltiple"
                    [onIcon]="'pi pi-bars'" offIcon="pi pi-list" [style]="{ width: '10em' }"
                    (onChange)="clearSelection()" [(ngModel)]="isMultipleChecked"></p-toggleButton>
                <p-button *ngIf="listPnlConfig.newAction" icon="pi pi-plus" [outlined]="true"
                    (onClick)="newEvent()"></p-button>
                <p-button *ngIf="!listPnlConfig.delete?.hideDeleteButton && isSelected" icon="pi pi-trash "
                    [outlined]="true" styleClass="p-button-danger" (onClick)="deleteEvent()"></p-button>
                <p-button *ngIf="isSelected" icon="pi pi-times" styleClass="p-button-warning" [outlined]="true"
                    (onClick)="clearSelection()"></p-button>
            </div>
        </p-toolbar>

        <p-listbox [style.width.%]="100" [options]="dataList | listPanelOptions:listPnlConfig.customOptionLabel"
            [formControl]="listBoxControl" [optionLabel]="optionLabel" [multiple]="isMultipleChecked" [filter]="true"
            [checkbox]="true" (onChange)="onSelectedData($event)">
        </p-listbox>
    </div>
    <div class="container-panel" [style.display]="showPanel">

        <lib-panel-form [panelConfig]="panelConfig" [metaDataOptions]="metaDataOptions" [panelMode]="panelMode"
            (panelModeChange)="onPanelModeChange($event)">
            <div card-title>
                <span>{{panelTitle}}</span>
            </div>

            <div card-footer>
                <ng-content select="[card-footer]"></ng-content>
            </div>
        </lib-panel-form>
    </div>
</div>