import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [
        DropdownModule,
        CardModule,
        ButtonModule,
        InputTextModule,
        SidebarModule,
        MenubarModule,
        TabMenuModule,
        SelectButtonModule,
        TableModule,
        TabViewModule,
        InputTextareaModule,
        CalendarModule,
        OverlayPanelModule,
        ToastModule,
        MultiSelectModule,
        BlockUIModule,
        TagModule,
        ConfirmDialogModule,
        ListboxModule,
        MenuModule,
        CheckboxModule,
        ToolbarModule,
        DynamicDialogModule,
        DialogModule,
        InputSwitchModule,
        FieldsetModule,
        DividerModule,
        AccordionModule,
        PanelModule,
        StepsModule,
        AvatarModule,
        ProgressSpinnerModule,
        ToggleButtonModule,
        PanelMenuModule,
        DataViewModule,
        KeyFilterModule,
    ],
    providers: [ConfirmationService],
})
export class PrimengModule {}
