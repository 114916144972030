import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { getHttpParams } from '../../constants/shared/shared.constants';
import { EntityMetaDataModel, InputType, MetaDataMode } from '../../model/components/form.model';
import { MetaData } from '../../model/components/meta-data.model';
import { DataObject } from '../../model/template/template.model';
import { _moment, ApiDataModel, OptionsPayloadModel } from '../../model/utils/utils.model';
import { ConfigService } from '../template/config.service';

@Injectable({
    providedIn: 'root',
})
export class SharedApiService {
    constructor(private httpCLient: HttpClient, private config: ConfigService) {}

    public getMetaData$(entity: string, mode: MetaDataMode): Observable<EntityMetaDataModel> {
        const params = {
            entity,
            mode,
        };
        const paramsHttp = getHttpParams(params);
        const uri = this.config.apiMetaDataUrl + '/metadata';
        return this.httpCLient.get(uri, { params: paramsHttp }) as Observable<EntityMetaDataModel>;
    }
    public getDataEP$<T>(endpoint: string, params: DataObject | null = null): Observable<T> {
        const paramsHttp = getHttpParams(params);
        const url = this.config.apiUrl + `/${endpoint}`;
        return this.httpCLient.get<T>(url, { params: paramsHttp });
    }

    public getDataEPFromApi$(endpoint: string, params: DataObject | null = null): Observable<any> {
        const paramsHttp = getHttpParams(params);
        const url = this.config.apiMetaDataUrl + `/${endpoint.replace('*', '')}`;
        return this.httpCLient.get(url, { params: paramsHttp });
    }

    public getDataEPFromExternalApi$(endpoint: string, params: DataObject | null = null): Observable<any> {
        const paramsHttp = getHttpParams(params);
        const url = endpoint.replace('+', '');
        return this.httpCLient.get(url, { params: paramsHttp });
    }

    public getDataEPFromExternalApiPost$(endpoint: string, body: any, params: DataObject | null = null): Observable<any> {
        const paramsHttp = getHttpParams(params);
        const url = endpoint.replace('+', '');
        return this.httpCLient.post(url, body, { params: paramsHttp });
    }

    public getDataEPTable$(endpoint: string, params: DataObject | null = null): Observable<DataObject[]> {
        return this.getDataEP$<ApiDataModel>(endpoint, params).pipe(map((response: ApiDataModel) => response.data));
    }

    public getPayloadEntity(entity: DataObject, metaDataList: MetaData[], options?: OptionsPayloadModel): DataObject {
        return metaDataList.reduce((payload: DataObject, meta: MetaData) => {
            let value = entity[meta.field];

            if (meta.input === InputType.Date && value instanceof Date && options?.formatDate) {
                value = _moment(value).format(options.formatDate);
            }
            if (!meta.hidden && meta.editable) {
                return { ...payload, [meta.field]: value };
            }
            return { ...payload };
        }, {});
    }
}
