<p-dialog header="Lista blanca de usuarios" [(visible)]="open" (visibleChange)="onOpenChange($event)" [modal]="true"
    [style]="{ width: '70vw' }">

    <lib-list-panel-form [listPanelConfig]="pnlListWhitelistUser" [metaDataOptions]="options"
        (panelModeChange)="onPanelModeChange($event)">
        <div card-footer>
            <p-button *ngIf="isValidToSave" icon="pi pi-save" label="Guardar" size="small" severity="success"
                (onClick)="onSave()"></p-button>
        </div>
    </lib-list-panel-form>
</p-dialog>