import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, OperatorFunction, map, of } from 'rxjs';
import { ExtendedMenuItem } from '../../../../model/components/menu-bar.model';
import { deepCloneArray } from '../../../../utils/clone-object';
import { overrideItems } from '../../../../utils/general-functions';
import { SubMenuModel } from './../../../../model/template/template.model';

@UntilDestroy()
@Component({
    selector: 'lib-menu-sidenav',
    templateUrl: './menu-sidenav.component.html',
    styleUrls: ['./menu-sidenav.component.scss'],
})
export class MenuSidenavComponent implements OnChanges {
    public menu$: Observable<ExtendedMenuItem[]> | null = null;
    private overrideMenusOp: OperatorFunction<ExtendedMenuItem[], ExtendedMenuItem[]> = map(this.overrideMenus.bind(this));
    private originalMenusOp: OperatorFunction<ExtendedMenuItem[], ExtendedMenuItem[]> = map(this.originalMenus.bind(this));
    private _menus: ExtendedMenuItem[] = [];
    private originalMenu: ExtendedMenuItem[] = [];

    @Input({ required: true }) set menus(_menus: ExtendedMenuItem[]) {
        this._menus = _menus;
        this.originalMenu = deepCloneArray<ExtendedMenuItem>(_menus) as ExtendedMenuItem[];
    }
    @Input() expandedMenu: boolean = false;
    @Output() menuButtonEvent: EventEmitter<SubMenuModel> = new EventEmitter();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            const menuFn = !this.expandedMenu ? this.overrideMenusOp : this.originalMenusOp;
            this.menu$ = of(this._menus).pipe(untilDestroyed(this), menuFn);
        }
    }

    public clickMenuButton(submenu: SubMenuModel) {
        this.menuButtonEvent.emit(submenu);
    }

    private overrideMenus(_menus: ExtendedMenuItem[]): ExtendedMenuItem[] {
        return overrideItems(_menus, { label: '' });
    }

    private originalMenus(_menus: ExtendedMenuItem[]): ExtendedMenuItem[] {
        return this.originalMenu;
    }
}
