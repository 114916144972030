import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { DEFAULT_HEADERS_INTERCEPTOR_HTTP } from '../../constants/interceptors/config-interceptor.constants';
import { ConfigService } from '../template/config.service';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
    constructor(private config: ConfigService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers;
        const { apiKey } = this.config;

        const defaultHeaders = apiKey
            ? { ...DEFAULT_HEADERS_INTERCEPTOR_HTTP, 'x-api-key': this.config.apiKey }
            : DEFAULT_HEADERS_INTERCEPTOR_HTTP;
        Object.keys(defaultHeaders).forEach((key: string) => (headers = headers.set(key, defaultHeaders[key])));
        return next.handle(
            req.clone({
                headers,
            })
        );
    }
}
export const PROVIDE_DEFAULT_HEADERS_INTERCEPTOR: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: DefaultHeadersInterceptor,
    multi: true,
};
