import { FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { MetaInputFormComponent } from '../../components/forms/meta-input-form/meta-input-form.component';
import { InputType, MetaDataModel, MetaValidationModel, ValidationType } from '../../model/components/form.model';
import { DataObject } from '../../model/template/template.model';
import { clearSelectionControl } from '../../utils/util-form';

function getValidationsObject(validationList: Array<MetaValidationModel>): DataObject {
    return validationList.reduce(
        (validations: DataObject, validation: MetaValidationModel) => ({
            ...validations,
            [validation.type]: validation.errorMessage,
        }),
        {}
    );
}
export const VALIDATIONS = (param: any) => {
    return {
        [ValidationType.Required]: param === 'true' ? Validators.required : null,
        [ValidationType.Email]: Validators.email,
        [ValidationType.Max]: Validators.max(param),
        [ValidationType.Min]: Validators.min(param),
        [ValidationType.MinLength]: Validators.minLength(param),
        [ValidationType.MaxLength]: Validators.maxLength(param),
        [ValidationType.Pattern]: Validators.pattern(param),
    };
};

export function getErrorMessage(errors: ValidationErrors | null, validationList: Array<MetaValidationModel>): string[] {
    const validationsObject = getValidationsObject(validationList);
    return Object.keys(errors || {})
        .filter((errorKey: string) => (errors ? errors[errorKey] : false))
        .reduce((messages: string[], errorKey: string) => {
            const errorMessage = validationsObject[errorKey];
            return [...messages, errorMessage];
        }, []);
}

export const inputTemplates = (ctx: MetaInputFormComponent) => {
    return {
        [InputType.Text]: ctx.textTemplate,
        [InputType.Select]: ctx.dropTemplate,
        [InputType.Lookup]: ctx.lookTemplate,
        [InputType.LookupMulti]: ctx.lookMultiTemplate,
        [InputType.SelectMulti]: ctx.selectMultiTemplate,
        [InputType.Endpoint]: ctx.endPointTemplate,
        [InputType.EndpointMulti]: ctx.endPointMultiTemplate,
        [InputType.Check]: ctx.boolTemplate,
        [InputType.Textarea]: ctx.areaTemplate,
        [InputType.Date]: ctx.dateTemplate,
        [InputType.DateTime]: ctx.dateTimeTemplate,
        [InputType.InputSelect]: ctx.inputSelect,
    };
};

const LoadableInputs: InputType[] = [InputType.Endpoint, InputType.Lookup, InputType.LookupMulti];
export const isLoadableInput: (input: InputType | null) => boolean = (input: InputType | null) => LoadableInputs.includes(input || InputType.Text);

export const actionEventComplex =
    (event: Function | null | undefined, formGroup: FormGroup, metaData: MetaDataModel, confirmService: ConfirmationService) =>
    (confirmation: Confirmation | null, accept: Function, reject?: Function) => {
        if (confirmation) {
            confirmation.accept = () => {
                accept();
                if (event) event();
            };
            confirmation.reject = () => {
                if (reject) reject();
                clearSelectionControl(formGroup, metaData.field);
            };
            confirmService.confirm(confirmation);
        } else {
            accept();
            if (event) event();
        }
    };

export const DefaultMasonryOptions: NgxMasonryOptions = {
    columnWidth: 5,
    itemSelector: '.masonry-item',
};
