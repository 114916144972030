import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, Provider } from '@angular/core';
import { DataObject } from '../../model/template/template.model';
//Constantes
export const LogoImageDefault = 'https://lsystems.com.mx/wp-content/uploads/2021/01/lsystems_icon.png';

export const AvatarImageDefault =
    'https://ae01.alicdn.com/kf/HTB1J_fJXU_rK1Rjy0Fcq6zEvVXaz/HUAYI-Photo-Backdrops-Stage-Light-Flicker-Artistic-Photo-Studios-Background-XT-7308.jpg_Q90.jpg_.webp';

export const SIDE_NAV_EXTENDED_TOKEN = 'expandedSideNav';
// export function loadObs<T>(load: LoadingService): MonoTypeOperatorFunction<T> {
//     return tap<T>({
//         next: (value: any) => {
//             if (!value) {
//                 load.unblock();
//             }
//         },
//         error: () => load.unblock(),
//         complete: () => load.unblock(),
//     });
// }

export const PIPE_PROVIDERS: Provider[] = [DecimalPipe, CurrencyPipe, DatePipe, PercentPipe];
export const DEFAULT_PROVIDERS: Provider[] = [
    { provide: DEFAULT_CURRENCY_CODE, useValue: '$' },
    { provide: LOCALE_ID, useValue: 'es-MX' },
];

export const getHttpParams = (params: DataObject | null): HttpParams => {
    let paramsHttp = new HttpParams();
    if (params) {
        Object.keys(params).forEach((key: string) => (paramsHttp = paramsHttp.set(key, params[key])));
    }
    return paramsHttp;
};

export const API_COUNTRIES = 'https://countriesnow.space/api/v0.1/countries/states';
