<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<ng-container *ngIf="toastMessage$ | async">
    <p-toast position="bottom-center" key="c" (onClose)="onReject()" [baseZIndex]="5000" [life]="5000">
        <ng-template let-message pTemplate="message">
            <div class="flex flex-column" style="flex: 1">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                    <p>{{message.detail}}</p>
                </div>
                <div class="grid p-fluid">
                    <div class="col-6">
                        <button type="button" pButton (click)="onConfirm()" label="Sí"
                            class="p-button-success"></button>
                    </div>
                    <div class="col-6">
                        <button type="button" pButton (click)="onReject()" label="No"
                            class="p-button-secondary"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</ng-container>