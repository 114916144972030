import { AbstractControl } from '@angular/forms';

export enum TableControlNames {
    ID = '_id',
    UPDATED = '_updated',
    DELETED = '_deleted',
    NEW = '_new',
}

export const controlIdFn = (control: AbstractControl) => control.get(TableControlNames.ID)?.value || '';
