<ng-container *ngIf="showMetaInputForm && template as templateRef">

  <!-- Simple view container -->
  <ng-container *ngIf="isSimpleWrapper">
    <div class="p-field">
      <label *ngIf="!hiddenLabel" class="p-lib-label">{{metaData.label}}</label>
      <div class="input-container">
        <div class="element-action">
          <ng-container *ngIf="isReadMode"> {{formControl.value | dataTypePipe:metaData}} </ng-container>
          <ng-container *ngIf="!isReadMode" [ngTemplateOutlet]="templateRef"></ng-container>
        </div>
        <div class="icon-action">
          <button *ngIf="iconAction" pButton pRipple type="button" icon="pi pi-info" (click)="iconAction.action()"
            class="p-button-rounded p-button-outlined"></button>
        </div>
      </div>

    </div>

  </ng-container>

  <!-- Group view container -->
  <ng-container *ngIf="!isSimpleWrapper">
    <div class="p-inputgroup" class="group">
      <ng-container *ngIf="isReadMode"> {{formControl.value | dataTypePipe:metaData}} </ng-container>
      <ng-container *ngIf="!isReadMode" [ngTemplateOutlet]="templateRef"></ng-container>
    </div>
  </ng-container>


  <ng-container *ngFor="let errorMessage of errorMessages">
    <small class="p-error small-error" *ngIf="showError && formMode === FORM_WRITE">{{errorMessage}}</small>
  </ng-container>

</ng-container>





<ng-template #textTemplate>
  <input libExtInput [formControl]="formControl" [ngClass]="inputTextClass" pInputText
    class="p-inputtext-sm input-field element" [placeholder]="metaData.placeholder" />
</ng-template>

<ng-template #dropTemplate>
  <ng-container
    *ngTemplateOutlet="dropDownElement; context:{options: metaData.options||[], control:formControl}"></ng-container>

</ng-template>

<ng-template #selectMultiTemplate>
  <ng-container
    *ngTemplateOutlet="multiDropDownElement; context:{options:metaData.options || [], control:formControl}"></ng-container>
</ng-template>


<ng-template #lookTemplate>
  <ng-container *ngIf="lookupValues | async as options">
    <ng-container *ngTemplateOutlet="dropDownElement; context:{options, control:formControl}"></ng-container>
  </ng-container>
</ng-template>

<ng-template #lookMultiTemplate>
  <ng-container *ngIf="lookupValues | async as options">
    <ng-container *ngTemplateOutlet="multiDropDownElement; context:{options, control:formControl}"></ng-container>
  </ng-container>
</ng-template>

<ng-template #endPointTemplate>
  <ng-container *ngIf="endPointValues | async as options">
    <ng-container *ngTemplateOutlet="dropDownElement; context:{options, control:formControl}"></ng-container>
  </ng-container>
</ng-template>

<ng-template #endPointMultiTemplate>
  <ng-container *ngIf="endPointValues | async as options">
    <ng-container *ngTemplateOutlet="multiDropDownElement; context:{options, control:formControl}"></ng-container>
  </ng-container>
</ng-template>

<ng-template #boolTemplate>
  <mat-slide-toggle class="element" color="accent" [formControl]="formControl"></mat-slide-toggle>
</ng-template>

<ng-template #areaTemplate>
  <textarea class="txtArea input-field element" rows="5" cols="30" pInputTextarea [formControl]="formControl"
    [autoResize]="true"></textarea>
</ng-template>

<ng-template #dateTemplate>
  <p-calendar [formControl]="formControl" dateFormat="mm-dd-yy" inputId="dateformat" class="element"
    [appendTo]="isAppendToBody ? 'body' : '' ">
  </p-calendar>
</ng-template>

<ng-template #dateTimeTemplate>
  <p-calendar [formControl]="formControl" [appendTo]="isAppendToBody ? 'body' : ''" [showTime]="true"
    dateFormat="mm-dd-yy" inputId="dateformat" class="element">
  </p-calendar>
</ng-template>

<ng-template #inputSelect>
  <input [formControl]="inputFormGroup.input" class="p-inputtext-sm input-group" pInputText
    [placeholder]="metaData.placeholder" />

  <ng-container
    *ngTemplateOutlet="dropDownElement; context:{options:metaData.options || [], control:inputFormGroup.option}"></ng-container>

</ng-template>

<!-- Template dropdown -->
<ng-template #dropDownElement let-options="options" let-control="control" let-isGroup="isGroup">
  <button *ngIf="dropdownTypeConfig?.prevButton as prevBtn" [disabled]="!control.valid" pButton pRipple
    [icon]="prevBtn.icon + ''" class="p-button-info btn-action btn-prev" (click)="prevBtn.action(control.value)"
    [pTooltip]="prevBtn.tooltip" tooltipPosition="left"></button>

  <p-dropdown [options]="options | metaInputDropdownValues: dropdownTypeConfig" class="element"
    [ngClass]="dropDownClasses" [formControl]="control" [filterBy]="optionLabel" [optionLabel]="optionLabel"
    [optionValue]="optionValue" [filter]="true" [showClear]="true" autoWidth="false"
    [placeholder]="metaData.placeholder || ''" [appendTo]="isAppendToBody ? 'body' : ''">
  </p-dropdown>

  <button *ngIf="dropdownTypeConfig?.afterButton as afterBtn" [disabled]="!control.valid" pButton pRipple
    [icon]=" afterBtn.icon + ''" class=" p-button-success btn-action btn-after" (click)="afterBtn.action(control.value)"
    [pTooltip]="afterBtn.tooltip" tooltipPosition="right"></button>
</ng-template>

<ng-template #multiDropDownElement let-options="options" let-control="control" let-isGroup="isGroup">

  <p-multiSelect [options]="options | metaInputDropdownValues: dropdownTypeConfig" optionValue="value" class="element"
    [ngClass]="{'drop':isGroup}" [formControl]="control" [filterBy]="optionLabel" [optionLabel]="optionLabel"
    [filter]="true" display="chip" [placeholder]="metaData.placeholder||''" [appendTo]="isAppendToBody ? 'body' : ''">
  </p-multiSelect>

</ng-template>