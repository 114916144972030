import { Component, Input, OnDestroy, Type, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuItem, MenuItemCommandEvent, PrimeIcons } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, combineLatest, tap } from 'rxjs';
import { DropDownElements } from '../../../../constants/components/input-select.constants';
import { TableControlNames, controlIdFn } from '../../../../constants/components/input-table.constants';
import { InputTypeList } from '../../../../constants/components/input.constants';
import { InputComplexOptions } from '../../../../model/components/input-meta/input-complex.model';
import { InputSelectOptions } from '../../../../model/components/input-meta/input-select.model';
import { HeaderInputTableModel, InputTableOptions, SearchTableOptions, TableConfig } from '../../../../model/components/input-meta/input-table.model';
import { MetaDataButtonAction, MetaDataExtended } from '../../../../model/components/meta-data.model';
import { InputMode, InputType } from '../../../../model/components/shared-models.model';
import { DataObject } from '../../../../model/template/template.model';
import { MetadataTypePipe } from '../../../../pipes/metadata-type.pipe';
import { MetaFormControlService } from '../../../../services/components/meta-form-control.service';
import { NotificationService } from '../../../../services/shared/notification.service';
import { createControl } from '../../../../utils/controls-operators';
import { deepCompareObjects, isEmptyArray } from '../../../../utils/general-functions';
import { arrayToObjectFn, filterByTerm } from '../../../../utils/util-form';
import { AbstractInputMeta } from '../abstract-input-meta.class';
import { InputTypeParams, RecordInputTypeParams } from '../input-meta.model';

@UntilDestroy()
@Component({
    selector: 'lib-input-table',
    templateUrl: './input-table.component.html',
    styleUrls: ['./input-table.component.scss'],
    providers: [MetadataTypePipe],
})
export class InputTableComponent extends AbstractInputMeta implements OnDestroy {
    @Input({ required: false }) public config!: TableConfig<any>;

    @ViewChild(Table) table: Table | null = null;

    public selectedData: DataObject[] = [];
    public searchControl: FormControl<string> = new FormControl();
    public dataTable: DataObject[] = [];
    public dataTableFiltered: DataObject[] | undefined;
    public table$: Observable<any> | undefined;
    public headers: HeaderInputTableModel<any>[] = [];
    public inputTypeParams: RecordInputTypeParams<any>[] = [];
    public menuTable: MenuItem[] = [
        {
            icon: PrimeIcons.BARS,
            tooltip: 'Menú de tabla',
            items: [
                { icon: PrimeIcons.PLUS_CIRCLE, label: 'Agregar', tooltip: 'Agregar...', command: this.addNewItem.bind(this) },
                {
                    icon: PrimeIcons.MINUS_CIRCLE,
                    label: 'Eliminar',
                    tooltip: 'Eliminar...',
                    command: (event: MenuItemCommandEvent) => this.deleteItem(this.selectedData),
                },
            ],
        },
    ];

    private metaDataParams: MetaDataExtended<any>[] = [];
    get tableOptions(): InputTableOptions<any> | undefined {
        return (this.metaData?.inputMetaOptions as InputComplexOptions<any>)?.componentOptions as InputTableOptions<any>;
    }

    get searchOptions(): SearchTableOptions<any> | undefined {
        return this.tableOptions?.searchOptions;
    }

    get inputTypeList(): Record<string, Type<any>> {
        return InputTypeList;
    }

    constructor(
        private fb: FormBuilder,
        private metaDataTypePipe: MetadataTypePipe,
        private notificationService: NotificationService,
        private metaFormControl: MetaFormControlService
    ) {
        super();
    }

    protected override abstractInit(): void {
        this.initTableOptions();
        this.formArray.valueChanges
            .pipe(
                untilDestroyed(this),
                tap((values: any) => {
                    if (this.isReadMode) {
                        this.dataTable = values;
                    }
                })
            )
            .subscribe();
    }

    public override destroyObservables(): void {}
    public override onChangesMode(): void {
        if (isEmptyArray(this.inputTypeParams) || this.isReadMode) {
            return this.addItemFromArray();
        }
        this.dataTable = this.formArray.value;
        this.reloadMasonry();
    }

    public ngOnDestroy(): void {
        this.formArray.clear();
    }

    public buttonAction(event: MouseEvent, btn: MetaDataButtonAction<any>): void {
        btn.action({ event, data: this.formArray.value, selected: this.selectedData });
    }

    public deleteItem(selectedData: DataObject[]): void {
        if (!isEmptyArray(selectedData)) {
            const controlsByID = arrayToObjectFn<AbstractControl>(this.formArray.controls, controlIdFn);
            selectedData.forEach((selectData: DataObject) => {
                const { _id } = selectData;
                const controlRow = controlsByID[_id];
                controlRow.patchValue({ [TableControlNames.DELETED]: true });
            });
            this.dataTable = this.formArray.value;
        }
    }

    public addItemFromArray(): void {
        this.inputTypeParams = [];
        this.formArray.controls.forEach((control: AbstractControl, index: number) => {
            const inputParams = this.metaDataParams.reduce(
                (_inputParams: RecordInputTypeParams<any>, meta: MetaDataExtended<any>) => ({
                    ..._inputParams,
                    ...this.createParamsArray(control.get(meta.field) ?? new FormControl(), meta),
                }),
                {}
            );
            this.inputTypeParams.push(inputParams);
        });
        this.dataTable = this.formArray.value;
        this.reloadMasonry();
    }

    public addNewItem(event: MenuItemCommandEvent): void {
        this.table?.clearFilterValues();
        const group = this.fb.group({});
        const inputParams = this.metaDataParams.reduce(
            (_inputParams: RecordInputTypeParams<any>, meta: MetaDataExtended<any>) => ({
                ..._inputParams,
                ...this.createParams(group, meta),
            }),
            {}
        );
        this.inputTypeParams.push(inputParams);
        this.metaFormControl.createTableControls(group);
        this.formArray.push(group);
        this.dataTable = this.formArray.value;
        this.table?.initRowEdit(group.value);
    }

    public onRowDblClick(data: DataObject, rowIndex: number) {
        const { _deleted } = data;
        if (!_deleted && !this.isReadMode) {
            this.table?.initRowEdit(data);
            return this.onRowEditInit(data, rowIndex);
        }
        this.notificationService.onWarn('No puede editar fila eliminada', 'Eliminar');
    }

    public onCreateInput(component: AbstractInputMeta): void {
        // const isCreated = this.createdInputList[component.metaData.field || ''];
        // if (!isCreated) this.createdInputList[component.metaData.field || ''] = true;
        // if (this.allInputsAreCreated()) {
        //     this.blockPanel(false);`
        //     this.reloadMasonry();
        // }
    }

    public onRowEditInit(data: DataObject, rowIndex: number): void {
        const { _deleted } = data;
        if (!_deleted) {
            const controlsByID = arrayToObjectFn<AbstractControl>(this.formArray.controls, controlIdFn);
            const { _id } = data;
            const controlRow = controlsByID[_id];
            if (controlRow.value[TableControlNames.UPDATED]) this.disableForSelectInput(rowIndex);
        } else {
            this.table?.cancelRowEdit(data);
            this.notificationService.onWarn('No puede editar fila eliminada', 'Eliminar');
        }

        // const paramsById = arrayToObjectFn<InputTypeParams<any>>(this.inputTypeParams, controlIdFn);
        // const { _id } = data;
        // const controlRow = controlsByID[_id];

        // const {} = this.inputTypeParams[rowIndex]
        // if (DropDownElements.includes(meta.input || InputType.Unknown)) {
        //     (inputMetaOptions as InputSelectOptions<any>).disableDefaultValue = true;
        // }
        // const controlsByID = arrayToObjectFn<AbstractControl>(this.formArray.controls, controlIdFn);
        // const { _id } = data;
        // const controlRow = controlsByID[_id];
        // controlRow.patchValue(data);
        // const dataTableIndex = this.dataTable.findIndex((data: DataObject) => data['_id'] === _id);
        // this.dataTable[dataTableIndex] = controlRow.value;
    }

    public onRowEditCancel(data: DataObject, rowIndex: number): void {
        // const controlsByID = arrayToObjectFn<AbstractControl>(this.formArray.controls, controlIdFn);
        // const { _id } = data;
        // const controlRow = controlsByID[_id];
        // const { _new, _updated } = controlRow.value;
        // if (_new && !_updated && !controlRow.valid) {
        //     const dataTableIndex = this.dataTable.findIndex((data: DataObject) => data[TableControlNames.ID] === _id);
        //     this.formArray.removeAt(dataTableIndex);
        //     this.dataTable = this.formArray.value;
        //     this.table?.clearState();
        //     this.table?.clear();
        // }
    }

    public onRowEditSave(data: DataObject, rowIndex: number): void {
        const controlsByID = arrayToObjectFn<AbstractControl>(this.formArray.controls, controlIdFn);
        const { _id } = data;
        const controlRow = controlsByID[_id];
        if (!controlRow.valid) {
            this.notificationService.onWarn('No puede guardar el valor antes de validar los campos', 'Guardar cambios en tabla');
            return this.table?.initRowEdit(data);
        }
        const dataTableIndex = this.dataTable.findIndex((data: DataObject) => data[TableControlNames.ID] === _id);
        const isUpdated = !deepCompareObjects(this.dataTable[dataTableIndex], controlRow.value);
        if (isUpdated) controlRow.patchValue({ [TableControlNames.UPDATED]: true });
        this.dataTable[dataTableIndex] = controlRow.value;

        // const paramIdFn = (inputParam: InputTypeParams<any>) => inputParam.metaData.;
        // const paramsById = arrayToObjectFn<Record<string,InputTypeParams<any>>>(this.inputTypeParams, controlIdFn);
        // const params = this.inputTypeParams[rowIndex];
        // const rowValue = Object.keys(params).reduce((value: DataObject, field: string) => ({ ...value, [field]: params[field].control.value }), {});
        // const controlRow = this.formArray.at(rowIndex);
        // controlRow.patchValue(rowValue);
        // this.dataTable[rowIndex] = controlRow.value;
        // const control = this.atControl(rowIndex);
        // if (!control?.valid) {
        //     this.table?.initRowEdit(data);
        //     return this.toastService.onMessage({
        //         message: 'El campo requiere validación',
        //         title: 'Error',
        //         type: 'error',
        //     });
        // }
        // this.values = this.getValuesArray();
        // this.onCreateItem.emit(control);
    }

    private disableForSelectInput(rowIndex: number): void {
        const inputParams = this.inputTypeParams[rowIndex];
        const selectsInputParams = Object.keys(inputParams).flatMap((key: string) => {
            const param = inputParams[key];
            if (DropDownElements.includes(param.metaData.input || InputType.Unknown)) {
                return param;
            }
            return [];
        });
        selectsInputParams.forEach(
            (inputParam: InputTypeParams<any>) => ((inputParam.metaData.inputMetaOptions as InputSelectOptions<any>).disableDefaultValue = true)
        );
    }

    private createParamsArray(control: AbstractControl, meta: MetaDataExtended<any>): RecordInputTypeParams<any> {
        const { inputMetaOptions } = meta;
        const params: InputTypeParams<any> = {
            control,
            metaData: { ...meta, inputMetaOptions },
            mode: InputMode.Write,
            reloadMasonry: this.reloadMasonry,
        };
        return { [meta.field]: params };
    }
    private createParams(group: FormGroup, meta: MetaDataExtended<any>): RecordInputTypeParams<any> {
        const control = createControl(meta, this);
        const { inputMetaOptions } = meta;
        const params: InputTypeParams<any> = {
            control,
            metaData: { ...meta, inputMetaOptions },
            mode: InputMode.Write,
            reloadMasonry: this.reloadMasonry,
        };
        group.addControl(meta.field, control);
        return { [meta.field]: params };
    }

    private initTableOptions(): void {
        this.initTable();
        this.initSearchOptions();
    }

    private initTable(): void {
        const { metaDataParams$, values$ } = this.config;
        this.table$ = combineLatest([metaDataParams$, values$]).pipe(
            untilDestroyed(this),
            tap(() => this.reloadMasonry()),
            tap(([metaDataParams, values]) => {
                this.metaDataParams = metaDataParams;
                this.assignHeadersToTable(metaDataParams);
                this.dataTable = values;
                this.addItemFromArray();
            })
        );
    }

    private assignHeadersToTable(metaDataParams: MetaDataExtended<any>[]): void {
        this.headers = metaDataParams.map((meta: MetaDataExtended<any>) => {
            const { field, label, type, customDataPipeFn, options } = meta;
            const header: HeaderInputTableModel<any> = {
                options,
                field,
                header: label,
                type,
                customDataPipeFn,
            };
            return header;
        });
    }

    private initSearchOptions(): void {
        if (this.searchOptions) {
            const { valueInputChanges } = this.searchOptions;
            this.searchControl.valueChanges
                .pipe(
                    untilDestroyed(this),
                    tap((value: string) => valueInputChanges?.(this.searchControl, value)),
                    tap((value: string) => {
                        if (!value) {
                            this.dataTableFiltered = undefined;
                            return;
                        }
                        const dataToFilter = this.dataTable.map((data: DataObject, index: number) =>
                            Object.keys(data).reduce((_data: DataObject, key: string) => {
                                const params = this.inputTypeParams[index]?.[key];
                                const newDataValue = !params ? data[key] : this.metaDataTypePipe.transform(data, params.metaData);
                                return { ..._data, [key]: newDataValue };
                            }, {})
                        );
                        const filteredData = filterByTerm<any>(dataToFilter, value).map((value: DataObject) => value[TableControlNames.ID]);
                        this.dataTableFiltered = this.dataTable.filter((value: DataObject) => filteredData.includes(value[TableControlNames.ID]));
                    })
                )
                .subscribe();
        }
    }
}
