import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { DataType, ValidationType } from '../model/components/form.model';
import { ConditionMetaData, MetaDataExtended, ValidationMetaData } from '../model/components/meta-data.model';
// import { hiddenMetaData } from './util-form';

/**
 *
 * @param param
 * @returns An object with all validation types with their ValidatorFn
 *
 */
const validationsFn: (param: any) => { [validationType: string]: ValidatorFn | null } = (param: any) => ({
    [ValidationType.Required]: param === 'true' ? Validators.required : null,
    [ValidationType.Email]: Validators.email,
    [ValidationType.Max]: Validators.max(param),
    [ValidationType.Min]: Validators.min(param),
    [ValidationType.MinLength]: Validators.minLength(param),
    [ValidationType.MaxLength]: Validators.maxLength(param),
    [ValidationType.Pattern]: Validators.pattern(param),
});
/**
 *
 * @param control
 * @param validation
 * @returns
 * @description it takes care of creating the validation and making sure that if it already exists it just replaces it otherwise it adds it
 */
const createValidation = (control: AbstractControl, validation: ValidationMetaData) => {
    const _validation = validationsFromMeta(validation);
    if (_validation) {
        if (control.hasValidator(_validation)) return control.setValidators(_validation);
        control.addValidators(_validation);
    }
};

/**
 *
 * @param validation
 * @returns `ValidatorFn | null` according to the type of validation with which the metadata is extracted
 */
const validationsFromMeta: (validation: ValidationMetaData) => ValidatorFn | null = (validation: ValidationMetaData) => {
    const { type, param } = validation;
    const validations = validationsFn(param);
    return validations[type];
};

/**
 *
 * @param control
 * @param validations
 * @description Create validations for form group type controls
 */
const validationFormGroup = (control: AbstractControl, validations: ValidationMetaData[]) => {
    validations.forEach((validation: ValidationMetaData) => {
        if (validation.path) {
            const ctrl = (control as FormGroup).controls[validation.path];
            createValidation(ctrl, validation);
        }
    });
};

/**
 *
 * @param control
 * @param validations
 * @description Create validations for form control type controls
 */
const validationControl = (control: AbstractControl, validations: ValidationMetaData[]) => {
    validations.forEach((validationModel: ValidationMetaData) => createValidation(control, validationModel));
};
/**
 *
 * @param control
 * @param meta
 * @param contextToDestroyVCControl
 * @description add the valueChanges that comes from configuration to the general control and receive the context of the service or component for handling the subscription
 */
const subscribeValueChanges = <T>(control: AbstractControl<T>, meta: MetaDataExtended<T>, contextToDestroyVCControl: any) => {
    const { valueChanges, valueInputChanges } = meta;
    control.valueChanges
        .pipe(
            untilDestroyed(contextToDestroyVCControl),

            tap((value: any) => {
                if (valueInputChanges) valueInputChanges(control, value);
            }),
            tap((value: any) => {
                if (valueChanges) valueChanges(value);
            })
        )
        .subscribe();
};

/**
 *
 * @param value
 * @param condition
 * @returns `true` when the condition is met by the meta value and the chosen meta value
 */
const isMatchCondition = (value: string | [], condition: string | null) => {
    if (Array.isArray(value)) return value?.length === 0 && !condition;
    if (value != null && condition?.includes('~')) {
        const val = condition.split('~')[1];
        return val !== value;
    }
    return value === condition;
};
/**
 *
 * @param control
 * @param validations
 * @description Assign validations to each control and update and validate values
 */
const assignValidationToControl = (control: AbstractControl, validations: ValidationMetaData[]) => {
    const validationForControl = control instanceof FormGroup ? validationFormGroup : validationControl;
    validationForControl(control, validations);
    control.updateValueAndValidity({ emitEvent: false });
};

/**
 *
 * @param type
 * @returns Create a control type according to `DataType`
 */
const createAbstractControl = (type: DataType) => {
    if (type === DataType.Array) {
        return new FormArray([]);
    }
    if (type === DataType.Object) {
        return new FormGroup({
            input: new FormControl(null),
            option: new FormControl(null),
        });
    }
    return new FormControl();
};

/**
 *
 * @param meta
 * @param contextToDestroyVCControl
 * @returns Control
 * @description Create the control, assign the valueChanges subscriptions and assign the validations to the control
 */
export const createControl = <T>(meta: MetaDataExtended<T>, contextToDestroyVCControl: any) => {
    const control = createAbstractControl(meta.type);
    subscribeValueChanges(control, meta, contextToDestroyVCControl);
    assignValidationToControl(control, meta.validations);
    return control;
};

/**
 *
 * @param metaDataControl
 * @param metaDataValidations
 * @returns
 * @description A constant that uses a 3-level closure, first it receives the context of the metadata control and its validations,
 * the function returned to receive each of the metadata conditions, finally it receives the function that receives the value of the control when it changes executed by the ` valueChanges`
 * and performs the comparison of values and adds, removes or reassigns the validations
 */
export const createConditionsForControl = (metaDataControl: FormControl, metaDataValidations: ValidationMetaData[]) => {
    return (condition: ConditionMetaData) => (controlValue: any) => {
        const _value = typeof controlValue === 'boolean' ? String(controlValue) : controlValue;
        const valueToCheck = controlValue?.[condition.pathId || ''] || _value;
        const _isMatchCondition = isMatchCondition(valueToCheck, condition.value);
        if (!_isMatchCondition) metaDataControl.clearValidators();
        const validations = _isMatchCondition ? condition.validations : metaDataValidations;
        assignValidationToControl(metaDataControl, validations);
    };
};

// export const conditionsHiddenToForm$ = (
//     reloadMasonry: Function,
//     metaData: MetaDataExtended<any>[],
//     formGroup: FormGroup,
//     conditionMetaData: ConditionHiddenMetaData[]
// ) => {
//     return conditionMetaData.map((condition: ConditionHiddenMetaData) => createConditionsHiddenEvents(reloadMasonry, metaData, formGroup, condition));
// };

// const createConditionsHiddenEvents: (
//     reloadMasonry: Function,
//     metaData: MetaDataExtended<any>[],
//     formGroup: FormGroup,
//     conditionMetaData: ConditionHiddenMetaData
// ) => Observable<any> | undefined = <T>(
//     reloadMasonry: Function,
//     metaData: MetaDataExtended<T>[],
//     formGroup: FormGroup,
//     conditionMetaData: ConditionHiddenMetaData
// ) => {
//     const control = formGroup.get(conditionMetaData.field);
//     return control?.valueChanges.pipe(
//         map((value: any) => {
//             elseConditionFn(metaData, value, conditionMetaData);
//             return conditionMetaData.conditions.filter((condition: ConditionHidden) => {
//                 const valueToCheck = value?.[condition.path || ''] || value;
//                 return valueToCheck === condition.value;
//             });
//         }),
//         tap((conditions: ConditionHidden[]) => {
//             conditions.forEach((condition: ConditionHidden) => conditionHiddenEmit(metaData, condition));
//             metaData = [...metaData];
//             setTimeout(reloadMasonry, 500);
//         })
//     );
// };

// const elseConditionFn = <T>(metaData: MetaDataExtended<T>[], value: any, conditionMetaData: ConditionHiddenMetaData) => {
//     const { elseConditions } = conditionMetaData;
//     const valueToCheck = value?.[elseConditions?.path || ''] || value;
//     if (elseConditions) elseConditionEmit(metaData, valueToCheck, elseConditions);
// };

// const elseConditionEmit = <T>(metaData: MetaDataExtended<T>[], value: T, elseConditions: ConditionHidden) => {
//     const values = elseConditions.value.split('|');
//     const isMatch = values.includes(value);
//     if (!isMatch) conditionHiddenEmit(metaData, elseConditions);
// };

// const conditionHiddenEmit = <T>(metaData: MetaDataExtended<T>[], condition: ConditionHidden) => {
//     hiddenMetaData(metaData, true, ...condition.fieldsToHidden);
//     hiddenMetaData(metaData, false, ...condition.fieldsToShow);
// };
