import { InputType } from '../../../model/components/form.model';
export const DISPLAY_VALUES = 'displayValues';
export const OPTION_LABEL = 'text';
export const OPTION_VALUE = 'value';
export const DropDownElements: InputType[] = [
    InputType.InputSelect,
    InputType.Lookup,
    InputType.LookupMulti,
    InputType.Select,
    InputType.SelectMulti,
];

export const SimpleDropDownElements: InputType[] = [InputType.Select, InputType.SelectMulti];
export const EndPointDropDownElements: InputType[] = [InputType.Endpoint, InputType.EndpointMulti];
export const LookupDropDownElements: InputType[] = [InputType.Lookup, InputType.LookupMulti];
