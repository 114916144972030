<div class="stepper-container" [style.width.%]="params.width || 100">
    <section class="header box">
        <header class="stepper-header">
            <p-button [icon]="params.icon || 'pi pi-box'"
                styleClass="p-button-sm p-button-rounded p-button-info p-button-outlined"
                [style]="{margin:' auto 0'}"></p-button>
            <div class="header-title">
                <span class="header-steps">Steps {{selectedIndex + 1}} / {{steps.length}}</span>
                <span class="header-name">{{params.title || 'Stepper'}}</span>
            </div>
        </header>
        <div class="progress">
            <div class="step-progress" *ngFor="let step of stepsAndSpaces; let i = index" [style.width.%]="stepWidth">
                <ng-container *ngIf="step; else stepLine;">
                    <div class="step-container "
                        [ngClass]="selectedIndex === step.params.stepIndex ?'shadowed': 'inactive' "
                        (click)="selectStep(step, i)">

                        <p-button [icon]="step.params.icon || 'pi pi-star'" [styleClass]="'p-button-sm p-button-rounded ' + 'p-button-' + (step.params.status) + ' ' + (step.params.status ===
                            'warning' ? '  p-button-text p-button-raised' : '')">
                        </p-button>
                        <div class="container-text">
                            <span class="step-index"> Step {{(step.params.stepIndex || 0) + 1}}
                            </span>
                            <span class="step-title"> {{ step.params.buttonName || '' }}</span>
                            <p-tag class="step-status" [severity]="step.params.status || ''"
                                [value]="step.params.statusLabel || ''" [rounded]="true"></p-tag>
                        </div>

                    </div>
                </ng-container>
                <ng-template #stepLine>
                    <div class="step-line">
                        <p-divider type="solid" class="color-line"></p-divider>
                    </div>
                </ng-template>
            </div>

        </div>
    </section>
    <section class="container box">

        <div class="content" [style.display]="_selected ? 'block' : 'none'">
            <ng-container #stepContent [ngTemplateOutlet]="_selected ? _selected.content: null"> </ng-container>
            <div class="buttons">
                <p-button icon="pi pi-angle-left" styleClass="p-button-info" label="Anterior"
                    (onClick)="previous()"></p-button>
                <ng-container *ngIf="!isLastStep || _selected?.completed">
                    <p-button [icon]="'pi ' + (!isLastStep ? 'pi-angle-right' : 'pi-check')" styleClass="p-button-info"
                        [label]="isLastStep ? 'Finalizar' :'Siguiente'" iconPos="right" (onClick)="next()"></p-button>

                </ng-container>
            </div>
        </div>

    </section>
</div>

<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>