import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { SELECT_CONTROL_TOKEN } from '../constants/dynamic-table/dynamic-table.constants';
import { ExtendedMetaDataModel, MetaDataModel } from '../model/components/form.model';
import { MetaDataExtended } from '../model/components/meta-data.model';
import { DropdownTypeConfig } from '../model/components/meta-input-form.model';
import { SortDataType, SortProperties, _moment } from '../model/utils/utils.model';
import { digger, multipleDigger } from './general-functions';
/**
 * @deprecated The method should not be used
 */
export function hiddenMetaData<T>(metaDataList: MetaDataExtended<T>[], hidden: boolean, ...fields: string[]) {
    const _filterByFields = filterMetaDataFields<T>(fields);
    _filterByFields(metaDataList).forEach((meta: MetaDataExtended<T>) => (meta.hidden = hidden));
}
/**
 * @deprecated The method should not be used
 */
export function setHiddenMetaData(metaDataList: MetaDataModel[], hidden: boolean, ...fields: string[]) {
    const _filterByFields = filterMetaData(fields);
    _filterByFields(metaDataList).forEach((meta: MetaDataModel) => (meta.hidden = hidden));
}

export function getSelectedData(formGroup: FormGroup, controlId: string): any {
    return (formGroup.controls[controlId] as FormArray).controls
        .map((control: AbstractControl) => control.value)
        .filter(({ _selection }) => _selection);
}

export const clearSelectionControl = (formGroup: FormGroup, controlId: string) => {
    (formGroup.controls[controlId] as FormArray).controls
        .filter((control: AbstractControl) => control.get(SELECT_CONTROL_TOKEN)?.value)
        .forEach((control: AbstractControl) => control.get(SELECT_CONTROL_TOKEN)?.setValue(false));
};

export const sortBy: SortTypeFn =
    <T>(field: string, props?: SortProperties) =>
    (itemA: T, itemB: T) => {
        let valueA = digger(itemA, field);
        let valueB = digger(itemB, field);
        if (props) {
            const { type, isInverted } = props;
            if (type === SortDataType.Date) {
                valueA = _moment(valueA).valueOf();
                valueB = _moment(valueB).valueOf();
            }
            if (isInverted) {
                const auxA = valueA;
                valueA = valueB;
                valueB = auxA;
            }
            if (type === SortDataType.Text) return valueA.localeCompare(valueB);
        }

        return valueA - valueB;
    };

/**
 * @deprecated The method should not be used
 */
export const filterMetaData = (fields: string[]) => (metaData: MetaDataModel[]) => {
    return metaData.filter((meta: MetaDataModel) => fields.includes(meta.field));
};

export const filterMetaDataFields =
    <T>(fields: string[]) =>
    (metaData: MetaDataExtended<T>[]) => {
        return metaData.filter((meta: MetaDataExtended<T>) => fields.includes(meta.field));
    };

export const filterByTerm: <T>(array: T[], term: string) => T[] = <T>(array: T[], term: string) =>
    array.filter((item: T) => Object.values(item as any).some((value: any) => value.toString().toLowerCase().includes(term.toLowerCase())));

export const filterMultipleByTerms: <T>(array: T[], terms: string[], paths: string[]) => T[] = <T>(array: T[], terms: string[], paths: string[]) =>
    array.filter((item: T) => {
        const dataExtracted = multipleDigger<T>(item, paths) as Array<string>;
        return terms.some((term: string) => dataExtracted.includes(term));
    });

export const arrayToObject: ArrayTypeFn = <T>(array: T[], key: string) => {
    return array.reduce((object: Record<string, T>, objectAt: T) => {
        return { ...object, [key]: objectAt };
    }, {});
};

export const arrayToObjectFn: ArrayTypeKeyFn = <T>(array: T[], keyFn: (object: T) => string) =>
    array.reduce((object: Record<string, T>, objectAt: T) => {
        const key = keyFn(objectAt);
        return { ...object, [key]: objectAt };
    }, {});

export const getCommonParamsCompare = (primaryObj: any, compareObj: any) => {
    const primaryParams = Object.keys(primaryObj);
    const matchParams = primaryParams.filter((param: string) => !!compareObj?.[param]);
    return matchParams.map((param: string) => ({ param, valueObject: primaryObj[param] }));
};

export const setDropDownConfig = (metaData: ExtendedMetaDataModel[], field: string, dropDownConfig: DropdownTypeConfig) =>
    metaData
        .filter((meta: ExtendedMetaDataModel) => meta.field === field)
        .forEach((_meta: ExtendedMetaDataModel) => (_meta.dropDownConfig = dropDownConfig));

export const betweenLI = (value: number, min: number, max: number) => min >= value && value < max;
export const betweenAll = (value: number, min: number, max: number) => min >= value && value <= max;
export const betweenRI = (value: number, min: number, max: number) => min > value && value <= max;
export const between = (value: number, min: number, max: number) => min > value && value < max;

type SortTypeFn = <T>(field: string, props?: SortProperties) => (itemA: T, itemB: T) => number;

type ArrayTypeFn = <T>(array: T[], key: string) => Record<string, T>;
type ArrayTypeKeyFn = <T>(array: T[], keyFn: (object: any) => string) => Record<string, T>;
