import { Component, OnInit } from '@angular/core';
import { AbstractInputMeta } from '../abstract-input-meta.class';

@Component({
    selector: 'lib-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss', '../input-meta.component.scss'],
})
export class InputTextComponent extends AbstractInputMeta implements OnInit {
    public override ngOnInit(): void {
        super.ngOnInit();
    }

    protected override abstractInit(): void {}
    public override destroyObservables(): void {}
    public override onChangesMode(): void {}
}
