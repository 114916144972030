import { Inject, Injectable } from '@angular/core';
import { TEMPLATE_CONFIG } from '../../constants/template/template.constants';
import { LoginConfig } from '../../model/login/login.model';
import { ConfigurationModel, NavigationConfig } from '../../model/template/template.model';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    public loginConfig: LoginConfig | undefined;
    public navigationData: NavigationConfig;
    public apiUrl: string;
    public apiMetaDataUrl: string;
    public version: string;
    public apiKey: string | undefined;

    constructor(@Inject(TEMPLATE_CONFIG) templateConfig: ConfigurationModel) {
        this.apiUrl = templateConfig.apiUrl;
        this.apiMetaDataUrl = templateConfig.apiMetaDataUrl;
        this.loginConfig = templateConfig.login;
        this.navigationData = templateConfig.navigation;
        this.version = templateConfig.version;
        this.apiKey = templateConfig.apiKey;
    }
}
