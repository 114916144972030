import { AfterViewInit, Component, Input, forwardRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PrimeIcons } from 'primeng/api';
import { BehaviorSubject, tap } from 'rxjs';
import { DUMMY_METADATA } from '../../constants/dummy/dummy.constants';
import { FormMode, InputActionList, MetaDataModel } from '../../model/components/form.model';
import { StepperData } from '../../model/components/stepper.model';
import { CardOrientation } from '../../model/utils/utils.model';
import { LibAbstractStep } from '../stepper/step/abstract-step.component';

@UntilDestroy()
@Component({
    selector: 'lib-dummy',
    templateUrl: './dummy.component.html',
    styleUrls: ['./dummy.component.scss'],
    providers: [{ provide: LibAbstractStep, useExisting: forwardRef(() => DummyComponent) }],
})
export class DummyComponent extends LibAbstractStep implements AfterViewInit {
    public orientation: CardOrientation = CardOrientation.Center;
    public formGroup: FormGroup = new FormGroup({});
    public orientationActions: CardOrientation = CardOrientation.End;
    public formWriteMode: FormMode = FormMode.Write;
    public meta: MetaDataModel[] = DUMMY_METADATA;
    public iconAction: InputActionList = {
        ['name']: {
            action: () => alert('hola'),
            icon: PrimeIcons.AMAZON,
        },
    };

    private _complete$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    @Input() message: string = 'none';
    @Input() cardDummy: boolean = false;

    public override getComplete$(): BehaviorSubject<boolean> {
        return this._complete$;
    }

    public override getStepData(): any {
        return {
            form: this.formGroup.value,
            message: this.message,
        };
    }

    public override setData(data: StepperData): void {
        console.log('Data que viene de stepper para procesar', data);
    }

    public setComplete(): void {
        this._complete$.next(true);
    }

    public ngAfterViewInit(): void {
        if (this.cardDummy) {
            this.setCompleteFormCard();
        }
    }
    private setCompleteFormCard() {
        this.formGroup.valueChanges
            .pipe(
                untilDestroyed(this),
                tap(() => {
                    this._complete$.next(this.formGroup.valid);
                    if (this.formGroup.valid) {
                        console.log('complete dummy ');
                    }
                })
            )
            .subscribe();
    }
}
