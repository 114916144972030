import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { InputComplexOptions } from '../../../../model/components/input-meta/input-complex.model';
import { TableConfig } from '../../../../model/components/input-meta/input-table.model';
import { MetaData, MetaDataExtended, MetaDataOptions } from '../../../../model/components/meta-data.model';
import { GlobalMetaData } from '../../../../model/components/panel-form.model';
import { ComplexType, ModeMetaData } from '../../../../model/components/shared-models.model';
import { MetaApiService } from '../../../../services/shared/meta-api.service';
import { AbstractInputMeta } from '../abstract-input-meta.class';
import { createComponent } from '../input-meta.constants';
import { InputComponent, InputTypeParams } from '../input-meta.model';

// const InputComplexTypeList: { [type: string]: Type<any> } = {
//     [ComplexType.Default]: InputUnknownComponent,
//     [ComplexType.Table]: InputTableComponent,
// };
// export const InputComplexFactory = (template: TemplateRef<any>, viewContainerRef: ViewContainerRef, params: InputTypeParams<any>) => {
//     const options = params.metaData.inputMetaOptions as InputComplexOptions<any>;
//     // const inputType = InputComplexTypeList[options.type] || InputUnknownComponent;
//     return createComplexComponent(template, viewContainerRef, params);
// };

// const createComplexComponent = (input: TemplateRef<any>, viewContainerRef: ViewContainerRef, params: InputTypeParams<any>) => {
//     const componentRef = viewContainerRef.createEmbeddedView(input);

//     Object.keys(params).forEach((key: string) => componentRef.context.setInput(key, (params as any)[key]));
//     return { component: componentRef, instance: componentRef.context.instance };
// };

@Component({
    selector: 'lib-input-complex',
    templateUrl: './input-complex.component.html',
    styleUrls: ['./input-complex.component.scss'],
})
export class InputComplexComponent extends AbstractInputMeta {
    @Input() metaDataOptions?: MetaDataOptions<any>;

    @ViewChild('templateContainer', { read: ViewContainerRef, static: true }) private templateContainer!: ViewContainerRef;

    public abstractInputComplex!: AbstractInputMeta;

    get inputMetaOptions(): InputComplexOptions<any> {
        return this.metaData.inputMetaOptions as InputComplexOptions<any>;
    }

    get type(): ComplexType {
        return this.inputMetaOptions.type;
    }

    constructor(private metaApiService: MetaApiService) {
        super();
    }

    protected override abstractInit(): void {
        const params: InputTypeParams<any> = {
            control: this.control,
            metaData: this.metaData,
            mode: this.mode,
            reloadMasonry: this.reloadMasonry,
        };
        const options = params.metaData.inputMetaOptions as InputComplexOptions<any>;
        // if (options.type === ComplexType.Table) this.templateRef = this.tableTemplate;
        // const inputType = InputComplexTypeList[options.type] ?? InputUnknownComponent;
        const inputComponent = createComponent(options.component, this.templateContainer, params);
        this.abstractInputComplex = inputComponent.instance;
        const metaDataComplex$ = this.getMetaDataComplex();
        if (this.type === ComplexType.Table) this.setConfigTable(inputComponent, metaDataComplex$);
    }

    public override destroyObservables(): void {}
    public override onChangesMode(): void {
        this.abstractInputComplex.setMode(this.mode);
    }
    private getMetaDataComplex(): Observable<MetaDataExtended<any>[]> {
        const { metadataId } = this.metaData;
        if (this.inputMetaOptions.metaData) return of(this.extendedMetaData(this.inputMetaOptions.metaData));
        return this.metaApiService.getMetaData$(metadataId ?? '', ModeMetaData.New).pipe(map((meta: MetaData[]) => this.extendedMetaData(meta)));
    }

    private extendedMetaData(_metaData: GlobalMetaData[]): MetaDataExtended<any>[] {
        return _metaData.map((metaData: GlobalMetaData) => {
            const _metaParams = this.metaDataOptions?.[metaData.field];
            const metaDataExtended = (_metaParams ? { ...metaData, ..._metaParams } : { ...metaData }) as MetaDataExtended<any>;
            // this.setErrorMessageStyle(metaDataExtended);
            // this.panelMetaDataExtendedList = { ...this.panelMetaDataExtendedList, [metaData.field]: { metaDataExtended } };
            return metaDataExtended;
        });
    }

    private setConfigTable(inputComponent: InputComponent, metaDataParams$: Observable<MetaDataExtended<any>[]>): void {
        const { component } = inputComponent;
        const valuesBS$ = new BehaviorSubject<Array<any>>([]);
        const config: TableConfig<any> = {
            metaDataParams$,
            values$: valuesBS$.asObservable(),
            valuesBS$,
        };
        (component as any).setInput('config', config);
    }
}
