import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AvatarImageDefault, LogoImageDefault, SIDE_NAV_EXTENDED_TOKEN } from '../../../constants/shared/shared.constants';
import { ExtendedMenuItem } from '../../../model/components/menu-bar.model';
import { UserInfo } from '../../../model/login/login.model';
import { NavigationConfig, SubMenuModel } from '../../../model/template/template.model';
import { OAuthLibService } from '../../../services/authorization/oauth-lib.service';
import { ConfigService } from '../../../services/template/config.service';

@UntilDestroy()
@Component({
    selector: 'lib-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
    public menus: ExtendedMenuItem[];
    public userInfo: UserInfo | undefined;
    public loginTime: Date | null;
    public logoApp: string;
    public avatar: string;
    public navigationData: NavigationConfig | null = null;
    public version: string;
    public toggledIcon: boolean = false;
    public expandedSideNav: boolean = localStorage.getItem(SIDE_NAV_EXTENDED_TOKEN) === 'true';

    @ViewChild('userInfoTemplate', { static: true }) userInfoTemplate!: TemplateRef<any>;

    constructor(
        config: ConfigService,
        private oauthService: OAuthLibService,
        private router: Router,
        private confirmationService: ConfirmationService
    ) {
        this.menus = config.navigationData.menus;
        this.userInfo = oauthService.getUserInfo();
        this.loginTime = oauthService.getLoginTime();
        this.logoApp = config.navigationData.logoApp || LogoImageDefault;
        this.version = `${config.version}`;
        this.avatar = this.userInfo?.avatar || AvatarImageDefault;
        this.navigationData = config.navigationData;
        this.setForceActiveButton();
    }

    public clickMenuEvent(submenu: SubMenuModel) {
        this.clearClickedButtons();
        submenu.isClicked = true;
        if (submenu.action) {
            submenu.action.next(submenu);
        }
        if (submenu.path) {
            const paths = submenu.path.split('/');
            this.router.navigate([submenu.rootPath || '', ...paths]);
        }
    }

    public toggleSideNav(): void {
        this.expandedSideNav = !this.expandedSideNav;
        localStorage.setItem(SIDE_NAV_EXTENDED_TOKEN, `${this.expandedSideNav}`);
    }

    public logOut(): void {
        this.confirmationService.confirm({
            message: '¿Está seguro de cerrar sesión?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sí',
            accept: () => this.oauthService.redirectLogOut(),
        });
    }

    public showProfile(op: OverlayPanel, event: any) {
        if (!this.expandedSideNav) {
            op.toggle(event);
        }
    }
    private clearClickedButtons() {
        // this.menus
        //     .flatMap(menu => menu.submenus)
        //     .filter(submenu => submenu.isClicked)
        //     .forEach(submenu => (submenu.isClicked = false));
    }

    private setForceActiveButton(): void {
        const rootPaths = this.menus.flatMap((menu: ExtendedMenuItem) => menu.items);
        this.router.events.pipe(untilDestroyed(this)).subscribe((navigation: Event) => {
            if (navigation instanceof NavigationEnd) {
                // rootPaths.forEach((submenu: SubMenuModel) => {
                //     if (!!submenu.pathNavigation && navigation.url.includes(submenu.pathNavigation)) {
                //         submenu.isClicked = true;
                //     }
                // });
            }
        });
    }
}
