<div class="main-sidenav" [ngClass]="{'expanded-sidenav':expandedSideNav}">
  <div class="tool-sidenav">
    <div class="tool tool-logo" [pTooltip]="version" (click)="toggleSideNav()">
      <img alt="" [src]="logoApp">
    </div>
    <span *ngIf="expandedSideNav" class="title-app">{{navigationData?.title}}</span>
  </div>

  <div class="menu-list">
    <lib-menu-sidenav [expandedMenu]="expandedSideNav" [menus]="menus" (menuButtonEvent)="clickMenuEvent($event)">
    </lib-menu-sidenav>
  </div>

  <div class="footer" [ngClass]="{'expanded-sidenav':expandedSideNav,'footer-expanded':expandedSideNav}"
    (click)="showProfile(op,$event)">
    <lib-image-file class="imgProfile" [isLoadFile]="false" [avatar]="avatar" [width]="70"></lib-image-file>
    <ng-container *ngIf="expandedSideNav" [ngTemplateOutlet]="userInfoTemplate"></ng-container>
  </div>
</div>

<p-overlayPanel #op [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" [dismissable]="true">
  <ng-template pTemplate>
    <ng-container [ngTemplateOutlet]="userInfoTemplate"></ng-container>
  </ng-template>
</p-overlayPanel>

<ng-template #userInfoTemplate>
  <div class="profile">
    <div class="profile-text">
      <div class="text-profile name-profile">{{userInfo?.name}}</div>
      <div class="text-profile mail-profile">{{userInfo?.email}}</div>
      <div class="text-profile time-profile">{{loginTime | date:'HH:mm, MMM d, y'}}</div>
    </div>
    <p-divider class="divider" layout="vertical"></p-divider>

    <div class="logout">
      <button pButton pRipple type="button" icon="pi pi-sign-out" pTooltip="Cerrar sesión"
        class="logout-button p-button-rounded p-button-outlined p-button-text p-button-info"
        (click)="logOut()"></button>
    </div>
  </div>
</ng-template>