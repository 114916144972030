<div *ngIf="metaDataComplex && template as templateRef" class="container">

    <div class="title-menu">
        <label class="p-lib-label">{{metaData?.label}}</label>
    </div>

    <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
</div>

<ng-template #defaultTemplate>
</ng-template>

<ng-template #tableTemplate>
    <ng-container *ngIf="metaDataComplex && dynamicTableModel">
        <lib-dynamic-table [formMode]="formMode" [dynamicTable]="dynamicTableModel"
            (onCreateItem)="onSaveEvent($event)"></lib-dynamic-table>
    </ng-container>
</ng-template>