import { AuthConfig } from 'angular-oauth2-oidc';
import { OAuthModel } from '../../model/login/login.model';

export const AuthCodeFlowConfig: AuthConfig = {
    responseType: 'code',
    strictDiscoveryDocumentValidation: false,
    // refreshes
    useSilentRefresh: true,
    sessionChecksEnabled: true,
    clearHashAfterLogin: false,
    nonceStateSeparator: 'semicolon',
    showDebugInformation: true,
    requireHttps: false,
};

export const DemoUser: OAuthModel = {
    isLogged: true,
    accessToken: '',
    refreshToken: '',
    userInfo: {
        name: 'User',
        auth_time: 231231231,
        avatar: '',
        email: 'demo@lsystems.com',
        exp: 123123,
        iat: 1,
        id: '_demo',
        sub: 'demo',
        username: 'demo',
    },
};
