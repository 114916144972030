import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { ExtendedMenuItem } from '../components/menu-bar.model';
import { LoginConfig } from '../login/login.model';

/**
 * Navigation
 */
export enum NavigationType {
    Top = 'top',
    Left = 'left',
    Right = 'rigth',
}

export interface NavigationConfig {
    title: string;
    icon: string;
    type: NavigationType;
    menus: ExtendedMenuItem[];
    toolbar: ToolBarActionModel[];
    logoApp?: string;
    urlProfile?: string;
    messageLogin?: string;
    messageLogout?: string;
}

/**
 * Toolbar actions model
 */
//ToDo: Remove this model

export interface ToolBarActionModel {
    icon: string;
    title?: string;
    action?: Subject<any>;
}

/**
 * Menu model
 */
//ToDo: Remove this model
export interface MenuTemplateModel {
    title: string;
    subtitle?: string;
    submenus: SubMenuModel[];
}

export interface MenuRouterModel {
    rootPath: string;
    submenu: SubMenuModel;
}
export interface SubMenuModel {
    icon?: string;
    title: string;
    action?: Subject<any>;
    path?: string;
    rootPath?: string;
    pathNavigation?: string;
    // parametros para operación
    isClicked?: boolean;
}

/* Template */
export interface ConfigurationModel {
    navigation: NavigationConfig;
    login?: LoginConfig;
    apiUrl: string;
    apiMetaDataUrl: string;
    version: string;
    apiKey?: string;
}

export interface DataObject {
    [key: string]: any;
}
export type DataObjectRow = DataObject | DataObject[] | null | undefined;
export type RouterActionFn = (event?: any) => void;
export type MenuItemFn = (ctx: any) => MenuItem[];
export type MenuItemSubFn = (routerAction: RouterActionFn) => MenuItem[];

// export type EntityMenuModel = {
//     [key: string]: MenuItem[];
// };
