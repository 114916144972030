import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { MessageFn, ToastModel, TypeToast } from './toast.model';
import { ToastService } from './toast.service';

@Component({
    selector: 'lib-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    providers: [MessageService],
})
export class ToastComponent implements OnInit {
    public toastMessage$: Observable<ToastModel> | null = null;

    constructor(private messageService: MessageService, private toastService: ToastService) {}

    ngOnInit(): void {
        this.toastMessage$ = this.toastService.getToastMessage$().pipe(
            tap((toastMessage: ToastModel) => {
                const messageFn = this.getMessage(toastMessage.type);
                messageFn(toastMessage.title, toastMessage.message);
            })
        );
    }

    public onConfirm() {
        this.messageService.clear('c');
    }

    public onReject() {
        this.messageService.clear('c');
    }

    public clear() {
        this.messageService.clear();
    }

    private showSuccess(title: string, message: string) {
        this.messageService.add({ severity: 'success', life: 2000, summary: title, detail: message });
    }

    private showInfo(title: string, message: string) {
        this.messageService.add({ severity: 'info', life: 2000, summary: title, detail: message });
    }

    private showWarn(title: string, message: string) {
        this.messageService.add({ severity: 'warn', life: 2000, summary: title, detail: message });
    }

    private showError(title: string, message: string) {
        this.messageService.add({ severity: 'error', life: 2000, summary: title, detail: message });
    }

    private showCustom(title: string, message: string) {
        this.messageService.add({ severity: 'custom', life: 2000, summary: title, detail: message, icon: 'pi-file' });
    }

    private getMessage(type: TypeToast): MessageFn {
        if (type === 'success') {
            return this.showSuccess.bind(this);
        }
        if (type === 'error') {
            return this.showError.bind(this);
        }
        if (type === 'info') {
            return this.showInfo.bind(this);
        }
        if (type === 'warn') {
            return this.showWarn.bind(this);
        }
        return this.showCustom.bind(this);
    }
}
