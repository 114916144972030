import { ConfigurationModel, NavigationType } from 'dashboard-lib';
import { PrimeIcons } from 'primeng/api';
import { environment } from 'src/environments/environment';

export const TemplateConfig: ConfigurationModel = {
    apiUrl: environment.apiUrl,
    apiKey: environment.apiKey,
    apiMetaDataUrl: environment.apiMetaDataUrl,
    navigation: {
        icon: PrimeIcons.COG,
        title: 'EasyPay Alertas',
        type: NavigationType.Top,
        toolbar: [],
        menus: [
            {
                label: 'Dashboard',
                icon: PrimeIcons.TH_LARGE,
                routerLink: 'dashboard',
            },
        ],
        urlProfile: 'users',
        messageLogin: 'Bienvenido a EasyPay Alertas',
        messageLogout: 'Inicia sesión en EasyPay Alertas',
    },
    login: {
        clientId: environment.clientId,
        scope: environment.scope,
        uriCallback: environment.uriCallback,
        oauthUrl: environment.oauthUrl,
        revocationEndpoint: environment.revocationEndpoint,
        logoutUrl: environment.logoutUrl,
    },
    version: `${environment.appVersion}`,
};
