import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputMode, MetaDataOptions, ModeMetaData, NotificationService, PanelConfig, PanelForm, PanelParams, PanelService } from 'dashboard-lib';
import { ConfirmationService } from 'primeng/api';
import { tap } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { getMockMetaData } from 'src/mock/_mocks_/metadata-alerts.mock';
import { AlertDialogStatusTypeParams } from '../../../configuration/dashboard.config';
import { AlertStatus } from '../../../constants/dashboard.constants';
import { Alert, AlertDialogStatus } from '../../../model/alert.model';

@UntilDestroy()
@Component({
    selector: 'app-dialog-status',
    templateUrl: './dialog-status.component.html',
    styleUrls: ['./dialog-status.component.scss'],
})
export class DialogStatusComponent implements OnChanges {
    get control(): FormGroup | undefined {
        return this.pnlParams?.control;
    }

    @Input({ required: true }) open: boolean = false;
    @Input({ required: true }) alert: Alert | null = null;

    @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public panelConfig: PanelConfig = {
        id: 'pnl-alert-status',
        inputMode: InputMode.Write,
        metaData: getMockMetaData('alertStatus', ModeMetaData.New),
        styleOptions: {
            columns: 1,
        },
    };
    public options: MetaDataOptions<AlertDialogStatus> = {
        status: {
            inputMetaOptions: {
                ...AlertDialogStatusTypeParams,
                filterOptions: (item: any) => this.filterStatus(item, this.alert?.status ?? ''),
            },
        },
        comment: {
            inputMetaOptions: { row: 3 },
        },
    };

    private pnlParams?: PanelParams;

    get enableSaveAlert(): boolean | undefined {
        return this.pnlParams?.control?.valid;
    }

    constructor(
        panelService: PanelService,
        private alertService: AlertService,
        private confirmationService: ConfirmationService,
        private notificationService: NotificationService
    ) {
        panelService
            .getPanels$()
            .pipe(
                untilDestroyed(this),
                tap((pnlForm: PanelForm) => {
                    this.pnlParams = pnlForm['pnl-alert-status'];
                })
            )
            .subscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { open } = changes;
        if (open) {
            this.pnlParams?.control.reset();
            this.pnlParams?.reloadMasonryEvt();
        }
    }

    public onSave(): void {
        this.confirmationService.confirm({
            acceptLabel: 'Actualizar',
            rejectLabel: 'Cancelar',
            header: 'Estatus de alerta',
            message: '¿Está seguro de actualizar el estatus de la alerta?',
            accept: () =>
                this.alertService
                    .updateStatus({ ...this.control?.value, alertId: this.alert?.id })
                    .pipe(
                        untilDestroyed(this),
                        tap(() => this.notificationService.onSuccess('Se actualizó el estatus con éxito', 'Alertas')),
                        tap(() => this.onOpenChange(false))
                    )
                    .subscribe({
                        error: () => this.notificationService.onError(`Hubó un error: al actualizar el estatus de la alerta con ID: ${this.alert?.id}`, 'Alertas'),
                    }),
        });
    }

    public onOpenChange(visible: boolean): void {
        this.openChange.emit(visible);
    }

    private filterStatus(option: any, status: string): boolean {
        const { value } = option;
        if (status === AlertStatus.ACTIVO) return [AlertStatus.ATENDIDO, AlertStatus.ESPERA].includes(value);
        if (status === AlertStatus.ATENDIDO) return [AlertStatus.ACTIVO, AlertStatus.ESPERA].includes(value);
        if (status === AlertStatus.ESPERA) return [AlertStatus.ATENDIDO].includes(value);
        return true;
    }
}
